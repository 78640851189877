import { NgStyle } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'app-accordion-item',
	standalone: true,
	imports: [NgStyle],
	templateUrl: './accordion-item.component.html',
	styleUrl: './accordion-item.component.scss',
})
export class AccordionItemComponent {
	@Input() itemId: string;
	@Input() parentId: string;
	@Input() isOpen?: boolean = false;
	@Input() disableExpand?: boolean = false;
	@Input() lineHeight?: number = 30;

	@Output() toggle = new EventEmitter<boolean>();

	buttonPaddingY = this.lineHeight! / 2 - 10;

	onShow(): void {
		this.isOpen = true;
		this.toggle.emit(true);
	}

	onClose(): void {
		this.isOpen = false;
		this.toggle.emit(false);
	}

	toggleAccordion() {
		if (this.disableExpand) {
			this.toggle.emit(true);
			return;
		}
		this.isOpen = !this.isOpen;
		this.toggle.emit(this.isOpen);
	}
}
