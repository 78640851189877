import { NgClass, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { AssessmentStatusBadgeComponent } from '@leads/shared/components/assessment-status-badge/assessment-status-badge.component';
import { ScheduledAssessment, ScheduledAssessmentStatus } from '@leads/shared/models/domain/scheduled-assessment.domain';

@Component({
	selector: 'app-scheduled-item-title',
	standalone: true,
	imports: [AssessmentStatusBadgeComponent, NgIf, NgClass],
	templateUrl: './scheduled-item-title.component.html',
	styleUrl: './scheduled-item-title.component.scss',
})
export class ScheduledItemTitleComponent {
	@Input() index: number;
	@Input({ alias: 'scheduledItem' }) item?: ScheduledAssessment;
	@Input() readonlyView? = false;
	@Input() mobile?: boolean = false;
	@Input() header?: boolean = false;

	protected readonly ScheduledAssessmentStatus = ScheduledAssessmentStatus;

	get scheduledTime(): string {
		const options: Intl.DateTimeFormatOptions = {
			weekday: 'short',
			month: 'short',
			day: '2-digit',
			hour: '2-digit',
			minute: '2-digit',
		};
		return Intl.DateTimeFormat('en-ZA', options).formatRange(this.item!.selectedStartTime, this.item!.selectedEndTime!);
	}
}
