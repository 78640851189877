import { FireStoreDocument } from './firestore';

import { LeadRequestType } from '@leads/shared/models/domain/lead-request.domain';

export interface IMail extends FireStoreDocument {
	to: Array<string>;
	from?: 'ServeOx Team <noreply@serveox.com>' | string;
	template: IMailTemplate;
	delivery?: {
		attempts: number;
		state: 'SUCCESS' | 'ERROR' | 'PROCESSING';
		startTime: Date;
		endTime: Date;
		error: string | null;
		info?: {
			accepted: Array<string>;
			pending: Array<string>;
			rejected: Array<string>;
			response: string;
			messageId: string;
		};
	};
}

export enum MailTemplate {
	ORGANISATION_INVITATION = 'organisation-invitation',
	LEAD_REQUEST_SUBMISSION = 'lead-request-submission',
}

export interface IMailTemplate {
	name: MailTemplate;
	data: object;
}

export interface ILeadRequestConfirmationMail {
	clientName: string;
	organisationName: string;
	requestType: LeadRequestType;
	serviceType: string;
	formattedDate: string;
	leadRequestLink: string;
}
