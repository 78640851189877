import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { IPropertyForm } from '@clients/models/domain/client.domain';
import { IClientProperty } from '@clients/models/firebase/client.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GooglePlacesAutocompleteComponent } from '@shared/components/forms/google-places-autocomplete/google-places-autocomplete.component';
import { StandardControlComponent } from '@shared/components/forms/standard-control/standard-control.component';
import { IGoogleProperty } from '@shared/models/property';

@Component({
	selector: 'app-add-property-modal',
	standalone: true,
	imports: [CommonModule, GooglePlacesAutocompleteComponent, FormsModule, ReactiveFormsModule, StandardControlComponent],
	templateUrl: './add-property-modal.component.html',
	styleUrl: './add-property-modal.component.scss',
})
export class AddPropertyModalComponent implements OnInit {
	@Output() propertySelected = new EventEmitter<IGoogleProperty>();
	private activeModal = inject(NgbActiveModal);

	selectedProperty: IGoogleProperty;
	propertyForm: FormGroup<IPropertyForm>;

	ngOnInit() {
		this.propertyForm = new FormGroup<IPropertyForm>({
			fullAddress: new FormControl(null, [Validators.required, Validators.minLength(2)]),
			suburb: new FormControl(null, [Validators.required, Validators.minLength(2)]),
			city: new FormControl(null, [Validators.required, Validators.minLength(2)]),
			province: new FormControl(null, [Validators.required, Validators.minLength(2)]),
			country: new FormControl(null, [Validators.required, Validators.minLength(2)]),
			postalCode: new FormControl(null, [Validators.required, Validators.minLength(2)]),
		});
	}

	closeModal(): void {
		this.activeModal.close();
	}

	onPlaceSelected(property: IGoogleProperty) {
		this.selectedProperty = property;
		this.propertyForm.patchValue(property);
	}

	submit(): void {
		if (this.propertyForm.invalid) {
			this.propertyForm.markAllAsTouched();
			return;
		}
		this.propertySelected.emit(this.propertyForm.value as IClientProperty);
		this.closeModal();
	}
}
