<app-loading-state [isLoading]="isLoading" [loadedContent]="loadedContent"></app-loading-state>
<ng-template #loadedContent>
	<div class="page-container">
		<div class="heading">
			<app-page-title [title]="organisation.companyName" [showLeadFormLink]="false"></app-page-title>
		</div>
		<div class="content">
			<div class="subpage-container">
				<ng-container *ngFor="let leadAssessment of leadAssessments; let i = index">
					<app-alert
						*ngIf="leadAssessment.status === ScheduledAssessmentStatus.REQUESTED"
						[content]="alertContent"
						[alertType]="AlertTypes.INFO"
						[dismissible]="true"
						[marginBottom]="0"
					></app-alert>
					<ng-template #alertContent>
						You have a new request from your service specialist scheduled for
						{{ leadAssessment.selectedStartTime | date: 'EEEE dd MMMM' }}
						at {{ leadAssessment.selectedStartTime | date: 'h:mma' }}.
					</ng-template>
				</ng-container>

				<div class="section-group">
					<div class="section">
						<div class="section-column">
							<h6 class="text-primary">Customer Details</h6>
							<div class="section-information">
								<div class="label">
									<div class="name">Customer Name</div>
									<div class="value">{{ leadRequest.personalDetails.name }}</div>
								</div>
								<div class="label">
									<div class="name">Phone Number</div>
									<div class="value">
										({{ leadRequest.mobileNumber.countryCode }} ) {{ leadRequest.mobileNumber.number }}
									</div>
								</div>
								<div class="label">
									<div class="name">Email</div>
									<div class="value">{{ leadRequest.personalDetails.emailAddress }}</div>
								</div>
								<div class="label" *ngIf="leadRequest.requestType === LeadRequestType.ON_SITE_VISIT">
									<div class="name">Address</div>
									<div class="value">
										<app-link-location [property]="leadRequest.personalDetails.installationAddress"></app-link-location>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="section">
						<div class="section-column">
							<form [formGroup]="notesForm">
								<h6 class="text-primary">Assessment Notes</h6>
								<div class="section-information">
									<p class="explainer">
										If you have any updates or additional details that weren't included in your initial request, please
										type them into the notes box and click save to notify your specialist.
									</p>

									<ng-container *ngIf="leadRequest.requestType === LeadRequestType.CALL">
										<textarea
											class="form-control notes-input"
											formControlName="callNotes"
											placeholder="Any additional information we may need to know about?"
										></textarea>
										<button
											type="button"
											class="btn btn-sm btn-primary"
											(click)="updateCallNotes()"
											[disabled]="notesForm.controls.callNotes.invalid || notesForm.controls.callNotes.pristine"
										>
											<ng-container *ngIf="isSaving; else notSaving">
												<div class="spinner-border spinner-border-sm" role="status">
													<span class="visually-hidden">Loading...</span>
												</div>
											</ng-container>
											<ng-template #notSaving>Save</ng-template>
										</button>
									</ng-container>
									<ng-container *ngIf="leadRequest.requestType === LeadRequestType.ON_SITE_VISIT">
										<textarea
											class="form-control notes-input"
											formControlName="onsiteNotes"
											placeholder="Any additional information we may need to know about?"
										></textarea>
										<button
											type="button"
											class="btn btn-sm btn-primary"
											(click)="updateOnSiteNotes()"
											[disabled]="notesForm.controls.callNotes.invalid || notesForm.controls.onsiteNotes.pristine"
										>
											<ng-container *ngIf="isSaving; else notSaving">
												<div class="spinner-border spinner-border-sm" role="status">
													<span class="visually-hidden">Loading...</span>
												</div>
											</ng-container>
											<ng-template #notSaving>Save</ng-template>
										</button>
									</ng-container>
								</div>
							</form>
						</div>
					</div>
				</div>

				<div class="section-group">
					<div class="section">
						<div class="section-column">
							<div class="section-header">
								<h6 class="text-primary">Request Tracking</h6>
							</div>
							<ng-container *ngIf="leadAssessments.length; else noScheduledItems">
								<ng-container *ngFor="let item of leadAssessments; let i = index">
									<app-lead-assessment-scheduled-item
										[item]="item"
										(accept)="acceptAppointment($event)"
										(decline)="declineAppointment($event)"
									></app-lead-assessment-scheduled-item>
								</ng-container>
							</ng-container>
							<ng-template #noScheduledItems>
								<em>Your request has not been actioned yet.</em>
							</ng-template>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>
