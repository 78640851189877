export const environment = {
	production: false,
	baseUrl: 'https://app.dev.serveox.com',
	firebase: {
		apiKey: 'AIzaSyBqrkKthtxhfe8aK15stKkkJxl-xGnHyv4',
		authDomain: 'app.dev.serveox.com',
		projectId: 'serveox-mvp-dev',
		storageBucket: 'serveox-mvp-dev.firebasestorage.app',
		messagingSenderId: '138621666502',
		appId: '1:138621666502:web:c2ab9d27398b39218743c7',
		measurementId: 'G-M2HDL43T5G',
	},
	recaptchaPublicKey: '6LclmcAqAAAAAP10A1ZmixyhjnaRdQd_OKZE0uqI',
	recaptchaDebugEnabled: false,
	googleMapsApiKey: 'AIzaSyD5Y5qq62d483fk2wHZxzkyWKI3JZF8vL0',
	termsAndConditionsUrl: 'https://serveox.com/terms-and-conditions',
	privacyPolicyUrl: 'https://serveox.com/privacy-policy',
};
