<div class="subpage-container">
	<form [formGroup]="form" class="h-100">
		<div class="form-container">
			<app-header-popover heading="Installation Address" [infoPopOver]="infoPopOver"></app-header-popover>
			<div class="form-row">
				<h6 class="text-primary m-0">Search Address</h6>
				<app-google-places-autocomplete (placeSelected)="onPlaceSelected($event)"></app-google-places-autocomplete>
				<h6 class="text-primary m-0">Or enter property manually</h6>
			</div>

			<form [formGroup]="form" class="body">
				<div class="row-group">
					<app-standard-control
						class="control"
						type="text"
						name="fullAddress"
						[formGroup]="form"
						label="Full Address"
						placeholder="Full Address"
						autocomplete="address-level1"
					></app-standard-control>

					<app-standard-control
						class="control"
						type="text"
						name="suburb"
						[formGroup]="form"
						label="Suburb"
						placeholder="Suburb"
					></app-standard-control>
				</div>

				<div class="row-group">
					<app-standard-control
						class="control"
						type="text"
						name="city"
						[formGroup]="form"
						label="City"
						placeholder="City"
					></app-standard-control>

					<app-standard-control
						class="control"
						type="text"
						name="province"
						[formGroup]="form"
						label="Province"
						placeholder="Province"
					></app-standard-control>
				</div>

				<div class="row-group">
					<app-standard-control
						class="control"
						type="text"
						name="country"
						[formGroup]="form"
						label="Country"
						placeholder="Country"
					></app-standard-control>

					<app-standard-control
						class="control"
						type="text"
						name="postalCode"
						[formGroup]="form"
						label="Postal Code"
						placeholder="Postal Code"
					></app-standard-control>
				</div>
			</form>
		</div>
	</form>
</div>
