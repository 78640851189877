<app-base-modal [showFooter]="false" title="Scheduled Items">
	<div content>
		<div class="section-column">
			<div *ngIf="!readonlyView" class="action-buttons">
				<button (click)="openScheduler()" class="btn btn-sm btn-primary" type="button">
					<span class="btn-text">Open Scheduler</span>
				</button>
				<button (click)="addCallAssessment()" class="btn btn-sm btn-primary" type="button">
					<span class="btn-text">Schedule Call</span>
				</button>
				<button (click)="addOnSiteAssessment()" class="btn btn-sm btn-primary" type="button">
					<span class="btn-text">Schedule On Site</span>
				</button>
			</div>
			<ng-container *ngIf="scheduledItems.length; else noScheduledItems">
				<app-accordion parentId="scheduledItems">
					<ng-container *ngFor="let item of scheduledItems; let i = index">
						<div class="d-flex flex-row justify-content-between w-100">
							<div class="w-100">
								<app-accordion-item
									parentId="scheduledItems"
									[itemId]="i.toString()"
									(toggle)="openScheduledItemsListItemModal(item, i)"
									[disableExpand]="true"
									[lineHeight]="26"
								>
									<div header class="w-100">
										<app-scheduled-item-title
											[index]="i"
											[scheduledItem]="item"
											[readonlyView]="readonlyView"
											[mobile]="true"
										></app-scheduled-item-title>
									</div>
									<div actions>
										<app-scheduled-item-actions
											[scheduledItem]="item"
											[readonlyView]="readonlyView"
											[mobile]="true"
											(cancelAssessment)="cancelAssessment($event)"
											(removeAssessment)="removeAssessment($event)"
										></app-scheduled-item-actions>
									</div>
								</app-accordion-item>
							</div>
						</div>
					</ng-container>
				</app-accordion>
			</ng-container>
			<ng-template #noScheduledItems>
				<em class="small-text">No assessments have been scheduled.</em>
			</ng-template>
		</div>
	</div>
</app-base-modal>
