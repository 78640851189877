<div class="property-container">
	<app-page-subtitle title="Client Properties"></app-page-subtitle>

	<div class="table-container">
		<div class="table-heading">
			<div>Street Address</div>
			<div>Suburb</div>
			<div>City</div>
			<div>Province</div>
			<div>Country</div>
			<div>Postal Code</div>
		</div>

		<ng-container *ngIf="properties.length === 0; else propertiesTable">
			<div class="table-row">
				<div class="column">
					<em class="fw-bold">There have been no properties added yet!</em>
				</div>
			</div>
		</ng-container>
		<ng-template #propertiesTable>
			<ng-container *ngFor="let property of properties; let i = index">
				<div class="table-row" role="button" (click)="openEditProperty(property, i)">
					<div class="column">
						{{ property.fullAddress }}
					</div>
					<div class="column">
						{{ property.suburb }}
					</div>
					<div class="column">
						{{ property.city }}
					</div>
					<div class="column">
						{{ property.province }}
					</div>
					<div class="column">
						{{ property.country }}
					</div>
					<div class="column">
						{{ property.postalCode }}
					</div>
				</div>
			</ng-container>
		</ng-template>
	</div>

	<div class="d-flex justify-content-end">
		<button type="button" class="btn btn-sm btn-primary btn-width" (click)="openAddProperty()">
			Add property
			<i class="bi bi-plus"></i>
		</button>
	</div>
</div>
