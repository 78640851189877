<ng-select
	[items]="statusOptions"
	[(ngModel)]="status"
	(ngModelChange)="statusChange.emit($event)"
	class="w-100"
	bindLabel="label"
	bindValue="value"
	placeholder="Assessment Status"
	[clearable]="false"
	[disabled]="readonlyView"
	[searchable]="false"
>
	<ng-template ng-label-tmp let-item="item">
		<div>
			<ng-container *ngIf="item.icon">
				<i class="bi {{ item.icon }}"></i>
			</ng-container>
			{{ item.label }}
		</div>
	</ng-template>
</ng-select>
