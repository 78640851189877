<div class="modal-header">
	<ng-container *ngIf="isTemplate(title); else stringContent">
		<ng-container *ngTemplateOutlet="title"></ng-container>
	</ng-container>
	<ng-template #stringContent>
		<h6 class="modal-title">{{ title }}</h6>
	</ng-template>
	<button type="button" class="btn-close" (click)="closeModal()" aria-label="Close"></button>
</div>
<div
	class="modal-body"
	[ngStyle]="{ padding: padding != undefined && padding > 0 ? padding : '1rem' }"
	[style.height]="showFooter ? 'calc(100% - 64px)' : '100%'"
>
	<ng-content select="[content]"></ng-content>
</div>
<div class="modal-footer" *ngIf="showFooter != undefined ? showFooter : true">
	<ng-content select="[footer]"></ng-content>
</div>
