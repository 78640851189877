import { CommonModule } from '@angular/common';
import { Component, Input, TemplateRef } from '@angular/core';

@Component({
	selector: 'app-loading-state',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './loading-state.component.html',
	styleUrl: './loading-state.component.scss',
	host: {
		class: 'h-100 w-100',
	},
})
export class LoadingStateComponent {
	@Input() isLoading: boolean;
	@Input() loadedContent!: TemplateRef<unknown>;
}
