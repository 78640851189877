import { takeUntil } from 'rxjs';

import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { LayoutService } from '@layout/services/layout/layout.service';
import { LeadRequestFormLinkDirective } from '@leads/shared/directives/lead-request-form-link.directive';
import { LeadRequestFormLinkService } from '@leads/shared/services/lead-request-form-link/lead-request-form-link.service';

@Component({
	selector: 'app-page-title',
	standalone: true,
	imports: [CommonModule, RouterLink, LeadRequestFormLinkDirective],
	templateUrl: './page-title.component.html',
	styleUrl: './page-title.component.scss',
})
export class PageTitleComponent implements OnInit, OnDestroy {
	@Input() title: string;
	@Input() backPath: string;
	@Input() showLeadFormLink = true;

	destroyed$ = new EventEmitter<void>();

	isMobileSize = true;

	private leadsLinkService = inject(LeadRequestFormLinkService);
	private layoutService = inject(LayoutService);

	ngOnInit(): void {
		this.layoutService.isMobileSize$.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
			this.isMobileSize = value;
		});
	}

	ngOnDestroy(): void {
		this.destroyed$.emit();
	}
	copyToClipboard() {
		this.leadsLinkService.copyNewLeadLink();
	}
}
