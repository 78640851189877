import { take } from 'rxjs';
import SignaturePad from 'signature_pad';

import { CommonModule } from '@angular/common';
import { Component, ElementRef, inject, Input, OnInit, ViewChild } from '@angular/core';
import { LeadAssessmentService } from '@leads/shared/services/lead-assessment/lead-assessment.service';
import { LeadStorageService } from '@leads/shared/services/lead-storage/lead-storage.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertComponent } from '@shared/components/alerts/alert/alert.component';
import { LoadingStateComponent } from '@shared/components/loading-state/loading-state.component';
import { ConfirmationModalComponent } from '@shared/components/modals/confirmation-modal/confirmation-modal.component';
import { AlertTypes } from '@shared/models/alert';
import { ModalService } from '@shared/services/modal/modal.service';

@Component({
	selector: 'app-job-card-modal',
	standalone: true,
	imports: [CommonModule, LoadingStateComponent, AlertComponent],
	templateUrl: './job-card-modal.component.html',
	styleUrl: './job-card-modal.component.scss',
})
export class JobCardModalComponent implements OnInit {
	@Input() organisationId: string;
	@Input() leadRequestId: string;
	@Input() leadAssessmentId: string;
	@Input() customerName: string;

	private activeModal = inject(NgbActiveModal);
	private modalService = inject(ModalService);
	private leadStorage = inject(LeadStorageService);
	private leadAssessmentService = inject(LeadAssessmentService);

	customerSignatureUrl: string | null;
	customerSignedNotes: string | null;
	assessmentNotes: string | null;
	isLoading = true;
	isImgLoaded = false;

	@ViewChild('signatureCanvas') signatureCanvas!: ElementRef<HTMLCanvasElement>;
	signaturePad!: SignaturePad;

	AlertTypes = AlertTypes;

	ngOnInit() {
		this.leadStorage.getCustomerAssessmentSignatureUrl(this.organisationId, this.leadRequestId, this.leadAssessmentId).then((url) => {
			this.customerSignatureUrl = url;
			this.isLoading = false;

			if (!this.customerSignatureUrl) {
				this.initSignaturePad();
			}
		});

		this.leadAssessmentService
			.getLeadAssessment(this.leadAssessmentId)
			.pipe(take(1))
			.subscribe((leadAssessment) => {
				this.customerSignedNotes = leadAssessment?.assessmentDetails.customerSignedNotes!;
				this.assessmentNotes = leadAssessment?.assessmentDetails.internalAssessmentNotes!;
			});
	}

	initSignaturePad() {
		setTimeout(() => {
			const canvas = this.signatureCanvas.nativeElement;
			this.signaturePad = new SignaturePad(canvas, {
				minWidth: 1,
				maxWidth: 2.5,
				penColor: 'black',
				backgroundColor: 'rgba(255, 255, 255, 0)',
			});

			this.resizeCanvas();
		});
	}

	private resizeCanvas() {
		const canvas = this.signatureCanvas.nativeElement;
		const ratio = Math.max(window.devicePixelRatio || 1, 1);
		canvas.width = canvas.offsetWidth * ratio;
		canvas.height = canvas.offsetHeight * ratio;
		canvas.getContext('2d')?.scale(ratio, ratio);

		this.signaturePad.clear();
	}

	clearSignature() {
		this.signaturePad.clear();
	}

	async saveSignature() {
		const modalRef = this.modalService.open(ConfirmationModalComponent, false, 'md');
		modalRef.componentInstance.title = 'Are you sure you want to continue?';
		modalRef.componentInstance.contentText = 'This cannot be edited once confirmed';
		modalRef.componentInstance.confirmAction.pipe(take(1)).subscribe(() => {
			const dataURL = this.signaturePad.toDataURL('image/png');
			const blob = this.dataURLToBlob(dataURL);

			this.leadStorage.uploadCustomerAssessmentSignature(
				this.organisationId,
				this.leadRequestId,
				this.leadAssessmentId,
				this.customerName,
				blob,
			);

			this.leadAssessmentService.update(this.leadAssessmentId, {
				'assessmentDetails.customerSignedNotes': this.assessmentNotes!,
			});
			this.closeModal();
		});
	}

	closeModal(): void {
		this.activeModal.close();
	}

	private dataURLToBlob(dataURL: string): Blob {
		const parts = dataURL.split(',');
		const byteString = atob(parts[1]);
		const mimeString = parts[0].split(':')[1].split(';')[0];

		const byteArray = new Uint8Array(byteString.length);
		for (let i = 0; i < byteString.length; i++) {
			byteArray[i] = byteString.charCodeAt(i);
		}

		return new Blob([byteArray], { type: mimeString });
	}
}
