import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ScheduledAssessmentStatus, ScheduledAssessmentStatusLabels } from '@leads/shared/models/domain/scheduled-assessment.domain';

@Component({
	selector: 'app-assessment-status-badge',
	standalone: true,
	imports: [NgClass],
	templateUrl: './assessment-status-badge.component.html',
	styleUrl: './assessment-status-badge.component.scss',
})
export class AssessmentStatusBadgeComponent {
	@Input() status: ScheduledAssessmentStatus;

	readonly ScheduledAssessmentStatusLabels = ScheduledAssessmentStatusLabels;

	get assessmentStatusClass(): string {
		switch (this.status) {
			case ScheduledAssessmentStatus.REQUESTED:
				return 'text-bg-warning';
			case ScheduledAssessmentStatus.SCHEDULED:
				return 'text-bg-success';
			case ScheduledAssessmentStatus.CANCELLED:
			case ScheduledAssessmentStatus.DECLINED:
				return 'text-bg-danger';
			case ScheduledAssessmentStatus.COMPLETED:
				return 'text-bg-primary';
			default:
				return 'text-bg-primary';
		}
	}
}
