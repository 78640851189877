import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-accordion',
	standalone: true,
	imports: [],
	templateUrl: './accordion.component.html',
	styleUrl: './accordion.component.scss',
})
export class AccordionComponent {
	@Input() parentId: string;
	@Input() gap: string = '16px';
}
