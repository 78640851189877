import { ScheduledAssessmentStatus } from '@leads/shared/models/domain/scheduled-assessment.domain';
import { IDropdownOption } from '@shared/components/forms/dropdown-control/dropdown-control.component';

export const scheduledAssessmentStatusOptions: Array<IDropdownOption> = [
	{
		id: 'requested',
		icon: 'bi-list-task',
		value: ScheduledAssessmentStatus.REQUESTED,
		label: 'Requested',
	},
	{
		id: 'scheduled',
		icon: 'bi-calendar-check',
		value: ScheduledAssessmentStatus.SCHEDULED,
		label: 'Scheduled',
	},
	{
		id: 'declined',
		icon: 'bi-calendar-check',
		value: ScheduledAssessmentStatus.DECLINED,
		label: 'Customer Declined',
	},
	{
		id: 'awaiting-invoice',
		icon: 'bi-hourglass-split',
		value: ScheduledAssessmentStatus.AWAITING_INVOICE,
		label: 'Awaiting Invoice',
	},
	{
		id: 'invoiced',
		icon: 'bi-receipt',
		value: ScheduledAssessmentStatus.INVOICED,
		label: 'Invoiced',
	},
	{
		id: 'partially-paid',
		icon: 'bi-check',
		value: ScheduledAssessmentStatus.PARTIALLY_PAID,
		label: 'Partially Paid',
	},
	{
		id: 'completed',
		icon: 'bi-check-circle',
		value: ScheduledAssessmentStatus.COMPLETED,
		label: 'Completed',
	},
];
