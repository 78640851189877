import { CalendarEvent } from 'angular-calendar';
import { EventAction } from 'calendar-utils';

import { CommonModule, NgForOf } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AssessmentStatusDropdownComponent } from '@leads/shared/components/assessment-status-dropdown/assessment-status-dropdown.component';
import { LeadRequestType } from '@leads/shared/models/domain/lead-request.domain';
import { ScheduledAssessmentStatus, ScheduledAssessmentStatusLabels } from '@leads/shared/models/domain/scheduled-assessment.domain';
import { NgSelectModule } from '@ng-select/ng-select';
import { PopoverChange, PopoverChangeEvent, ScheduledEvent } from '@schedule/models/calendar/calendar.model';
import { scheduledAssessmentStatusOptions } from '@schedule/models/leads/lead-assessment.domain';
import { LinkLocationComponent } from '@shared/components/buttons/link-location/link-location.component';
import { IDropdownOption } from '@shared/components/forms/dropdown-control/dropdown-control.component';
import { NullDefaultPipe } from '@shared/pipes/null-default.pipe';

const onSiteVisitProperties = ['date_and_time', 'customer_name', 'customer_property_address', 'employee_name', 'job_type', 'status'];

const callProperties = ['date_and_time', 'customer_name', 'customer_number', 'employee_name', 'status'];

const unconfirmedAssessment = ['date_and_time', 'customer_name', 'customer_property_address', 'employee_name', 'job_type', 'status'];

const calendarEvent = ['date_and_time', 'customer_name', 'customer_property_address', 'employee_name', 'job_type', 'status'];

@Component({
	selector: 'app-calendar-popover',
	standalone: true,
	imports: [
		CommonModule,
		NgForOf,
		NullDefaultPipe,
		NgSelectModule,
		FormsModule,
		AssessmentStatusDropdownComponent,
		LinkLocationComponent,
	],
	templateUrl: './calendar-popover.component.html',
	styleUrl: './calendar-popover.component.scss',
	host: {
		class: 'h-100',
	},
})
export class CalendarPopoverComponent implements OnInit, OnDestroy {
	@Input({ alias: 'event' }) calendarEvent: CalendarEvent<ScheduledEvent>;
	@Input() isOwner: boolean = false;
	@Output() closed = new EventEmitter<void>();

	get scheduledEvent(): ScheduledEvent {
		return this.calendarEvent.meta!;
	}

	scheduledAssessmentStatusOptions: Array<IDropdownOption> = scheduledAssessmentStatusOptions;

	protected scheduledAssessmentStatus: ScheduledAssessmentStatus;

	protected readonly LeadRequestType = LeadRequestType;
	protected readonly ScheduledAssessmentStatusLabels = ScheduledAssessmentStatusLabels;

	ngOnInit(): void {
		this.scheduledAssessmentStatus = this.scheduledEvent.scheduledAssessmentStatus;
	}

	ngOnDestroy(): void {}

	onActionClick(action: EventAction, sourceEvent: MouseEvent | KeyboardEvent): void {
		this.closed.emit();
		action.onClick({ event: this.calendarEvent, sourceEvent });
	}

	statusChange(status: ScheduledAssessmentStatus): void {
		this.popoverAction(PopoverChange.SCHEDULED_ASSESSMENT_STATUS).onChange(status, this.scheduledEvent);
	}

	private popoverAction(action: PopoverChange): PopoverChangeEvent {
		return <PopoverChangeEvent>this.scheduledEvent.popoverChanges!.find((item) => item.action === action);
	}

	get eventTypeLabel(): string {
		if (this.scheduledEvent.leadRequestType === LeadRequestType.CALL) {
			return 'Call Assessment';
		}
		if (this.scheduledEvent.leadRequestType === LeadRequestType.ON_SITE_VISIT) {
			return 'On Site Assessment';
		}
		if (this.scheduledEvent.leadRequestType === null) {
			return 'Unconfirmed Assessment';
		}
		return 'Event';
	}

	get jobStatusLabel(): string {
		if (this.scheduledEvent.leadRequestType === LeadRequestType.CALL) {
			return 'Call Status';
		}
		if (this.scheduledEvent.leadRequestType === LeadRequestType.ON_SITE_VISIT) {
			return 'Job Status';
		}
		return '';
	}

	get dateTimeRange(): string {
		return Intl.DateTimeFormat('en-ZA', {
			weekday: 'short',
			month: 'short',
			day: '2-digit',
			hour: '2-digit',
			minute: '2-digit',
		}).formatRange(this.calendarEvent.start, this.calendarEvent.end!);
	}

	/**
	 * Determine the schedule event type
	 * - On Site
	 * - Call
	 * - Unconfirmed assessment
	 * - Calendar event
	 * @param propertyName
	 * @protected
	 */
	protected showProperty(propertyName: string): boolean {
		if (this.scheduledEvent.leadRequestType === LeadRequestType.ON_SITE_VISIT) {
			return onSiteVisitProperties.includes(propertyName);
		}
		if (this.scheduledEvent.leadRequestType === LeadRequestType.CALL) {
			return callProperties.includes(propertyName);
		}
		// Unconfirmed assessment
		if (this.scheduledEvent.leadRequestType === null) {
			return unconfirmedAssessment.includes(propertyName);
		}
		// Assume calendar event
		return calendarEvent.includes(propertyName);
	}
}
