<div class="step-container">
	<div class="content">
		<app-loading-state [isLoading]="isLoading" [loadedContent]="loadedContent"></app-loading-state>
		<ng-template #loadedContent>
			<ng-container [ngSwitch]="requestType.value">
				<div *ngSwitchCase="LeadRequestType.CALL">
					<app-lead-notes
						title="Call Back / Quote Request Notes"
						[form]="leadRequestForm.controls.callAssessment"
					></app-lead-notes>
				</div>
				<div *ngSwitchCase="LeadRequestType.ON_SITE_VISIT" class="assessment-details">
					<ng-container *ngIf="isMobileSize; else desktopSize">
						<app-mobile-lead-assessment-details
							[form]="leadRequestForm.controls.onsiteAssessment"
							[disabledDaysOfTheWeek]="disabledDaysOfTheWeek"
							[serviceTypes]="serviceTypes"
						></app-mobile-lead-assessment-details>
					</ng-container>
					<ng-template #desktopSize>
						<app-desktop-lead-assessment-details
							[form]="leadRequestForm.controls.onsiteAssessment"
							[disabledDaysOfTheWeek]="disabledDaysOfTheWeek"
							[serviceTypes]="serviceTypes"
						></app-desktop-lead-assessment-details>
					</ng-template>
					<app-lead-notes title="Assessment Notes" [form]="leadRequestForm.controls.onsiteAssessment"></app-lead-notes>
				</div>
			</ng-container>
		</ng-template>
	</div>

	<div class="footer">
		<button type="button" class="btn btn-sm btn-light btn-width" [routerLink]="'../' + CustomerLeadRequestRoutes.PERSONAL_DETAILS">
			<i class="bi bi-arrow-left"></i>
			Previous
		</button>
		<button type="button" class="btn btn-sm btn-primary btn-width" (click)="submit()" [disabled]="leadRequestForm.invalid">
			<ng-container *ngIf="isSubmitting; else notSubmitting">
				<div class="spinner-border spinner-border-sm" role="status">
					<span class="visually-hidden">Loading...</span>
				</div>
			</ng-container>
			<ng-template #notSubmitting>Submit Assessment</ng-template>
		</button>
	</div>
</div>
