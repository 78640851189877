import { Injectable } from '@angular/core';
import {
	addDoc,
	collection,
	CollectionReference,
	DocumentReference,
	getDocs,
	orderBy,
	query,
	QuerySnapshot,
	where,
} from '@angular/fire/firestore';
import { IClient, IClientProperty, IClientWithProperties } from '@clients/models/firebase/client.model';
import { FireStoreCollection } from '@shared/models/firestore';
import { FirestoreService } from '@shared/services/firestore/firestore.service';
import { combineLatest, from, map, Observable, of, switchMap } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class ClientsService extends FirestoreService<IClient> {
	constructor() {
		super(FireStoreCollection.CLIENTS);
	}

	getClientByMobileNumber(organisationRef: DocumentReference, mobileNumber: string) {
		const clientsQuery = query(
			this.collectionRef,
			where('organisation', '==', organisationRef),
			where('clientNumber.number', '==', mobileNumber),
		);
		return getDocs(clientsQuery);
	}

	getClientsWithPropertiesByOrganisation(
		organisationRef: DocumentReference,
		searchTerm: string,
	): Observable<Array<IClientWithProperties>> {
		let clientsQuery = query(this.collectionRef, where('organisation', '==', organisationRef));

		if (searchTerm) {
			clientsQuery = query(
				clientsQuery,
				where('clientNameLower', '>=', searchTerm.toLowerCase()),
				where('clientNameLower', '<=', searchTerm.toLowerCase() + '\uf8ff'),
			);
		}

		clientsQuery = query(clientsQuery, orderBy('clientNameLower'));

		return from(getDocs(clientsQuery)).pipe(
			switchMap((clientSnapshot: QuerySnapshot<IClient>) => {
				if (clientSnapshot.empty) {
					return of([]);
				}
				const clientWithProperties$ = clientSnapshot.docs.map((doc) => {
					const client = doc.data();
					return this.getClientProperties(doc.id).pipe(
						map((properties) => ({
							...client,
							id: doc.id,
							properties,
						})),
					);
				});
				return combineLatest(clientWithProperties$);
			}),
		);
	}

	private getClientProperties(clientId: string): Observable<Array<IClientProperty>> {
		const propertiesRef = collection(
			this.firestore,
			`${FireStoreCollection.CLIENTS}/${clientId}/properties`,
		) as CollectionReference<IClientProperty>;
		const propertiesQuery = query(propertiesRef);

		return from(getDocs(propertiesQuery)).pipe(
			map((querySnapshot) => querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }) as IClientProperty)),
		);
	}

	async createClient(client: IClient, properties: Array<IClientProperty>) {
		// ToDo: Check if client contact number exists before saving
		return super.create(client).then((document) => {
			const docId = document.id;
			const propertiesRef = collection(this.firestore, `${this.fireStoreCollection}/${docId}/properties`);

			properties.forEach((property) => {
				addDoc(propertiesRef, property);
			});
			return document;
		});
	}

	getClientWithProperties(clientId: string): Observable<IClientWithProperties | null> {
		return from(super.getDocSnapshot(clientId)).pipe(
			switchMap((clientSnapshot) => {
				if (!clientSnapshot.exists()) {
					return of(null);
				}

				const client = clientSnapshot.data();

				return this.getClientProperties(clientId).pipe(
					map((properties) => ({
						...client,
						id: clientSnapshot.id,
						properties,
					})),
				);
			}),
		);
	}

	async searchClientProperty(clientId: string, property: IClientProperty): Promise<QuerySnapshot<IClientProperty>> {
		const propertiesRef = collection(
			this.firestore,
			`${this.fireStoreCollection}/${clientId}/properties`,
		) as CollectionReference<IClientProperty>;

		let propertiesQuery = query(
			propertiesRef,
			where('fullAddress', '==', property.fullAddress),
			where('city', '==', property.city),
			where('province', '==', property.province),
			where('country', '==', property.country),
			where('postalCode', '==', property.postalCode),
		);

		return await getDocs(propertiesQuery);
	}

	async addClientProperty(clientId: string, property: IClientProperty): Promise<DocumentReference> {
		const propertiesRef = collection(this.firestore, `${this.fireStoreCollection}/${clientId}/properties`);
		return await addDoc(propertiesRef, property);
	}

	async removeClientProperty(clientId: string, propertyId: string): Promise<void> {
		return await super.delete(`${clientId}/properties/${propertyId}`);
	}

	async editClientProperty(clientId: string, property: IClientProperty): Promise<void> {
		return super.update(`${clientId}/properties/${property.id}`, property);
	}
}
