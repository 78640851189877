import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'ellipsis',
	standalone: true
})
export class EllipsisPipe implements PipeTransform {
	transform(value: string | unknown, maxLength: number = 20): string {
		if (!value) return '';
		if (typeof value !== 'string') return '';
		const str = value as string;
		if (str.length <= maxLength) return str;
		return str.substring(0, maxLength) + '...';
	}
}
