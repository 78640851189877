<div class="actions d-flex flex-row gap-1" *ngIf="showButtons">
	<button
		*ngIf="canRemove"
		class="btn btn-sm btn-outline-danger"
		[ngClass]="{ 'disabled': readonlyView }"
		type="button"
		(click)="removeAssessment.emit(editScheduledItem ? null : item)"
	>
		<i class="bi bi-trash" *ngIf="mobile"></i>
		<span *ngIf="!mobile">Remove</span>
	</button>
	<button
		*ngIf="canCancel"
		class="btn btn-sm btn-outline-danger"
		[ngClass]="{ 'disabled': readonlyView }"
		type="button"
		(click)="cancelAssessment.emit(item)"
	>
		<i class="bi bi-x-lg" *ngIf="mobile"></i>
		<span *ngIf="!mobile">Cancel</span>
	</button>
</div>
