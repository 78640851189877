import { takeUntil } from 'rxjs';
import { AppRoutes } from 'src/app/app.routes';

import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, OnDestroy, OnInit } from '@angular/core';
import { Timestamp } from '@angular/fire/firestore';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '@auth/services/auth.service';
import { LayoutService } from '@layout/services/layout/layout.service';
import { DesktopOrganisationDetailsComponent } from '@organisations/components/forms/desktop-organisation-details/desktop-organisation-details.component';
import { MobileOrganisationDetailsComponent } from '@organisations/components/forms/mobile-organisation-details/mobile-organisation-details.component';
import {
	IOrganisation,
	IOrganisationForm,
	IOrganisationSettings,
	OrganisationIndustry,
	OrganisationStatus,
} from '@organisations/models/organisations';
import { OrganisationRoutes } from '@organisations/organisation.routes';
import { OrganisationService } from '@organisations/services/organisation/organisation.service';
import { PageTitleComponent } from '@shared/components/sections/page-title/page-title.component';
import { ToastTypes } from '@shared/models/toast';
import { ToastService } from '@shared/services/toast/toast.service';

@Component({
	selector: 'app-organisation-create',
	standalone: true,
	imports: [CommonModule, PageTitleComponent, DesktopOrganisationDetailsComponent, MobileOrganisationDetailsComponent],
	templateUrl: './organisation-create.component.html',
	styleUrl: './organisation-create.component.scss',
})
export class OrganisationCreateComponent implements OnInit, OnDestroy {
	private organisationService = inject(OrganisationService);
	private authService = inject(AuthService);
	private layoutService = inject(LayoutService);
	private toastService = inject(ToastService);
	private router = inject(Router);

	organisationForm: FormGroup<IOrganisationForm>;
	destroyed$ = new EventEmitter<void>();
	isMobileSize = true;
	isSaving = false;

	constructor() {
		this.organisationForm = new FormGroup<IOrganisationForm>({
			companyName: new FormControl(null, [Validators.required, Validators.minLength(2), Validators.maxLength(50)]),
			companyContactEmail: new FormControl(null, [Validators.required, Validators.email, Validators.maxLength(50)]),
			companyContactName: new FormControl(null, [Validators.required, Validators.minLength(2), Validators.maxLength(50)]),
			companyContactNumber: new FormControl(null, [
				Validators.required,
				Validators.minLength(6),
				Validators.maxLength(21),
				Validators.pattern(/^[0-9]{10,20}$/),
			]),
			companyRegistrationNumber: new FormControl(null, [Validators.required, Validators.pattern(/^[0-9]{4}\/[0-9]{6}\/[0-9]{2}$/)]),
			companySize: new FormControl(null, [Validators.required, Validators.min(1)]),
			industry: new FormControl(OrganisationIndustry.PLUMBING, [Validators.required]),
		});
	}

	ngOnInit(): void {
		this.layoutService.isMobileSize$.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
			this.isMobileSize = value;
		});
		const previousForm = localStorage.getItem('organisationCreate');

		if (previousForm) {
			this.organisationForm.patchValue(JSON.parse(previousForm));
		}
	}

	ngOnDestroy(): void {
		this.destroyed$.emit();
	}

	handleLocalSave(): void {
		localStorage.setItem('organisationCreate', JSON.stringify(this.organisationForm.value));
	}

	handleNext(): void {
		if (this.organisationForm.invalid) {
			return;
		}
		localStorage.removeItem('organisationCreate');
		this.isSaving = true;

		const user = this.authService.currentUser;

		const defaultOrganisationSettings: IOrganisationSettings = {
			businessHours: {
				monday: true,
				tuesday: true,
				wednesday: true,
				thursday: true,
				friday: true,
				saturday: true,
				sunday: false,
			},
			serviceTypes: [],
			leadsForm: {},
		};
		if (user) {
			this.organisationService
				.createOrganisation(
					{
						...(this.organisationForm.value as IOrganisation),
						settings: defaultOrganisationSettings,
						createDate: Timestamp.now(),
						status: OrganisationStatus.TRIAL,
					},
					user,
				)
				.then(() => {
					this.toastService.showToast({
						title: 'Organisation',
						message: `Successfully created organisation: ${this.organisationForm.controls.companyName.value}`,
						type: ToastTypes.SUCCESS,
					});

					this.isSaving = false;

					this.router.navigate([AppRoutes.ORGANISATION, OrganisationRoutes.USERS]);
				})
				.catch((error) => {
					this.isSaving = false;
					this.toastService.showToast({
						title: 'Organisation',
						message: `Failed to create organisation: ${error.message}`,
						type: ToastTypes.ERROR,
					});
				});
		} else {
			this.isSaving = false;
			this.toastService.showToast({
				title: 'Organisation',
				message: 'Failed to create organisation. You are not logged in.',
				type: ToastTypes.ERROR,
			});
		}
	}
}
