import { Routes } from '@angular/router';
import { LeadRequestMobileNumberComponent } from '@leads/customer-facing/pages/lead-request-mobile-number/lead-request-mobile-number.component';
import { LeadRequestComponent } from '@leads/customer-facing/pages/lead-request/lead-request.component';
import { LeadRequestRequestTypeComponent } from './customer-facing/pages/lead-request-request-type/lead-request-request-type.component';
import { LeadRequestPersonalDetailsComponent } from './customer-facing/pages/lead-request-personal-details/lead-request-personal-details.component';
import { LeadRequestAssessmentDetailsComponent } from './customer-facing/pages/lead-request-assessment-details/lead-request-assessment-details.component';
import { LeadRequestSuccessComponent } from './customer-facing/pages/lead-request-success/lead-request-success.component';
import { LeadRequestViewComponent } from './customer-facing/pages/lead-request-view/lead-request-view.component';

export enum CustomerLeadRequestRoutes {
	// New Lead Request
	NEW = 'new',
	REQUEST_TYPE = 'request-type',
	PERSONAL_DETAILS = 'personal-details',
	APPOINTMENT_TIME = 'appointment-time',
	SUCCESS = 'success/:leadRequestId',

	// View Booking
	VIEW_REQUEST = 'view/:leadRequestId',
}

export const customerLeadRequestRoutes: Routes = [
	{
		path: CustomerLeadRequestRoutes.VIEW_REQUEST,
		component: LeadRequestViewComponent,
		title: 'ServeOx | View Request',
	},
	{
		path: CustomerLeadRequestRoutes.SUCCESS,
		component: LeadRequestSuccessComponent,
		title: 'ServeOx | Request Submitted',
	},
	{
		path: '',
		component: LeadRequestComponent,
		title: 'ServeOx | New Request',
		children: [
			{
				path: '',
				redirectTo: CustomerLeadRequestRoutes.NEW,
				pathMatch: 'full',
			},
			{
				path: CustomerLeadRequestRoutes.NEW,
				component: LeadRequestMobileNumberComponent,
			},
			{
				path: CustomerLeadRequestRoutes.REQUEST_TYPE,
				component: LeadRequestRequestTypeComponent,
			},
			{
				path: CustomerLeadRequestRoutes.PERSONAL_DETAILS,
				component: LeadRequestPersonalDetailsComponent,
			},
			{
				path: CustomerLeadRequestRoutes.APPOINTMENT_TIME,
				component: LeadRequestAssessmentDetailsComponent,
			},
			{
				path: '**',
				redirectTo: CustomerLeadRequestRoutes.NEW,
			},
		],
	},
];
