import {
	CalendarCommonModule,
	CalendarDayModule,
	CalendarEvent,
	CalendarEventTimesChangedEvent,
	CalendarWeekModule,
} from 'angular-calendar';
import { Subject, takeUntil } from 'rxjs';

import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { LayoutService, ScreenSize } from '@layout/services/layout/layout.service';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { CalendarPopoverComponent } from '@schedule/lib/components/calendar-popover/calendar-popover.component';
import { CalendarPopoverServiceService } from '@schedule/lib/services/calendar-popover-service/calendar-popover-service.service';
import { ScheduledEvent } from '@schedule/models/calendar/calendar.model';
import { CalendarSettings, CalendarSettingsService } from '@schedule/services/calendar/calendar-settings/calendar-settings.service';

@Component({
	selector: 'app-calendar-day-view',
	standalone: true,
	imports: [CommonModule, CalendarDayModule, NgbPopover, CalendarWeekModule, CalendarPopoverComponent, CalendarCommonModule],
	templateUrl: './calendar-day-view.component.html',
	styleUrl: './calendar-day-view.component.scss',
})
export class CalendarDayViewComponent implements OnInit, OnDestroy, AfterViewInit {
	destroyed$ = new EventEmitter<void>();
	protected screenSize: ScreenSize;

	protected calendarSettings: CalendarSettings;

	@Input() events: CalendarEvent[];
	@Input() viewDate: Date;
	@Input() refresh: Subject<void>;
	@Input() hidePopup: boolean = false;
	@Input() isOwner: boolean = false;
	@Output() eventClicked = new EventEmitter<CalendarEvent>();
	@Output() eventTimesChanged = new EventEmitter<CalendarEventTimesChangedEvent>();
	@Output() hourSegmentClicked = new EventEmitter<{ date: Date; sourceEvent: MouseEvent }>();

	private calendarSettingsService = inject(CalendarSettingsService);
	private layoutService = inject(LayoutService);
	private popupService = inject(CalendarPopoverServiceService);

	ngOnInit(): void {
		this.calendarSettings = this.calendarSettingsService.getDefaultSettings();

		this.layoutService.isMobileSize$.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
			this.screenSize = value ? ScreenSize.Mobile : ScreenSize.Desktop;
		});
	}

	ngOnDestroy(): void {
		this.destroyed$.emit();
	}

	ngAfterViewInit(): void {
		setTimeout(() => {
			const newAssessmentElements = document.querySelectorAll('.event-title');
			const timeElements = document.querySelectorAll('.cal-time');

			const newAssessmentTarget = Array.from(newAssessmentElements).find((el) => el.textContent?.trim().includes('New Assessment'));
			const timeTarget = Array.from(timeElements).find((el) => el.textContent?.trim() === '6 AM');

			if (newAssessmentTarget) {
				newAssessmentTarget.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
			} else if (timeTarget) {
				timeTarget.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'center' });
			}
		}, 300);
	}

	handleEventClicked(event: CalendarEvent): void {
		this.eventClicked.emit(event);
	}

	toggleEventPopover(popover: NgbPopover, event: CalendarEvent<ScheduledEvent>): void {
		if (this.hidePopup) {
			return;
		}
		this.popupService.toggleEventPopover(this.screenSize, popover, event, this.isOwner);
	}

	onClosePopup(): void {
		this.popupService.closePopup();
	}

	handleEventTimesChanged(event: CalendarEventTimesChangedEvent): void {
		this.eventTimesChanged.emit(event);
	}

	handleHourSegmentClicked($event: { date: Date; sourceEvent: MouseEvent }) {
		this.hourSegmentClicked.emit($event);
	}
}
