import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { NgbCalendar, NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

import { IDisabledDaysOfTheWeek, NgbDayNumber } from './models';

export abstract class DatePickerParent {
	abstract disabledDaysOfTheWeek: IDisabledDaysOfTheWeek;
	abstract datePickerDateChanged(date: Date): void;
}

@Component({
	template: '',
})
export abstract class DatePickerBase<T extends { [K in keyof T]: AbstractControl }> implements OnInit {
	@Input() form!: FormGroup<T>;
	@Input() name!: Extract<keyof T, string | number>;
	@Input() disabledDaysOfTheWeek: IDisabledDaysOfTheWeek;

	@Output() selectedDateChanged = new EventEmitter<Date>();

	today = inject(NgbCalendar).getToday();

	ngOnInit(): void {
		const value = this.form.controls[this.name].value;
		if (!value) {
			this.selectedDateChanged.emit(this.getNextAvailableDate(new Date()));
		}
	}

	dateSelected(ngbDate: NgbDate) {
		this.selectedDateChanged.emit(new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day));
	}

	/**
	 * Disables non-business days on the calendar.
	 * @param date
	 */
	isDisabled = (date: NgbDateStruct): boolean => {
		const currentDate = new Date(date.year, date.month - 1, date.day);
		const day = currentDate.getDay();

		return (
			(day === NgbDayNumber.SUNDAY && this.disabledDaysOfTheWeek.sunday) ||
			(day === NgbDayNumber.MONDAY && this.disabledDaysOfTheWeek.monday) ||
			(day === NgbDayNumber.TUESDAY && this.disabledDaysOfTheWeek.tuesday) ||
			(day === NgbDayNumber.WEDNESDAY && this.disabledDaysOfTheWeek.wednesday) ||
			(day === NgbDayNumber.THURSDAY && this.disabledDaysOfTheWeek.thursday) ||
			(day === NgbDayNumber.FRIDAY && this.disabledDaysOfTheWeek.friday) ||
			(day === NgbDayNumber.SATURDAY && this.disabledDaysOfTheWeek.saturday)
		);
	};

	/**
	 * Find the next available date on the calendar that is not disabled.
	 * @see isDisabled
	 * @param date
	 */
	getNextAvailableDate(date: Date): Date {
		const maxIterations = 30;
		let iterations = 0;
		let nextDate = new Date(date);

		while (
			this.isDisabled({ year: nextDate.getFullYear(), month: nextDate.getMonth() + 1, day: nextDate.getDate() }) &&
			iterations < maxIterations
		) {
			nextDate.setDate(nextDate.getDate() + 1);
			iterations++;
		}

		return iterations < maxIterations ? nextDate : new Date();
	}

	get control() {
		return this.form.controls[this.name];
	}
}
