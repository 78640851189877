import { CalendarEvent, CalendarEventTimesChangedEvent } from 'angular-calendar';
import { filter, Subject, switchMap, takeUntil } from 'rxjs';

import { Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { DocumentReference } from '@angular/fire/firestore';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IClient } from '@clients/models/firebase/client.model';
import { AssessmentScheduleSteps, ISchedulerForm } from '@leads/client-facing/models/domain/scheduled-assessment.domain';
import { LeadRequestType } from '@leads/shared/models/domain/lead-request.domain';
import { ScheduledAssessment, ScheduledAssessmentType } from '@leads/shared/models/domain/scheduled-assessment.domain';
import { ILeadRequest } from '@leads/shared/models/firebase/lead-request.model';
import { LeadAssessmentService } from '@leads/shared/services/lead-assessment/lead-assessment.service';
import { LeadRequestFormLinkService } from '@leads/shared/services/lead-request-form-link/lead-request-form-link.service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { IOrganisationUserWithUserName } from '@organisations/models/organisation-user';
import { IOrganisation } from '@organisations/models/organisations';
import { CalendarSettings, CalendarSettingsService } from '@schedule/services/calendar/calendar-settings/calendar-settings.service';
import { CalendarService } from '@schedule/services/calendar/calendar.service';
import { DatePickerParent } from '@shared/components/calendar/date-picker';
import { IDisabledDaysOfTheWeek } from '@shared/components/calendar/models';
import { dateToNgbDateStruct, getFormattedDateTime } from '@shared/components/calendar/utilities';
import { IDropdownOption } from '@shared/components/forms/dropdown-control/dropdown-control.component';
import { StepNavigatorComponent } from '@shared/components/ui/step-navigator/step-navigator.component';
import { IStepNavigation, IStepNavigatorConfig, NavButtonStyle, StepModes, StepUI } from '@shared/models/step-navigator';

@Component({
	template: '',
})
export abstract class BaseScheduledItemCreate extends DatePickerParent implements OnInit, OnDestroy {
	@Input() scheduledItemRequestType: LeadRequestType;

	@Input() selectedOrganisationRef: DocumentReference<IOrganisation>;
	@Input() employees: Array<IDropdownOption> = [];
	@Input() activeOrganisationUsers: Array<IOrganisationUserWithUserName> = [];

	@Input() client: IClient;
	@Input() leadRequest: ILeadRequest;

	@Input() disabledDaysOfTheWeek: IDisabledDaysOfTheWeek;
	@Input() serviceTypes: Array<IDropdownOption> = [];

	@Output() createdAssessment = new EventEmitter<ScheduledAssessment>();

	@ViewChild('stepNav', { static: true }) stepNav!: StepNavigatorComponent;

	schedulerForm: FormGroup<ISchedulerForm>;

	navigationConfig: IStepNavigatorConfig;
	selectedStep: IStepNavigation;

	submittingAssessment = false;
	assessmentSubmitted: boolean = false;
	assessmentSharedOnWhatsapp: boolean = false;

	destroyed$ = new EventEmitter<void>();
	LeadRequestType = LeadRequestType;
	ScheduledAssessmentType = ScheduledAssessmentType;
	NavigationStepCases = AssessmentScheduleSteps;

	assessmentTypes: Array<IDropdownOption> = [
		{
			id: 'assessment',
			value: ScheduledAssessmentType.ASSESSMENT,
			label: 'Assessment',
			icon: 'bi-clipboard',
		},
		{
			id: 'job',
			value: ScheduledAssessmentType.JOB,
			label: 'Job',
			icon: 'bi-wrench-adjustable',
		},
	];
	readonly CALL_OUT_FEE_MAX = 10_000;

	timePickerSelectedDate: Date = new Date();
	refreshTimePicker = new Subject<void>();

	timePickerEvents: CalendarEvent[] = [];
	selectedEmployeeEvents: CalendarEvent[] = [];

	newAssessmentEvent: CalendarEvent;

	calendarSettings: CalendarSettings;
	private leadAssessmentService = inject(LeadAssessmentService);
	private calendarService = inject(CalendarService);
	private calendarSettingsService = inject(CalendarSettingsService);
	private leadRequestFormLinkService = inject(LeadRequestFormLinkService);

	ngOnInit(): void {
		this.calendarSettings = this.calendarSettingsService.getDefaultSettings();

		this.setupSchedulerForm();
		this.listenToFormChanges();

		this.setNavigation();
		this.selectedStep = this.navigationConfig.steps[0];
	}

	ngOnDestroy(): void {
		this.destroyed$.emit();
	}

	// Navigation

	setNavigation(): void {
		const step1Disabled = false;
		const step2Disabled =
			this.employeeId.invalid || this.selectedDate.invalid || this.selectedStartTime.invalid || this.selectedEndTime.invalid;
		let step3Disabled: boolean = step2Disabled;

		if (this.requestType.value === LeadRequestType.ON_SITE_VISIT) {
			step3Disabled = step3Disabled || (this.assessments?.invalid as boolean);
			if (this.assessmentType?.value === ScheduledAssessmentType.ASSESSMENT) {
				step3Disabled =
					step3Disabled || (this.callOutFeeRands?.invalid as boolean) || (this.includeCallOutFeeComms?.invalid as boolean);
			}
		}

		step3Disabled = step3Disabled || !this.assessmentSubmitted;

		const step1Complete =
			this.employeeId.valid && this.selectedDate.valid && this.selectedStartTime.valid && this.selectedEndTime.valid;
		let step2Complete: boolean = step1Complete;

		if (this.requestType.value === LeadRequestType.ON_SITE_VISIT) {
			step2Complete = step2Complete && (this.assessments?.valid as boolean);
			if (this.assessmentType?.value === ScheduledAssessmentType.ASSESSMENT) {
				step2Complete =
					step2Complete && (this.callOutFeeRands?.valid as boolean) && (this.includeCallOutFeeComms?.valid as boolean);
			}
		}

		step2Complete = step2Complete && this.assessmentSubmitted;
		let step3Complete = step2Complete && this.assessmentSharedOnWhatsapp;

		this.navigationConfig = {
			ui: StepUI.WIZARD,
			mode: StepModes.NG_SWITCH,
			navButtonStyle: NavButtonStyle.CHEVRON,
			showStepChevrons: true,
			showStepNumbers: false,
			showNavButtons: true,
			steps: [
				{
					title: {
						desktop: 'Schedule',
						mobile: 'Schedule',
					},
					icon: 'bi-person',
					switchCase: AssessmentScheduleSteps.ASSIGN_EMPLOYEE,
					disabled: step1Disabled,
					complete: step1Complete,
				},
				{
					title: {
						desktop: 'Assessment Details',
						mobile: 'Assessment Details',
					},
					icon: 'bi-clipboard',
					switchCase: AssessmentScheduleSteps.ASSIGNMENT_DETAILS,
					disabled: step2Disabled,
					complete: step2Complete,
				},
				{
					title: {
						desktop: 'Communications',
						mobile: 'Communications',
					},
					icon: 'bi-telephone',
					switchCase: AssessmentScheduleSteps.COMMUNICATIONS,
					disabled: step3Disabled,
					complete: step3Complete,
				},
			],
		};
	}

	previousTab(): void {
		this.stepNav.scrollToPreviousTab();
	}

	nextTab(): void {
		this.stepNav.scrollToNextTab();
	}

	// Form

	setupSchedulerForm(): void {
		this.schedulerForm = new FormGroup<ISchedulerForm>({
			requestType: new FormControl<LeadRequestType | null>(this.scheduledItemRequestType, [Validators.required]),
			employeeId: new FormControl<string | null>(null, [Validators.required]),
			selectedDate: new FormControl<NgbDateStruct | null>(null, [Validators.required]),
			selectedStartTime: new FormControl<Date | null>(null, [Validators.required]),
			selectedEndTime: new FormControl<Date | null>(null, [Validators.required]),
			assessments: new FormControl<Array<string> | null>([], [Validators.required]),
			assessmentType: new FormControl<ScheduledAssessmentType | null>(null, [Validators.required]),
			callOutFeeRands: new FormControl<number | null>(null, [
				Validators.required,
				Validators.min(0),
				Validators.max(this.CALL_OUT_FEE_MAX),
			]),
			includeCallOutFeeComms: new FormControl<boolean | null>(false, [Validators.required]),
			assessmentNotes: new FormControl<string | null>(null),
			whatsappNotes: new FormControl<string | null>(null),
		});

		switch (this.scheduledItemRequestType) {
			case LeadRequestType.CALL:
				this.schedulerForm.controls.requestType.patchValue(LeadRequestType.CALL);
				this.schedulerForm.controls.assessments?.disable();
				this.schedulerForm.controls.assessmentType?.disable();
				this.schedulerForm.controls.callOutFeeRands?.disable();
				this.schedulerForm.controls.includeCallOutFeeComms?.disable();
				break;
			case LeadRequestType.ON_SITE_VISIT:
				this.schedulerForm.controls.requestType.patchValue(LeadRequestType.ON_SITE_VISIT);
				this.schedulerForm.controls.assessments?.enable();
				this.schedulerForm.controls.assessmentType?.enable();
				this.schedulerForm.controls.callOutFeeRands?.enable();
				this.schedulerForm.controls.includeCallOutFeeComms?.enable();
				break;
		}
	}

	private updateWhatsappNotes(): void {
		const clientName = this.client.clientName;
		const serviceType = this.schedulerForm.controls.requestType.value!;
		const assessmentLink = this.leadRequestFormLinkService.existingAssessment(this.leadRequest.id!);

		const message = `Hi ${clientName},\n
We've scheduled the ${serviceType} you've requested. Please use the link below to approve or decline the proposed meeting time:\n
${assessmentLink}`;

		this.schedulerForm.controls.whatsappNotes.patchValue(message);
	}

	/**
	 * On Calendar Date Change.
	 * @param date
	 */
	datePickerDateChanged(date: Date): void {
		this.updateRequestedDate(date);
		this.updateRequestedTimeSlot(date);
	}

	private updateRequestedDate(date: Date): void {
		this.schedulerForm.controls.selectedDate.patchValue(dateToNgbDateStruct(date));
	}

	/**
	 * Update Time Picker Start and End Time
	 * @param date
	 */
	private updateRequestedTimeSlot(date: Date): void {
		const startDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 8, 0, 0, 0);
		const endDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 8, 30, 0, 0);

		this.timePickerSelectedDate = date;

		this.updateNewAssessmentEvent(startDate, endDate);
		this.updateTimePickerEvents();
	}

	/**
	 * Time picker event time change handler
	 */
	timePickerEventTimesChanged({ newStart, newEnd }: CalendarEventTimesChangedEvent): void {
		const endOfDay = new Date(
			this.schedulerForm.controls.selectedDate.value!.year,
			this.schedulerForm.controls.selectedDate.value!.month - 1,
			this.schedulerForm.controls.selectedDate.value!.day + 1,
			0,
			0,
			0,
			0,
		);
		const startOfDay = new Date(
			this.schedulerForm.controls.selectedDate.value!.year,
			this.schedulerForm.controls.selectedDate.value!.month - 1,
			this.schedulerForm.controls.selectedDate.value!.day,
			0,
			0,
			0,
			0,
		);

		if (newStart.getTime() < startOfDay.getTime()) {
			newStart = startOfDay;
		}
		if (newEnd && newEnd.getTime() > endOfDay.getTime()) {
			newEnd = endOfDay;
		}

		this.updateNewAssessmentEvent(newStart, newEnd!);
		this.updateTimePickerEvents();
	}

	/**
	 * Time picker hour area clicked handler
	 */
	timePickerHourClicked({ date }: { date: Date; sourceEvent: MouseEvent }): void {
		let minsDifference = 15;
		if (this.newAssessmentEvent.end) {
			minsDifference = Math.floor(
				Math.abs(this.newAssessmentEvent.end?.getTime() - this.newAssessmentEvent.start?.getTime()) / 60_000,
			);
		}
		const endOfDay = new Date(
			this.schedulerForm.controls.selectedDate.value!.year,
			this.schedulerForm.controls.selectedDate.value!.month - 1,
			this.schedulerForm.controls.selectedDate.value!.day + 1,
			0,
			0,
			0,
			0,
		);

		let endDate = new Date(date);
		endDate.setMinutes(endDate.getMinutes() + minsDifference);

		if (endDate.getTime() > endOfDay.getTime()) {
			endDate = endOfDay;
		}

		this.updateNewAssessmentEvent(date, endDate);
		this.updateTimePickerEvents();
	}

	/**
	 * Updates the new assessment for the selected time slot.
	 * @param startDate
	 * @param endDate
	 */
	updateNewAssessmentEvent(startDate: Date, endDate: Date) {
		this.newAssessmentEvent = {
			title: `New Assessment: ${getFormattedDateTime(startDate)} to ${getFormattedDateTime(endDate)}`,
			color: {
				primary: '#e4c441',
				secondary: '#e4c441',
				secondaryText: '#fff',
			},
			start: startDate,
			end: endDate,
			draggable: true,
			resizable: {
				beforeStart: true,
				afterEnd: true,
			},
		};
	}

	/**
	 * Update time picker events that are displayed.
	 * Displays employee records for a given day and highlights the new assessment calendar event to be created.
	 */
	updateTimePickerEvents(): void {
		this.timePickerEvents = [this.newAssessmentEvent, ...this.selectedEmployeeEvents];

		this.refreshTimePicker.next();

		this.selectedStartTime.patchValue(this.newAssessmentEvent.start);

		if (this.newAssessmentEvent.end) {
			this.selectedEndTime.patchValue(this.newAssessmentEvent.end);
		}
	}

	get employeeId() {
		return this.schedulerForm.controls.employeeId;
	}

	get selectedDate() {
		return this.schedulerForm.controls.selectedDate;
	}

	get selectedStartTime() {
		return this.schedulerForm.controls.selectedStartTime;
	}

	get selectedEndTime() {
		return this.schedulerForm.controls.selectedEndTime;
	}

	get requestType() {
		return this.schedulerForm.controls.requestType;
	}

	get assessmentType() {
		return this.schedulerForm.controls.assessmentType;
	}

	get assessments() {
		return this.schedulerForm.controls.assessments;
	}

	get callOutFeeRands() {
		return this.schedulerForm.controls.callOutFeeRands;
	}

	get includeCallOutFeeComms() {
		return this.schedulerForm.controls.includeCallOutFeeComms;
	}

	get whatsappNotes() {
		return this.schedulerForm.controls.whatsappNotes;
	}

	/**
	 * Listen to form changes to update validation
	 */
	listenToFormChanges(): void {
		this.schedulerForm.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => {
			this.setNavigation();
		});

		this.assessmentType?.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((newAssessmentType) => {
			switch (newAssessmentType) {
				case ScheduledAssessmentType.ASSESSMENT:
					this.schedulerForm.controls.callOutFeeRands?.enable();
					this.schedulerForm.controls.includeCallOutFeeComms?.enable();
					break;
				case ScheduledAssessmentType.JOB:
					this.schedulerForm.controls.callOutFeeRands?.disable();
					this.schedulerForm.controls.includeCallOutFeeComms?.disable();
					break;
			}
		});

		this.employeeId?.valueChanges
			.pipe(
				takeUntil(this.destroyed$),
				switchMap((employeeId) => {
					const rangeStartDate = new Date(this.timePickerSelectedDate);
					rangeStartDate.setHours(this.calendarSettings.dayStartHour, 0, 0, 0);

					const rangeEndDate = new Date(this.timePickerSelectedDate);
					rangeEndDate.setHours(this.calendarSettings.dayEndHour, 0, 0, 0);

					return this.calendarService.getCalendarEventsByEmployeeId(employeeId!, rangeStartDate, rangeEndDate);
				}),
			)
			.subscribe((calendarEvents) => {
				this.selectedEmployeeEvents = calendarEvents;
				this.updateTimePickerEvents();
			});

		this.selectedDate?.valueChanges
			.pipe(
				takeUntil(this.destroyed$),
				filter((d) => d !== null),
				switchMap((selectedDate) => {
					if (!selectedDate) {
						return [];
					}
					const rangeStartDate = new Date(selectedDate.year, selectedDate.month - 1, selectedDate.day);
					rangeStartDate.setHours(this.calendarSettings.dayStartHour, 0, 0, 0);

					const rangeEndDate = new Date(selectedDate.year, selectedDate.month - 1, selectedDate.day);
					rangeEndDate.setHours(this.calendarSettings.dayEndHour, 0, 0, 0);

					return this.calendarService.getCalendarEventsByEmployeeId(this.employeeId.value!, rangeStartDate, rangeEndDate);
				}),
			)
			.subscribe((calendarEvents) => {
				this.selectedEmployeeEvents = calendarEvents;
				this.updateTimePickerEvents();
			});
	}

	submitAssessment(): void {
		this.submittingAssessment = true;
		this.updateWhatsappNotes();
		this.leadAssessmentService
			.createLeadAssessment(this.schedulerForm, this.leadRequest.id! as string, this.selectedOrganisationRef.id)
			.pipe(takeUntil(this.destroyed$))
			.subscribe((scheduledAssessment) => {
				this.createdAssessment.emit(scheduledAssessment);

				this.submittingAssessment = false;
				this.assessmentSubmitted = true;

				this.setNavigation();

				setTimeout(() => this.nextTab());
				// ToDO: Reset form at some point
			});
	}

	shareToWhatsApp(): void {
		this.assessmentSharedOnWhatsapp = true;
		this.setNavigation();
		const text = this.schedulerForm.controls.whatsappNotes.value;
		window.open(`https://wa.me/${this.client.clientNumber.countryCode}${this.client.clientNumber.number}?text=${text}`, '_blank');
	}
}
