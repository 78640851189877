import { LeadRequestType } from '@leads/shared/models/domain/lead-request.domain';

export class ScheduledAssessment {
	id?: string;

	organisationId: string;
	organisationName: string;

	leadRequestId: string;
	leadRequestType: LeadRequestType;

	assignedEmployee: {
		userId: string;
		name: string;
	};

	selectedStartTime: Date;
	selectedEndTime: Date;

	assessmentServiceTypes?: Array<string>;
	assessmentType?: ScheduledAssessmentType;
	callOutFeeRands?: number;
	includeCallOutFeeComms?: boolean;
	assessmentNotes?: string;
	whatsappNotes?: string;
	status: ScheduledAssessmentStatus;
	customerName: string;
}

export enum ScheduledAssessmentType {
	ASSESSMENT = 'ASSESSMENT',
	JOB = 'JOB',
}

export enum ScheduledAssessmentStatus {
	// Initial
	REQUESTED = 'REQUESTED',

	SCHEDULED = 'SCHEDULED',
	DECLINED = 'DECLINED',

	CANCELLED = 'CANCELLED',

	// Invoicing Statuses
	AWAITING_INVOICE = 'AWAITING_INVOICE',
	INVOICED = 'INVOICED',
	PARTIALLY_PAID = 'PARTIALLY_PAID',

	COMPLETED = 'COMPLETED',
}

export const ScheduledAssessmentStatusLabels: { [key in ScheduledAssessmentStatus]: string } = {
	[ScheduledAssessmentStatus.REQUESTED]: 'Requested',
	[ScheduledAssessmentStatus.SCHEDULED]: 'Scheduled',
	[ScheduledAssessmentStatus.DECLINED]: 'Declined',
	[ScheduledAssessmentStatus.CANCELLED]: 'Cancelled',
	[ScheduledAssessmentStatus.AWAITING_INVOICE]: 'Awaiting Invoice',
	[ScheduledAssessmentStatus.INVOICED]: 'Invoiced',
	[ScheduledAssessmentStatus.PARTIALLY_PAID]: 'Partially Paid',
	[ScheduledAssessmentStatus.COMPLETED]: 'Completed',
};
