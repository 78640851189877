import { inject, Injectable } from '@angular/core';
import { FullMetadata, getDownloadURL, getMetadata, listAll, ref, Storage, uploadBytes, UploadMetadata } from '@angular/fire/storage';

@Injectable({
	providedIn: 'root',
})
export class StorageService {
	private storage = inject(Storage);

	/**
	 * Uploads a file to Firebase Storage.
	 * @param path - The storage path where the file should be uploaded.
	 * @param file - The file to be uploaded.
	 * @returns A promise that resolves with the download URL of the uploaded file.
	 */
	async uploadFile(
		path: string,
		file: File | Blob,
		customMetadata?: {
			[key: string]: string;
		},
	): Promise<string> {
		const storageRef = ref(this.storage, path);
		const metadata: UploadMetadata = {
			customMetadata,
			contentType: file.type,
		};
		await uploadBytes(storageRef, file, metadata);
		return await getDownloadURL(storageRef);
	}

	/**
	 * Fetches a file from Firebase Storage.
	 * @param path - The storage path where the file is located.
	 * @returns A promise that resolves with the download URL of the uploaded file.
	 */
	async getFileUrl(path: string): Promise<string> {
		const storageRef = ref(this.storage, path);
		return await getDownloadURL(storageRef);
	}

	/**
	 * Fetches a file's meta data from Firebase Storage.
	 * @param path - The storage path where the file is located.
	 * @returns A promise that resolves with the file's meta data.
	 */
	async getFileMetaData(path: string): Promise<FullMetadata> {
		const storageRef = ref(this.storage, path);
		return await getMetadata(storageRef);
	}

	/**
	 * Fetches a file to Firebase Storage.
	 * @param path - The storage path where the file should be uploaded.
	 * @returns A promise that resolves with an array of file pathes in the folder.
	 */
	async listFilesInFolder(path: string): Promise<string[]> {
		const folderRef = ref(this.storage, path);
		const folderContents = await listAll(folderRef);
		return folderContents.items.map((item) => item.fullPath);
	}
}
