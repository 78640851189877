<app-base-modal [showFooter]="true" title="Select Time">
	<form content [formGroup]="formGroup">
		<h5 class="text-primary">Manually Set Time</h5>
		<div class="time-picker">
			<app-dropdown-control
				[formGroup]="formGroup"
				[options]="timeOptions"
				[searchable]="false"
				label="Start Time"
				name="selectedStartTime"
				placeholder="Select Start Time"
			></app-dropdown-control>
			-
			<app-dropdown-control
				[formGroup]="formGroup"
				[options]="timeOptions"
				[searchable]="false"
				label="End Time"
				name="selectedEndTime"
				placeholder="Select End Time"
			></app-dropdown-control>
		</div>

		<ng-container *ngIf="invalidTime">
			<div class="text-danger">Please ensure the start time is before the end time.</div>
		</ng-container>
	</form>

	<div footer>
		<button role="button" class="btn btn-sm btn-primary" [disabled]="invalidTime" (click)="saveAction()">Save</button>
	</div>
</app-base-modal>
