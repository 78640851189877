import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { PageSubtitleComponent } from '@shared/components/sections/page-subtitle/page-subtitle.component';
import { ModalService } from '@shared/services/modal/modal.service';
import { AddPropertyModalComponent } from '../modals/add-property-modal/add-property-modal.component';
import { take } from 'rxjs';
import { IClientProperty } from '@clients/models/firebase/client.model';
import { EditPropertyModalComponent } from '../modals/edit-property-modal/edit-property-modal.component';
import { ClientsService } from '@clients/services/clients/clients.service';
import { ToastService } from '@shared/services/toast/toast.service';
import { ToastTypes } from '@shared/models/toast';

@Component({
	selector: 'app-desktop-client-edit-property-table',
	standalone: true,
	imports: [CommonModule, PageSubtitleComponent],
	templateUrl: './desktop-client-edit-property-table.component.html',
	styleUrl: './desktop-client-edit-property-table.component.scss',
})
export class DesktopClientEditPropertyTableComponent {
	@Input() properties: Array<IClientProperty>;
	@Input() clientId: string;
	@Output() propertyChange: EventEmitter<void> = new EventEmitter<void>();
	private modalService = inject(ModalService);
	private toastService = inject(ToastService);
	private clientsService = inject(ClientsService);

	openAddProperty(): void {
		const modalRef = this.modalService.open(AddPropertyModalComponent);
		modalRef.componentInstance.propertySelected.pipe(take(1)).subscribe((property: IClientProperty) => {
			this.clientsService.addClientProperty(this.clientId, property).then(() => {
				this.toastService.showToast({
					title: 'Client',
					message: 'Added client property',
					type: ToastTypes.SUCCESS,
				});
			});
			this.propertyChange.emit();
		});
	}

	openEditProperty(clientProperty: IClientProperty, index: number): void {
		const modalRef = this.modalService.open(EditPropertyModalComponent);
		modalRef.componentInstance.clientProperty = clientProperty;
		modalRef.componentInstance.propertySelected.pipe(take(1)).subscribe((property: IClientProperty) => {
			this.clientsService.editClientProperty(this.clientId, property).then(() => {
				this.toastService.showToast({
					title: 'Client',
					message: 'Updated client property',
					type: ToastTypes.SUCCESS,
				});
			});
			this.propertyChange.emit();
		});
		modalRef.componentInstance.propertyRemoved.pipe(take(1)).subscribe((propertyId: string) => {
			this.clientsService.removeClientProperty(this.clientId, propertyId).then(() => {
				this.toastService.showToast({
					title: 'Client',
					message: 'Removed client property',
					type: ToastTypes.SUCCESS,
				});
			});
			this.propertyChange.emit();
		});
	}
}
