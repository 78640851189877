export class LeadRequest {
	id?: string;
}

export enum LeadStatus {
	NEW = 'NEW',
	ACTIVE = 'ACTIVE',
	COMPLETED = 'COMPLETED',
	REJECTED = 'REJECTED',
	CANCELLED = 'CANCELLED',
}

export enum LeadRequestType {
	CALL = 'Call',
	ON_SITE_VISIT = 'On Site Visit',
}

export enum LeadOnSiteVisitDateTimeType {
	REQUEST = 'REQUEST',
	ASAP = 'ASAP',
}

export enum LeadTimeWindow {
	MORNING = 'Morning',
	MIDDAY = 'Midday',
	AFTERNOON = 'Afternoon',
}

export interface MobileNumber {
	countryCode: string;
	number: string;
}
