import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BaseScheduledItemDetail } from '@leads/client-facing/classes/base-scheduled-item-detail.component';
import { ScheduledItemTitleComponent } from '@leads/client-facing/components/scheduled-item-title/scheduled-item-title.component';
import { AssessmentStatusDropdownComponent } from '@leads/shared/components/assessment-status-dropdown/assessment-status-dropdown.component';
import { DropdownControlComponent } from '@shared/components/forms/dropdown-control/dropdown-control.component';
import { TextareaControlComponent } from '@shared/components/forms/textarea-control/textarea-control.component';
import { LoadingStateComponent } from '@shared/components/loading-state/loading-state.component';
import { BaseModalComponent } from '@shared/components/modals/base-modal/base-modal.component';
import { StepNavigatorComponent } from '@shared/components/ui/step-navigator/step-navigator.component';

@Component({
	selector: 'app-mobile-scheduled-item-detail-modal',
	standalone: true,
	imports: [
		CommonModule,
		StepNavigatorComponent,
		LoadingStateComponent,
		TextareaControlComponent,
		FormsModule,
		AssessmentStatusDropdownComponent,
		BaseModalComponent,
		ScheduledItemTitleComponent,
		DropdownControlComponent,
		TextareaControlComponent,
	],
	templateUrl: './mobile-scheduled-item-detail-modal.component.html',
	styleUrl: './mobile-scheduled-item-detail-modal.component.scss',
	host: {
		class: 'h-100',
	},
})
export class MobileScheduledItemDetailModalComponent extends BaseScheduledItemDetail {
	@Input() index: number;
}
