<div class="leads-table-container">
	<div class="top-bar">
		<div class="search-bar">
			<app-search-control [control]="searchControl" placeholder="Search"></app-search-control>
			<button
				[redirectToLeads]="true"
				[target]="LinkTarget.SAME_WINDOW"
				appLeadRequestFormLink
				class="btn btn-sm btn-primary"
				type="button"
			>
				New
				<i class="bi bi-plus"> </i>
			</button>
		</div>

		<app-dropdown-control
			[clearable]="true"
			[formGroup]="form"
			[multiple]="true"
			[options]="leadStatusOptions"
			name="leadStatus"
			placeholder="Filter by status"
		/>

		<app-radio-group-control [formGroup]="form" [options]="leadRequestTypeOptions" name="leadRequestType" />
	</div>

	<div class="content">
		<div class="section table-container">
			<div class="table-headings">
				<div class="column-name">Client Name</div>
				<div class="column-name">Service</div>
				<div class="column-name">Status</div>
			</div>

			<app-loading-state [isLoading]="isLoading" [loadedContent]="loadedContent"></app-loading-state>
			<ng-template #loadedContent>
				<ng-container *ngIf="leads.length === 0; else leadsTable">
					<div class="table-row">
						<div class="inner-row">
							<em class="fw-bold">
								No
								{{ leadStatus.value.join(', ') | titlecase }}
								leads.
							</em>
						</div>
					</div>
				</ng-container>
				<ng-template #leadsTable>
					<ng-container *ngFor="let lead of leads">
						<div class="table-row" (click)="onLeadItemClick(lead)">
							<div class="inner-row">{{ lead.personalDetails.name | nullDefault: 'N/A' }}</div>
							<div class="inner-row">
								{{ lead?.onSiteVisitAssessment?.serviceType | nullDefault: '-' }}
							</div>
							<div class="inner-row">
								<app-lead-status-badge [status]="lead.status"></app-lead-status-badge>
							</div>
						</div>
					</ng-container>
				</ng-template>
			</ng-template>
		</div>
	</div>
</div>
