import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { LeadContactInformationComponent } from '@leads/customer-facing/components/forms/lead-contact-information/lead-contact-information.component';
import { LeadAddressInformationComponent } from '@leads/customer-facing/components/forms/lead-address-information/lead-address-information.component';
import { LeadRequestFormService } from '@leads/customer-facing/services/lead-request-form/lead-request-form.service';
import { ILeadRequestForm } from '@leads/customer-facing/models/domain/lead-request.domain';
import { CustomerLeadRequestRoutes } from '@leads/leads.routes';
import { IOrganisation } from '@organisations/models/organisations';
import { OrganisationService } from '@organisations/services/organisation/organisation.service';
import { LoadingStateComponent } from '@shared/components/loading-state/loading-state.component';
import { LeadRequestType } from '@leads/shared/models/domain/lead-request.domain';

@Component({
	selector: 'app-lead-request-personal-details',
	standalone: true,
	imports: [CommonModule, RouterLink, LeadContactInformationComponent, LeadAddressInformationComponent, LoadingStateComponent],
	templateUrl: './lead-request-personal-details.component.html',
	styleUrl: './lead-request-personal-details.component.scss',
})
export class LeadRequestPersonalDetailsComponent implements OnInit, OnDestroy {
	private leadRequestFormService = inject(LeadRequestFormService);
	private organisationService = inject(OrganisationService);
	private router = inject(Router);
	private route = inject(ActivatedRoute);

	destroyed$ = new EventEmitter<void>();
	isLoading = true;

	organisationId: string;
	organisation: IOrganisation;
	leadRequestForm: FormGroup<ILeadRequestForm>;

	CustomerLeadRequestRoutes = CustomerLeadRequestRoutes;
	LeadRequestType = LeadRequestType;

	ngOnInit(): void {
		this.leadRequestForm = this.leadRequestFormService.getForm();
		this.validatePreviousStep();

		this.organisationId = this.route.parent?.parent?.snapshot.params.organisationId;

		this.organisationService.getDocSnapshot(this.organisationId).then((doc) => {
			if (doc.exists()) {
				this.organisation = doc.data();
			}
			this.isLoading = false;
		});
	}

	ngOnDestroy(): void {
		this.destroyed$.emit();
	}

	nextDisabled(): boolean {
		let disabled = this.leadRequestForm.controls.contactDetails.invalid;
		if (this.leadRequestForm.controls.requestType.value === LeadRequestType.ON_SITE_VISIT) {
			disabled = disabled || this.leadRequestForm.controls.installationAddress.invalid;
		}
		return disabled;
	}

	validatePreviousStep(): void {
		if (this.leadRequestForm.controls.requestType.invalid) {
			this.router.navigate(['../' + CustomerLeadRequestRoutes.NEW], { relativeTo: this.route });
		}
	}
}
