<div class="clients-table-container">
	<div class="top-bar">
		<app-search-control [control]="searchControl" placeholder="Search"></app-search-control>
		<button type="button" class="btn btn-sm btn-primary" [routerLink]="ClientsRoutes.CREATE">
			<i class="bi bi-plus"> </i>
			Add new
		</button>
	</div>

	<div class="content">
		<div class="table-container">
			<div class="table-title">
				<div class="fw-bold">Clients</div>
				<div class="fw-bold">Clients: {{ clients.length }}</div>
			</div>

			<div class="table-heading">
				<div>Client Name</div>
				<div>Client Properties</div>
			</div>

			<app-loading-state [isLoading]="isLoading" [loadedContent]="loadedContent"></app-loading-state>
			<ng-template #loadedContent>
				<ng-container *ngIf="clients.length === 0; else clientsTable">
					<div class="table-row">
						<div class="column">
							<em class="fw-bold">No clients found.</em>
						</div>
					</div>
				</ng-container>
				<ng-template #clientsTable>
					<ng-container *ngFor="let client of clients">
						<div class="table-row cursor-p" [routerLink]="viewClientRoute(client)">
							<div class="column">
								{{ client.clientName }}
							</div>
							<div class="column">
								<ng-container *ngIf="client.properties.length > 0; else noAddress">
									<ng-container *ngFor="let property of client.properties">
										{{ property.fullAddress }}, {{ property.suburb }}
										<br />
									</ng-container>
								</ng-container>
								<ng-template #noAddress> - </ng-template>
							</div>
						</div>
					</ng-container>
				</ng-template>
			</ng-template>
		</div>
	</div>
</div>
