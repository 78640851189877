import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ScheduledAssessmentStatus } from '@leads/shared/models/domain/scheduled-assessment.domain';
import { NgSelectModule } from '@ng-select/ng-select';
import { IDropdownOption } from '@shared/components/forms/dropdown-control/dropdown-control.component';

@Component({
	selector: 'app-assessment-status-dropdown',
	standalone: true,
	imports: [NgSelectModule, FormsModule, NgIf],
	templateUrl: './assessment-status-dropdown.component.html',
	styleUrl: './assessment-status-dropdown.component.scss',
})
export class AssessmentStatusDropdownComponent {
	@Input() statusOptions: Array<IDropdownOption> = [];
	@Input() status: ScheduledAssessmentStatus;
	@Input() readonlyView = false;
	@Output() statusChange = new EventEmitter<ScheduledAssessmentStatus>();
}
