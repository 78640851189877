import { takeUntil } from 'rxjs';

import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { LeadRequestTypeComponent } from '@leads/customer-facing/components/forms/lead-request-type/lead-request-type.component';
import { ILeadRequestForm } from '@leads/customer-facing/models/domain/lead-request.domain';
import { LeadRequestFormService } from '@leads/customer-facing/services/lead-request-form/lead-request-form.service';
import { CustomerLeadRequestRoutes } from '@leads/leads.routes';
import { LeadOnSiteVisitDateTimeType, LeadRequestType } from '@leads/shared/models/domain/lead-request.domain';

@Component({
	selector: 'app-lead-request-request-type',
	standalone: true,
	imports: [CommonModule, RouterLink, LeadRequestTypeComponent],
	templateUrl: './lead-request-request-type.component.html',
	styleUrl: './lead-request-request-type.component.scss',
})
export class LeadRequestRequestTypeComponent implements OnInit, OnDestroy {
	private leadRequestFormService = inject(LeadRequestFormService);
	private router = inject(Router);
	private route = inject(ActivatedRoute);

	destroyed$ = new EventEmitter<void>();
	leadRequestForm: FormGroup<ILeadRequestForm>;
	CustomerLeadRequestRoutes = CustomerLeadRequestRoutes;

	ngOnInit(): void {
		this.leadRequestForm = this.leadRequestFormService.getForm();
		this.validatePreviousStep();
		this.validateForm();
	}

	ngOnDestroy(): void {
		this.destroyed$.emit();
	}

	get requestType(): FormControl<LeadRequestType | null> {
		return this.leadRequestForm.controls.requestType;
	}

	validatePreviousStep(): void {
		if (this.leadRequestForm.controls.mobileNumber.invalid) {
			this.router.navigate(['../' + CustomerLeadRequestRoutes.NEW], { relativeTo: this.route });
		}
	}

	validateForm(): void {
		this.requestType.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((requestType) => {
			if (requestType === LeadRequestType.ON_SITE_VISIT) {
				this.leadRequestForm.controls.callAssessment.disable();
				this.leadRequestForm.controls.installationAddress.enable();
				this.leadRequestForm.controls.onsiteAssessment.enable();

				this.leadRequestForm.controls.callAssessment.reset();

				this.leadRequestForm.controls.onsiteAssessment.controls.dateType.patchValue(LeadOnSiteVisitDateTimeType.REQUEST);
			}

			if (requestType === LeadRequestType.CALL) {
				this.leadRequestForm.controls.callAssessment.enable();
				this.leadRequestForm.controls.installationAddress.disable();
				this.leadRequestForm.controls.onsiteAssessment.disable();

				this.leadRequestForm.controls.installationAddress.reset();
				this.leadRequestForm.controls.onsiteAssessment.reset();
			}
		});
	}
}
