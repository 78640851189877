import { filter, takeUntil } from 'rxjs';

import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, OnDestroy, OnInit } from '@angular/core';
import { DocumentReference, getDoc } from '@angular/fire/firestore';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LayoutService } from '@layout/services/layout/layout.service';
import { DesktopOrganisationDetailsComponent } from '@organisations/components/forms/desktop-organisation-details/desktop-organisation-details.component';
import { MobileOrganisationDetailsComponent } from '@organisations/components/forms/mobile-organisation-details/mobile-organisation-details.component';
import { OrganisationUserAccessLevel } from '@organisations/models/organisation-user';
import { IOrganisation, IOrganisationForm, OrganisationIndustry } from '@organisations/models/organisations';
import { OrganisationSelectedService } from '@organisations/services/organisation-selected/organisation-selected.service';
import { OrganisationService } from '@organisations/services/organisation/organisation.service';
import { LoadingStateComponent } from '@shared/components/loading-state/loading-state.component';
import { UnauthorisedBannerComponent } from '@shared/components/ui/unauthorised-banner/unauthorised-banner.component';
import { ToastTypes } from '@shared/models/toast';
import { ToastService } from '@shared/services/toast/toast.service';

@Component({
	selector: 'app-organisation-details',
	standalone: true,
	imports: [
		CommonModule,
		DesktopOrganisationDetailsComponent,
		MobileOrganisationDetailsComponent,
		UnauthorisedBannerComponent,
		LoadingStateComponent,
	],
	templateUrl: './organisation-details.component.html',
	styleUrl: './organisation-details.component.scss',
})
export class OrganisationDetailsComponent implements OnInit, OnDestroy {
	organisationForm: FormGroup<IOrganisationForm>;
	destroyed$ = new EventEmitter<void>();
	isMobileSize = true;
	organisationId: string;

	userHasAccess = false;
	isLoading = true;
	isSaving = false;

	private layoutService = inject(LayoutService);
	private organisationService = inject(OrganisationService);
	private organisationSelectedService = inject(OrganisationSelectedService);
	private toastService = inject(ToastService);

	constructor() {
		this.organisationForm = new FormGroup<IOrganisationForm>({
			companyName: new FormControl(null, [Validators.required, Validators.minLength(2), Validators.maxLength(50)]),
			companyContactEmail: new FormControl(null, [Validators.required, Validators.email, Validators.maxLength(50)]),
			companyContactName: new FormControl(null, [Validators.required, Validators.minLength(2), Validators.maxLength(50)]),
			companyContactNumber: new FormControl(null, [
				Validators.required,
				Validators.minLength(6),
				Validators.maxLength(21),
				Validators.pattern(/^[0-9]{10,20}$/),
			]),
			companyRegistrationNumber: new FormControl(null, [Validators.pattern(/^[0-9]{4}\/[0-9]{6}\/[0-9]{2}$/)]),
			companySize: new FormControl(null, [Validators.required, Validators.min(1)]),
			industry: new FormControl(OrganisationIndustry.PLUMBING, [Validators.required]),
		});
	}

	ngOnInit(): void {
		this.layoutService.isMobileSize$.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
			this.isMobileSize = value;
		});

		this.subscribeToSelectedOrganisation();
	}

	ngOnDestroy(): void {
		this.destroyed$.emit();
	}

	subscribeToSelectedOrganisation(): void {
		this.organisationSelectedService.selectedOrganisation
			.pipe(
				takeUntil(this.destroyed$),
				filter((o) => o !== null),
			)
			.subscribe((selectedOrg) => {
				if (selectedOrg?.accessLevel === OrganisationUserAccessLevel.OWNER) {
					this.userHasAccess = true;
					this.fetchOrganisationDetails(selectedOrg.organisation);
				} else {
					this.userHasAccess = false;
					this.isLoading = false;
				}
			});
	}

	fetchOrganisationDetails(orgRef: DocumentReference): void {
		getDoc(orgRef)
			.then((doc) => {
				if (doc.exists()) {
					this.organisationId = doc.id;
					this.organisationForm.patchValue(doc.data());
				}
				this.isLoading = false;
			})
			.catch((error) => {
				this.isLoading = false;
				console.error('Failed to load organisation');
			});
	}

	handleSave(): void {
		if (this.organisationForm.invalid) {
			return;
		}

		this.isSaving = true;

		this.organisationService
			.update(this.organisationId, this.organisationForm.value as Partial<IOrganisation>)
			.then(() => {
				this.toastService.showToast({
					title: 'Organisation',
					message: 'Successfully updated organisation',
					type: ToastTypes.SUCCESS,
				});
				this.isSaving = false;
				this.organisationForm.markAsPristine();
			})
			.catch((error) => {
				this.toastService.showToast({
					title: 'Organisation',
					message: `Failed to update organisation: ${error.message}`,
					type: ToastTypes.ERROR,
				});
				this.isSaving = false;
			});
	}
}
