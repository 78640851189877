import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerLeadRequestRoutes } from '@leads/leads.routes';

@Component({
	selector: 'app-lead-request-success',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './lead-request-success.component.html',
	styleUrl: './lead-request-success.component.scss',
})
export class LeadRequestSuccessComponent implements OnInit {
	private router = inject(Router);
	private route = inject(ActivatedRoute);

	leadRequestId: string;
	CustomerLeadRequestRoutes = CustomerLeadRequestRoutes;

	ngOnInit(): void {
		this.leadRequestId = this.route.snapshot.params.leadRequestId;
	}

	viewAssessment(): void {
		this.router.navigate([`../../${CustomerLeadRequestRoutes.VIEW_REQUEST.replace(':leadRequestId', this.leadRequestId)}`], {
			relativeTo: this.route,
		});
	}
}
