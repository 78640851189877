import { AppRoutes } from 'src/app/app.routes';

import { inject, NgZone } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { CanActivateFn, Router } from '@angular/router';

export const unauthenticatedGuard: CanActivateFn = (route, state) => {
	const auth = inject(Auth);
	const router = inject(Router);
	const ngZone = inject(NgZone);

	return new Promise((resolve) => {
		auth.onAuthStateChanged((user) => {
			if (user) {
				ngZone.run(() => {
					router.navigate([AppRoutes.DASHBOARD]);
				});
				resolve(false);
			} else {
				resolve(true);
			}
		});
	});
};
