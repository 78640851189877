import { BehaviorSubject } from 'rxjs';

import { Injectable } from '@angular/core';
import { IAvailableOrganisation } from '@organisations/models/organisation-user';

@Injectable({
	providedIn: 'root',
})
export class OrganisationSelectedService {
	selectedOrganisation: BehaviorSubject<IAvailableOrganisation | null>;
	constructor() {
		this.selectedOrganisation = new BehaviorSubject<IAvailableOrganisation | null>(null);
	}

	setSelectedOrganisation(availableOrganisation: IAvailableOrganisation | null): void {
		this.selectedOrganisation.next(availableOrganisation);
		localStorage.setItem('selectedOrganisationId', availableOrganisation?.organisation.id as string);
	}

	getSelectedOrganisationId(): string | null {
		return localStorage.getItem('selectedOrganisationId');
	}

	clearSelectedOrganisation(): void {
		this.selectedOrganisation.next(null);
	}
}
