import { filter, takeUntil } from 'rxjs';
import { AppRoutes } from 'src/app/app.routes';

import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, OnDestroy, OnInit } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { INavigationItem } from '@layout/models/navigation';
import { OrganisationUserAccessLevel } from '@organisations/models/organisation-user';
import { OrganisationSelectedService } from '@organisations/services/organisation-selected/organisation-selected.service';

@Component({
	selector: 'app-mobile-footer',
	standalone: true,
	imports: [CommonModule, RouterLink, RouterLinkActive],
	templateUrl: './mobile-footer.component.html',
	styleUrl: './mobile-footer.component.scss',
})
export class MobileFooterComponent implements OnInit, OnDestroy {
	destroyed$ = new EventEmitter<void>();
	navItems: Array<INavigationItem> = [];

	private organisationSelectedService = inject(OrganisationSelectedService);

	private scheduleEnabled = true;
	private leadsEnabled = true;
	private clientsEnabled = true;

	ngOnInit(): void {
		this.organisationSelectedService.selectedOrganisation
			.pipe(
				takeUntil(this.destroyed$),
				filter((o) => o !== null),
			)
			.subscribe((organisationUser) => {
				this.scheduleEnabled = !!organisationUser;
				this.leadsEnabled = organisationUser?.accessLevel === OrganisationUserAccessLevel.OWNER;
				this.clientsEnabled = organisationUser?.accessLevel === OrganisationUserAccessLevel.OWNER;
				this.setNavigationItems();
			});
	}

	ngOnDestroy(): void {
		this.destroyed$.emit();
	}

	setNavigationItems(): void {
		this.navItems = [
			{
				icon: 'bi-house',
				label: 'Dashboard',
				route: [AppRoutes.DASHBOARD],
				disabled: false,
				visible: true,
			},
			{
				icon: 'bi-bucket',
				label: 'Leads',
				route: [AppRoutes.CLIENT_LEADS_DASHBOARD],
				disabled: !this.leadsEnabled,
				visible: this.leadsEnabled,
			},
			{
				icon: 'bi-calendar2-plus',
				label: 'Schedule',
				route: [AppRoutes.SCHEDULE],
				disabled: !this.scheduleEnabled,
				visible: this.scheduleEnabled,
			},
			{
				icon: 'bi-people',
				label: 'Clients',
				route: [AppRoutes.CLIENTS],
				disabled: !this.clientsEnabled,
				visible: this.clientsEnabled,
			},
		];
	}
}
