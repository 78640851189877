import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { IClientProperty } from '@clients/models/firebase/client.model';
import { EllipsisPipe } from '@shared/pipes/ellipsis.pipe';

@Component({
	selector: 'app-link-location',
	standalone: true,
	imports: [CommonModule, EllipsisPipe],
	templateUrl: './link-location.component.html',
	styleUrl: './link-location.component.scss',
})
export class LinkLocationComponent {
	@Input() property: IClientProperty;
	@Input() maxCharacters: number = 100;

	get query(): string {
		return `https://www.google.com/maps/search/?api=1&query=${this.addressParts}`;
	}

	get addressParts(): string {
		const fullAddress = this.property?.fullAddress || '';
		const suburb = this.property?.suburb || '';
		const city = this.property?.city || '';
		const country = this.property?.country || '';

		const addressParts = [fullAddress, suburb, city, country].filter((part) => part);

		return addressParts.join(',');
	}
}
