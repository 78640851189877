<app-step-navigator #stepNav [stepConfig]="navigationConfig" [(selectedStep)]="selectedStep"></app-step-navigator>

<div class="assessment-content">
	<ng-container [ngSwitch]="selectedStep.switchCase">
		<ng-container *ngSwitchCase="NavigationStepCases.ASSIGN_EMPLOYEE">
			<div class="employee-date-time">
				<div class="form-column">
					<div class="text-primary">Employee Name</div>
					<app-dropdown-control
						[formGroup]="schedulerForm"
						name="employeeId"
						placeholder="Select an employee"
						label="Employee"
						[options]="employees"
						[searchable]="false"
					></app-dropdown-control>
				</div>
				<div class="form-column">
					<div class="form-row">
						<div class="text-primary">Select a Date & Time</div>
					</div>
					<div class="form-column">
						<div class="date-time-container">
							<div class="date-column" style="min-width: 250px">
								<app-date-picker-fullscreen
									[form]="schedulerForm"
									name="selectedDate"
									[disabledDaysOfTheWeek]="disabledDaysOfTheWeek"
									(selectedDateChanged)="datePickerDateChanged($event)"
								></app-date-picker-fullscreen>
							</div>
							<div class="date-column w-100">
								<app-calendar-day-view
									[events]="timePickerEvents"
									[viewDate]="timePickerSelectedDate"
									[refresh]="refreshTimePicker"
									[hidePopup]="true"
									(eventTimesChanged)="timePickerEventTimesChanged($event)"
									(hourSegmentClicked)="timePickerHourClicked($event)"
								></app-calendar-day-view>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="pagination-controls justify-content-end">
				<button
					class="btn btn-primary"
					(click)="nextTab()"
					[disabled]="employeeId.invalid || selectedDate.invalid || selectedStartTime.invalid || selectedEndTime.invalid"
				>
					Next
					<i class="bi bi-arrow-right"></i>
				</button>
			</div>
		</ng-container>

		<ng-container *ngSwitchCase="NavigationStepCases.ASSIGNMENT_DETAILS">
			<div class="form-row">
				<ng-container *ngIf="requestType.value === LeadRequestType.ON_SITE_VISIT">
					<div class="form-column">
						<div class="text-primary">Assessment Type</div>
						<div class="form-column assessment-details">
							<div class="form-row">
								<app-dropdown-control
									class="w-100"
									[formGroup]="schedulerForm"
									name="assessments"
									placeholder="Select a service"
									label="Service Type"
									[options]="serviceTypes"
									[multiple]="true"
									[searchable]="false"
									[clearable]="true"
								></app-dropdown-control>
							</div>
							<div class="form-row">
								<app-dropdown-control
									class="w-100"
									[formGroup]="schedulerForm"
									name="assessmentType"
									label="Assessment Type"
									placeholder="Select an assessment type"
									[options]="assessmentTypes"
								></app-dropdown-control>
							</div>
							<ng-container *ngIf="assessmentType?.value === ScheduledAssessmentType.ASSESSMENT">
								<div class="form-row">
									<app-standard-group-control
										class="w-100"
										type="number"
										name="callOutFeeRands"
										[formGroup]="schedulerForm"
										label="Call-Out Fee"
										[showLabel]="false"
										placeholder="Call-Out Fee"
										leftText="R"
									></app-standard-group-control>
								</div>
								<div class="form-row">
									<app-switch-control
										name="includeCallOutFeeComms"
										[formGroup]="schedulerForm"
										label="Include call-out fee in communication to client"
									></app-switch-control>
								</div>
							</ng-container>
						</div>
					</div>
				</ng-container>
				<div class="form-column">
					<div class="text-primary">Internal Assessment Notes</div>
					<app-textarea-control
						class="h-100"
						[resizable]="false"
						[height100]="true"
						name="assessmentNotes"
						[formGroup]="schedulerForm"
						label="Assessment Notes"
						placeholder="Assessment Notes"
					></app-textarea-control>
				</div>
			</div>

			<div class="pagination-controls">
				<button class="btn btn-outline-primary" (click)="previousTab()">
					<i class="bi bi-arrow-left btn-sm"></i>
					Back
				</button>
				<button
					class="btn btn-primary"
					(click)="submitAssessment()"
					[disabled]="schedulerForm.invalid || this.assessmentSubmitted || submittingAssessment"
				>
					<ng-container *ngIf="submittingAssessment; else notSubmitting">
						<div class="spinner-border spinner-border-sm" role="status">
							<span class="visually-hidden">Loading...</span>
						</div>
					</ng-container>
					<ng-template #notSubmitting> Create Assessment <i class="bi bi-arrow-right"></i> </ng-template>
				</button>
			</div>
		</ng-container>

		<ng-container *ngSwitchCase="NavigationStepCases.COMMUNICATIONS">
			<div class="form-column">
				<div class="text-primary">WhatsApp Notes</div>
				<app-textarea-control
					class="h-100"
					[resizable]="false"
					[height100]="true"
					name="whatsappNotes"
					[formGroup]="schedulerForm"
					label="WhatsApp Notes"
					placeholder="WhatsApp Notes"
				></app-textarea-control>
			</div>

			<div class="pagination-controls">
				<button class="btn btn-outline-primary" (click)="previousTab()">
					<i class="bi bi-arrow-left btn-sm"></i>
					Back
				</button>
				<button class="btn btn-success btn-whatsapp" (click)="shareToWhatsApp()" [disabled]="whatsappNotes.invalid">
					<i class="bi bi-whatsapp"></i>
					Share to WhatsApp
				</button>
			</div>
		</ng-container>
	</ng-container>

	<!-- <pre>{{ schedulerForm.value | json }}</pre> -->
</div>
