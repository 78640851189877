<div class="calendar-controls">
	<div class="calendar-controls-container left">
		<!-- <div class="create-btn btn btn-sm btn-primary">Create <span class="bi bi-calendar-plus"></span></div> -->
		<div
			class="today-btn btn btn-sm btn-outline-secondary"
			mwlCalendarToday
			[(viewDate)]="viewDate"
			(viewDateChange)="viewDateChange.emit($event)"
		>
			Today
		</div>
		<div class="btn-group">
			<div
				class="btn"
				mwlCalendarPreviousView
				[view]="calendarView"
				[(viewDate)]="viewDate"
				(viewDateChange)="viewDateChange.emit($event)"
			>
				<span class="bi bi-chevron-left"></span>
			</div>

			<div
				class="btn"
				mwlCalendarNextView
				[view]="calendarView"
				[(viewDate)]="viewDate"
				(viewDateChange)="viewDateChange.emit($event)"
			>
				<span class="bi bi-chevron-right"></span>
			</div>
		</div>
		<h6 class="header">
			{{ viewDate | calendarDate: headerCalendarDateMethod : 'en-ZA' : calendarSettings.weekStartsOn }}
		</h6>
	</div>
	<div class="calendar-controls-container right">
		<ng-container *ngIf="showEmployeeFilter">
			<div class="employee-filter">
				<ng-select
					[items]="employees"
					(change)="selectedEmployee.emit($event)"
					class="w-100"
					bindLabel="displayName"
					bindValue="userId"
					placeholder="Search employee"
					[searchable]="false"
					[clearable]="true"
				>
				</ng-select>
			</div>
		</ng-container>
		<div class="dropdown">
			<button
				aria-expanded="false"
				class="btn btn-sm btn-primary dropdown-toggle"
				data-bs-toggle="dropdown"
				id="dropdownMenuButton"
				type="button"
			>
				{{ calendarView | titlecase }}
			</button>
			<ul aria-labelledby="dropdownMenuButton" class="dropdown-menu">
				<li>
					<button (click)="calendarViewChange.emit(CalendarView.Month)" class="dropdown-item" type="button">Month</button>
				</li>
				<li>
					<button (click)="calendarViewChange.emit(CalendarView.Week)" class="dropdown-item" type="button">Week</button>
				</li>
				<li>
					<button (click)="calendarViewChange.emit(CalendarView.Day)" class="dropdown-item" type="button">Day</button>
				</li>
			</ul>
		</div>
	</div>
</div>
