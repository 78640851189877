import 'zone.js';

import { CalendarEventTimesChangedEvent } from 'angular-calendar';

import { NgSwitch, NgSwitchCase } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseCalendarView } from '@schedule/lib/classes/base-calendar-view';
import { CalendarControlsComponent } from '@schedule/lib/components/calendar-controls/calendar-controls.component';
import { CalendarDayViewComponent } from '@schedule/lib/components/calendar-day-view/calendar-day-view.component';
import { CalendarMonthViewComponent } from '@schedule/lib/components/calendar-month-view/calendar-month-view.component';
import { CalendarWeekViewComponent } from '@schedule/lib/components/calendar-week-view/calendar-week-view.component';
import { ISearchableEmployee } from '@schedule/lib/models/employee';
import { LoadingStateComponent } from '@shared/components/loading-state/loading-state.component';

@Component({
	selector: 'app-calendar-owner-view',
	standalone: true,
	imports: [
		LoadingStateComponent,
		CalendarMonthViewComponent,
		CalendarWeekViewComponent,
		CalendarDayViewComponent,
		CalendarControlsComponent,
		NgSwitchCase,
		NgSwitch,
	],
	templateUrl: './calendar-owner-view.component.html',
	styleUrl: './calendar-owner-view.component.scss',
})
export class CalendarOwnerViewComponent extends BaseCalendarView {
	@Input() selectedEmployeeId: string | null;
	@Output() selectedEmployeeIdChange = new EventEmitter<string | null>();

	@Input() employees: Array<ISearchableEmployee> = [];

	@Output() eventTimesChanged = new EventEmitter<CalendarEventTimesChangedEvent>();

	onEmployeeSelected($event: ISearchableEmployee) {
		if ($event) {
			this.selectedEmployeeIdChange.emit($event.userId);
		} else {
			this.selectedEmployeeIdChange.emit(null);
		}
	}
}
