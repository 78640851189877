import { takeUntil } from 'rxjs';

import { Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IClient } from '@clients/models/firebase/client.model';
import { AssessmentScheduleViewSteps, leadAssessmentStatusOptions } from '@leads/client-facing/models/domain/scheduled-assessment.domain';
import { LeadRequestType } from '@leads/shared/models/domain/lead-request.domain';
import { ScheduledAssessment, ScheduledAssessmentStatus } from '@leads/shared/models/domain/scheduled-assessment.domain';
import { IDropdownOption } from '@shared/components/forms/dropdown-control/dropdown-control.component';
import { IStepNavigation, IStepNavigatorConfig, NavButtonStyle, StepModes, StepUI } from '@shared/models/step-navigator';
import { ModalService } from '@shared/services/modal/modal.service';

import { JobCardModalComponent } from '../components/job-card-modal/job-card-modal.component';

export type ScheduledAssessmentStatusChange = {
	assessmentId: string;
	status: ScheduledAssessmentStatus;
};

export type ScheduledAssessmentEmployeeIdChange = {
	assessmentId: string;
	employeeId: string;
};

export type ScheduledAssessmentNotesChange = {
	assessmentId: string;
	assessmentNotes: string;
};

@Component({
	template: '',
})
export abstract class BaseScheduledItemDetail implements OnInit, OnDestroy {
	@Input() scheduledItem: ScheduledAssessment;
	@Input() employees: Array<IDropdownOption> = [];
	@Input() client: IClient;
	@Input() readonlyView = false;
	@Input() isSavingNotes = false;

	@Output() statusChanged = new EventEmitter<ScheduledAssessmentStatusChange>();
	@Output() employeeIdChanged = new EventEmitter<ScheduledAssessmentEmployeeIdChange>();
	@Output() assessmentNotesChanged = new EventEmitter<ScheduledAssessmentNotesChange>();

	protected leadAssessmentStatus: ScheduledAssessmentStatus;
	protected readonly leadAssessmentStatusOptions = leadAssessmentStatusOptions;

	assessmentForm = new FormGroup({
		whatsappNotes: new FormControl<string | null | undefined>(''),
		employeeId: new FormControl<string | null>(null, [Validators.required]),
		assessmentNotes: new FormControl<string | null | undefined>(''),
	});

	NavigationStepCases = AssessmentScheduleViewSteps;
	LeadRequestType = LeadRequestType;
	navigationConfig: IStepNavigatorConfig;
	selectedStep: IStepNavigation;

	isLoading = true;
	destroyed$ = new EventEmitter<void>();

	private modalService = inject(ModalService);

	ngOnInit(): void {
		this.navigationConfig = {
			ui: StepUI.WIZARD,
			mode: StepModes.NG_SWITCH,
			navButtonStyle: NavButtonStyle.CHEVRON,
			showStepChevrons: true,
			showStepNumbers: false,
			showNavButtons: true,
			steps: [
				{
					title: {
						desktop: 'Assessment Overview',
						mobile: 'Assessment Overview',
					},
					icon: 'bi-clipboard',
					switchCase: AssessmentScheduleViewSteps.ASSESSMENT_OVERVIEW,
				},
				{
					title: {
						desktop: 'Communications',
						mobile: 'Commuications',
					},
					icon: 'bi-telephone',
					switchCase: AssessmentScheduleViewSteps.COMMUNICATIONS,
				},
			],
		};

		this.selectedStep = this.navigationConfig.steps[0];

		this.leadAssessmentStatus = this.scheduledItem.status;

		this.assessmentForm.patchValue({
			whatsappNotes: this.scheduledItem.whatsappNotes,
			employeeId: this.scheduledItem.assignedEmployee.userId,
			assessmentNotes: this.scheduledItem.assessmentNotes,
		});

		this.handleReadonlyView();
		this.onEmployeeChange();

		this.isLoading = false;
	}

	ngOnDestroy(): void {
		this.destroyed$.emit();
	}

	private handleReadonlyView() {
		if (this.readonlyView) {
			this.assessmentForm.disable();
		} else {
			this.assessmentForm.enable();
		}
	}

	statusChange(status: ScheduledAssessmentStatus): void {
		this.scheduledItem.status = status;
		this.statusChanged.emit({ assessmentId: this.scheduledItem.id!, status });
	}

	shareToWhatsApp(): void {
		const text = this.assessmentForm.controls.whatsappNotes.value;
		window.open(`https://wa.me/${this.client.clientNumber.countryCode}${this.client.clientNumber.number}?text=${text}`, '_blank');
	}

	onEmployeeChange(): void {
		this.assessmentForm.controls.employeeId.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
			this.employeeIdChanged.emit({ assessmentId: this.scheduledItem.id!, employeeId: value! });
		});
	}

	onAssessmentNotesChange(): void {
		this.assessmentNotesChanged.emit({
			assessmentId: this.scheduledItem.id!,
			assessmentNotes: this.assessmentForm.controls.assessmentNotes.value!,
		});
	}

	openJobCard(): void {
		const modalRef = this.modalService.open(JobCardModalComponent, false, 'lg');
		console.log(this.scheduledItem);
		modalRef.componentInstance.organisationId = this.scheduledItem.organisationId;
		modalRef.componentInstance.leadRequestId = this.scheduledItem.leadRequestId;
		modalRef.componentInstance.leadAssessmentId = this.scheduledItem.id;
		modalRef.componentInstance.customerName = this.scheduledItem.customerName;
	}
}
