import { IClientProperty } from '@clients/models/firebase/client.model';
import { LeadRequestType } from '@leads/shared/models/domain/lead-request.domain';
import { ScheduledAssessmentStatus } from '@leads/shared/models/domain/scheduled-assessment.domain';

/**
 * Represents the metadata of the CalendarEvent.
 * Combines the IScheduledAssessmentWithLeadRequest & IOrganisationUser fields into a calendar event.
 */
export class ScheduledEvent {
	// Scheduled Assessment fields
	scheduledAssessmentId: string;
	serviceTypes: string[];
	scheduledAssessmentStatus: ScheduledAssessmentStatus;
	scheduledAssessmentNotes: string;

	// Lead Request fields
	leadRequestId: string;
	leadRequestType: LeadRequestType;
	customerName: string;
	customerNumber: string;
	customerProperty: IClientProperty;

	// Organisation User fields
	employeeUserId: string;
	employeeName: string;

	// Misc
	description: string;
	actions: Array<ScheduleAction>;
	popoverChanges?: Array<PopoverChangeEvent>;
}

export enum PopoverChange {
	SCHEDULED_ASSESSMENT_STATUS,
}

export interface PopoverChangeEvent {
	action: PopoverChange;
	onChange(value: any, event: ScheduledEvent): void;
}

export enum ScheduleAction {
	CREATE = 'Create', // TODO: Implement after BETA
	VIEW = 'View',
	DATE_CHANGED = 'Date Changed', // TODO: Implement after BETA
	CANCEL_JOB = 'Cancel Job',
	CANCEL_CALL = 'Cancel Call',
	CANCEL_VISIT = 'Cancel Visit',
	NOTES = 'Notes',
	JOB_CARD = 'Job Card',
}
