<div class="modal-body text-primary">
	<div class="text-primary text-container">
		<app-loading-state [isLoading]="isLoading" [loadedContent]="loadedContent" class="h-100"></app-loading-state>
		<ng-template #loadedContent>
			<ng-container *ngIf="customerSignatureUrl; else mustSign">
				<div class="signature-notes-body">
					<div>
						<h5>Customer Signed Notes</h5>
						<textarea class="form-control" readonly disabled>{{ customerSignedNotes }}</textarea>
					</div>
					<div>
						<h5>Customer Signature</h5>
						<img *ngIf="!isImgLoaded" height="200" class="img-fluid" src="assets/placeholders/placeholder-animated.gif" />
						<img
							[hidden]="!isImgLoaded"
							height="200"
							class="img-fluid"
							[src]="customerSignatureUrl"
							(load)="isImgLoaded = true"
						/>
					</div>
				</div>
			</ng-container>
			<ng-template #mustSign>
				<app-alert [alertType]="AlertTypes.WARNING" [content]="alertContent" [marginBottom]="8"></app-alert>
				<ng-template #alertContent>
					Once you click save, your signature is final and cannot be changed. Any changes to the assessment notes won't update the
					saved job card.
				</ng-template>
				<div class="signature-notes-body">
					<div>
						<h5>Assessment Notes</h5>
						<textarea class="form-control" readonly disabled>{{ assessmentNotes }}</textarea>
					</div>
					<div>
						<h5>Customer Signature</h5>
						<p>Sign Here</p>
						<div class="signature-container">
							<canvas #signatureCanvas></canvas>
						</div>
					</div>
				</div>
			</ng-template>
		</ng-template>
	</div>
	<div class="button-container mt-2">
		<button type="button" class="btn btn-sm btn-primary" (click)="closeModal()"><i class="bi bi-arrow-left"></i> Back</button>
		<ng-container *ngIf="!customerSignatureUrl && !isLoading">
			<button type="button" class="btn btn-sm btn-outline-secondary" (click)="clearSignature()">Clear</button>
			<button
				type="button"
				class="btn btn-sm btn-outline-primary"
				[disabled]="signaturePad && signaturePad.isEmpty()"
				(click)="saveSignature()"
			>
				Save
			</button>
		</ng-container>
	</div>
</div>
