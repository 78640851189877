/*
Folder Structure
.
└── Root/
    ├── user/
    │   └── {userId}/
    │       └── profile-picture/
    │           └── profile.jpg
    └── organisation/
        └── {organisationId}/
            └── lead-request/
                └── {leadRequestId}/
                    └── assessment/
                        └── {assessmentId}/
                            ├── signatures/
                            │   └── customer-signature.jpg
                            └── files/
                                └── {userUploadedFileName}
**/

export class StoragePaths {
	private static readonly ROOT_USER = 'user';
	private static readonly ROOT_ORGANISATION = 'organisation';
	private static readonly LEAD_REQUEST = 'lead-request';
	private static readonly ASSESSMENT = 'assessment';
	private static readonly SIGNATURES = 'signatures';
	private static readonly FILES = 'files';
	private static readonly PROFILE_PICTURE = 'profile-picture';

	static userProfilePicture(userId: string): string {
		return `${this.ROOT_USER}/${userId}/${this.PROFILE_PICTURE}/profile.jpg`;
	}

	static organisationPath(organisationId: string): string {
		return `${this.ROOT_ORGANISATION}/${organisationId}`;
	}

	static leadRequestPath(organisationId: string, leadRequestId: string): string {
		return `${this.organisationPath(organisationId)}/${this.LEAD_REQUEST}/${leadRequestId}`;
	}

	static assessmentPath(organisationId: string, leadRequestId: string, assessmentId: string): string {
		return `${this.leadRequestPath(organisationId, leadRequestId)}/${this.ASSESSMENT}/${assessmentId}`;
	}

	static assessmentCustomerSignatureFolder(organisationId: string, leadRequestId: string, assessmentId: string): string {
		return `${this.assessmentPath(organisationId, leadRequestId, assessmentId)}/${this.SIGNATURES}`;
	}

	static assessmentCustomerSignature(organisationId: string, leadRequestId: string, assessmentId: string): string {
		return `${this.assessmentCustomerSignatureFolder(organisationId, leadRequestId, assessmentId)}/customer-signature.jpg`;
	}

	static assessmentFile(organisationId: string, leadRequestId: string, assessmentId: string, fileName: string): string {
		return `${this.assessmentPath(organisationId, leadRequestId, assessmentId)}/${this.FILES}/${fileName}`;
	}
}
