import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IOrganisationForm, OrganisationIndustry } from '@organisations/models/organisations';
import { SelectControlComponent } from '@shared/components/forms/select-control/select-control.component';
import { StandardControlComponent } from '@shared/components/forms/standard-control/standard-control.component';
import { AccordionItemComponent } from '@shared/components/ui/accordion-item/accordion-item.component';
import { AccordionComponent } from '@shared/components/ui/accordion/accordion.component';
import { ISelectOption, selectOptionsFromEnum } from '@shared/models/form-controls';

@Component({
	selector: 'app-mobile-organisation-details',
	standalone: true,
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		AccordionComponent,
		AccordionItemComponent,
		StandardControlComponent,
		SelectControlComponent,
	],
	templateUrl: './mobile-organisation-details.component.html',
	styleUrl: './mobile-organisation-details.component.scss',
})
export class MobileOrganisationDetailsComponent {
	@Input() organisationForm: FormGroup<IOrganisationForm>;

	industryOptions: Array<ISelectOption> = selectOptionsFromEnum(OrganisationIndustry);
}
