import { Offcanvas } from 'bootstrap';
import * as packageJson from 'package.json';
import { filter, takeUntil } from 'rxjs';
import { AppRoutes } from 'src/app/app.routes';

import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '@auth/services/auth.service';
import { INavigationItem } from '@layout/models/navigation';
import { OrganisationSelectorComponent } from '@organisations/components/organisation-selector/organisation-selector.component';
import { OrganisationUserAccessLevel } from '@organisations/models/organisation-user';
import { OrganisationRoutes } from '@organisations/organisation.routes';
import { OrganisationSelectedService } from '@organisations/services/organisation-selected/organisation-selected.service';
import { OrganisationSettingsRoutes, SettingsRoutes } from '@settings/settings.routes';

import { MenuNavItemComponent } from '../menu-nav-item/menu-nav-item.component';

@Component({
	selector: 'app-menu',
	standalone: true,
	imports: [CommonModule, OrganisationSelectorComponent, MenuNavItemComponent],
	templateUrl: './menu.component.html',
	styleUrl: './menu.component.scss',
})
export class MenuComponent implements OnInit, OnDestroy {
	@Input() isCollapsed = false;
	destroyed$ = new EventEmitter<void>();
	AppRoutes = AppRoutes;
	SettingsRoutes = SettingsRoutes;

	authService = inject(AuthService);
	private organisationSelectedService = inject(OrganisationSelectedService);

	private organisationEnabled = false;
	private clientsEnabled = false;
	private leadsEnabled = false;
	private scheduleEnabled = false;
	navigationItems: Array<INavigationItem> = [];
	version: string;

	ngOnInit(): void {
		this.organisationSelectedService.selectedOrganisation
			.pipe(
				takeUntil(this.destroyed$),
				filter((o) => o !== null),
			)
			.subscribe((organisationUser) => {
				this.organisationEnabled = organisationUser?.accessLevel === OrganisationUserAccessLevel.OWNER;
				this.clientsEnabled = organisationUser?.accessLevel === OrganisationUserAccessLevel.OWNER;
				this.leadsEnabled = organisationUser?.accessLevel === OrganisationUserAccessLevel.OWNER;
				this.scheduleEnabled = !!organisationUser;
				this.setNavigationItems();
			});
		this.version = packageJson.version;
	}

	ngOnDestroy(): void {
		this.destroyed$.emit();
	}

	handleSignOut(): void {
		this.authService.signOut();
	}

	setNavigationItems(): void {
		this.navigationItems = [
			{
				icon: 'bi-house',
				label: 'Dashboard',
				route: [AppRoutes.DASHBOARD],
				disabled: false,
				visible: true,
			},
			{
				icon: 'bi-bucket',
				label: 'Leads',
				route: [AppRoutes.CLIENT_LEADS_DASHBOARD],
				disabled: !this.leadsEnabled,
				visible: this.leadsEnabled,
			},
			{
				icon: 'bi-calendar2-plus',
				label: 'Schedule',
				route: [AppRoutes.SCHEDULE],
				disabled: !this.scheduleEnabled,
				visible: this.scheduleEnabled,
			},
			{
				icon: 'bi-people',
				label: 'Clients',
				route: [AppRoutes.CLIENTS],
				disabled: !this.clientsEnabled,
				visible: this.clientsEnabled,
			},
			{
				icon: 'bi-diagram-2',
				label: 'Organisation',
				route: [AppRoutes.ORGANISATION],
				disabled: !this.organisationEnabled,
				visible: this.organisationEnabled,
				children: [
					{
						icon: 'bi-briefcase',
						label: 'Details',
						route: [AppRoutes.ORGANISATION, OrganisationRoutes.DETAILS],
						disabled: !this.organisationEnabled,
						visible: this.organisationEnabled,
					},
					{
						icon: 'bi-person-fill-add',
						label: 'Users',
						route: [AppRoutes.ORGANISATION, OrganisationRoutes.USERS],
						disabled: !this.organisationEnabled,
						visible: this.organisationEnabled,
					},
					{
						icon: 'bi-receipt',
						label: 'Billing',
						route: [AppRoutes.ORGANISATION, OrganisationRoutes.BILLING],
						disabled: !this.organisationEnabled,
						visible: this.organisationEnabled,
					},
				],
			},
			{
				icon: 'bi-gear',
				label: 'Settings',
				route: [AppRoutes.SETTINGS],
				// Enable this when user settings is back in
				disabled: !this.organisationEnabled,
				visible: this.organisationEnabled,
				children: [
					{
						icon: 'bi-diagram-2',
						label: 'Organisation',
						route: [AppRoutes.SETTINGS, SettingsRoutes.ORGANISATION],
						disabled: !this.organisationEnabled,
						visible: this.organisationEnabled,
						children: [
							{
								icon: 'bi-clock',
								label: 'Business Hours',
								route: [AppRoutes.SETTINGS, SettingsRoutes.ORGANISATION, OrganisationSettingsRoutes.BUSINESS_HOURS],
								disabled: !this.organisationEnabled,
								visible: this.organisationEnabled,
							},
							{
								icon: 'bi-backpack',
								label: 'Service Types',
								route: [AppRoutes.SETTINGS, SettingsRoutes.ORGANISATION, OrganisationSettingsRoutes.SERVICE_TYPES],
								disabled: !this.organisationEnabled,
								visible: this.organisationEnabled,
							},
							// Hidden until we implement the assessment form feature
							// Ticket: https://serveox.atlassian.net/browse/SMVP-135
							// {
							// 	icon: 'bi-ui-checks',
							// 	label: 'Assessment Form',
							// 	route: [AppRoutes.SETTINGS, SettingsRoutes.ORGANISATION, OrganisationSettingsRoutes.ASSESSMENT_FORM],
							// 	disabled: !this.organisationEnabled,
							// 	visible: this.organisationEnabled,
							// },
							{
								icon: 'bi-link',
								label: 'Leads Link',
								route: [AppRoutes.SETTINGS, SettingsRoutes.ORGANISATION, OrganisationSettingsRoutes.LEADS_LINK],
								disabled: !this.organisationEnabled,
								visible: this.organisationEnabled,
							},
						],
					},
					// Hidden until we implement the user settings feature
					// Ticket: https://serveox.atlassian.net/browse/SMVP-137
					// {
					// 	icon: 'bi-person-circle',
					// 	label: 'User',
					// 	route: [AppRoutes.SETTINGS, SettingsRoutes.USER],
					// 	disabled: false,
					// 	visible: true,
					// },
				],
			},
		];
	}

	dismissOffcanvas(item: INavigationItem): void {
		if (!item.children) {
			const mobileOffcanvas = Offcanvas.getInstance('#mobileNavbar');
			mobileOffcanvas?.hide();
		}
	}
}
