import { CommonModule } from '@angular/common';
import { Component, Input, TemplateRef } from '@angular/core';
import { AlertTypes } from '@shared/models/alert';

@Component({
	selector: 'app-alert',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './alert.component.html',
	styleUrl: './alert.component.scss'
})
export class AlertComponent {
	@Input() content: string | TemplateRef<any>;
	@Input() alertType: AlertTypes = AlertTypes.INFO;
	@Input() alertIcon: string = 'bi-info-circle-fill';
	@Input() dismissible: boolean = false;
	@Input() marginBottom: number = 16;

	isTemplate(value: any): value is TemplateRef<any> {
		return value instanceof TemplateRef;
	}
}
