import { CalendarModule } from 'angular-calendar';

import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatePickerFullscreenComponent } from '@shared/components/calendar/date-picker-fullscreen/date-picker-fullscreen.component';
import { DropdownControlComponent } from '@shared/components/forms/dropdown-control/dropdown-control.component';
import { StandardGroupControlComponent } from '@shared/components/forms/standard-group-control/standard-group-control.component';
import { SwitchControlComponent } from '@shared/components/forms/switch-control/switch-control.component';
import { TextareaControlComponent } from '@shared/components/forms/textarea-control/textarea-control.component';
import { StepNavigatorComponent } from '@shared/components/ui/step-navigator/step-navigator.component';
import { CalendarDayViewComponent } from '@schedule/lib/components/calendar-day-view/calendar-day-view.component';
import { BaseScheduledItemCreate } from '@leads/client-facing/classes/base-scheduled-item-create.component';

@Component({
	selector: 'app-desktop-scheduled-item-create',
	standalone: true,
	imports: [
		CommonModule,
		StepNavigatorComponent,
		FormsModule,
		ReactiveFormsModule,
		DatePickerFullscreenComponent,
		DropdownControlComponent,
		CalendarModule,
		StandardGroupControlComponent,
		TextareaControlComponent,
		SwitchControlComponent,
		CalendarDayViewComponent
	],
	templateUrl: './desktop-schedule-item-create.component.html',
	styleUrl: './desktop-schedule-item-create.component.scss',
})
export class DesktopScheduleItemCreateComponent extends BaseScheduledItemCreate {}
