import { combineLatest, map, Observable, of, switchMap } from 'rxjs';

import { Injectable } from '@angular/core';
import { collectionData, doc, docData, DocumentData, DocumentReference, orderBy, query, Timestamp, where } from '@angular/fire/firestore';
import { Query } from '@firebase/firestore';
import { ScheduledAssessmentStatus } from '@leads/shared/models/domain/scheduled-assessment.domain';
import { ILeadRequest } from '@leads/shared/models/firebase/lead-request.model';
import { ILeadAssessment, ILeadAssessmentWithLeadRequest } from '@leads/shared/models/firebase/scheduled-assessment.model';
import { IOrganisation } from '@organisations/models/organisations';
import { FireStoreCollection } from '@shared/models/firestore';
import { FirestoreService } from '@shared/services/firestore/firestore.service';
import { IUser } from '@user/models/user';

@Injectable({
	providedIn: 'root',
})
export class ScheduleService extends FirestoreService<ILeadAssessment> {
	constructor() {
		super(FireStoreCollection.LEAD_ASSESSMENTS);
	}

	/**
	 * Get all scheduled assessments for an organisation where:
	 * - The status is SCHEDULED.
	 * - The date and time have been set.
	 */
	getScheduledAssessments(
		organisationRef: DocumentReference<IOrganisation>,
		startDate: Date,
		endDate: Date,
		employeeId?: string | null,
	): Observable<Array<ILeadAssessmentWithLeadRequest>> {
		const validStatuses = [
			ScheduledAssessmentStatus.REQUESTED,
			ScheduledAssessmentStatus.SCHEDULED,
			ScheduledAssessmentStatus.DECLINED,
			ScheduledAssessmentStatus.AWAITING_INVOICE,
			ScheduledAssessmentStatus.INVOICED,
			ScheduledAssessmentStatus.PARTIALLY_PAID,
			ScheduledAssessmentStatus.COMPLETED,
		];

		let leadAssessmentQuery = query(
			this.collectionRef,
			where('organisation', '==', organisationRef),
			where('status', 'in', validStatuses),
			where('assignment.dateTime.start', '>=', Timestamp.fromDate(startDate)),
			where('assignment.dateTime.end', '<=', Timestamp.fromDate(endDate)),
		);

		if (employeeId) {
			leadAssessmentQuery = query(
				leadAssessmentQuery,
				where('assignment.employee', '==', doc(this.firestore, `${FireStoreCollection.USERS}/${employeeId}`)),
			);
		}

		leadAssessmentQuery = query(leadAssessmentQuery, orderBy('assignment.dateTime.start'));
		return this.joinWithLeadRequests(leadAssessmentQuery);
	}

	getScheduledAssessmentsByEmployeeId(
		employeeId: string,
		startDate: Date,
		endDate: Date,
	): Observable<Array<ILeadAssessmentWithLeadRequest>> {
		const validStatuses = [ScheduledAssessmentStatus.REQUESTED, ScheduledAssessmentStatus.SCHEDULED];

		const leadAssessmentQuery = query(
			this.collectionRef,
			where('assignment.employee', '==', doc(this.firestore, `${FireStoreCollection.USERS}/${employeeId}`)),
			where('status', 'in', validStatuses),
			where('assignment.dateTime.start', '>=', Timestamp.fromDate(startDate)),
			where('assignment.dateTime.end', '<=', Timestamp.fromDate(endDate)),
			orderBy('assignment.dateTime.start'),
		);

		return this.joinWithLeadRequests(leadAssessmentQuery);
	}

	private joinWithLeadRequests(assessmentQuery: Query<ILeadAssessment, DocumentData>): Observable<Array<ILeadAssessmentWithLeadRequest>> {
		return collectionData(assessmentQuery, { idField: 'id' }).pipe(
			switchMap((assessments: Array<ILeadAssessment>) => {
				if (assessments.length === 0) {
					return of([]);
				}
				const assessmentWithAdditionalData$ = assessments.map((assessment) => {
					const organisationUserRef = assessment.assignment.employee as DocumentReference<IUser>;
					const leadRequestRef = assessment.leadRequest as DocumentReference<ILeadRequest>;

					const userData$ = docData(organisationUserRef, { idField: 'id' });
					const leadRequestData$ = docData(leadRequestRef, { idField: 'id' });

					return combineLatest([userData$, leadRequestData$]).pipe(
						map(
							([userData, leadRequestData]) =>
								({
									...assessment,
									employeeDisplayName: userData?.displayName,
									employeeId: userData?.id,
									leadRequestId: leadRequestData?.id,
									leadRequestNotes:
										leadRequestData?.onSiteVisitAssessment?.notes || leadRequestData?.callAssessment?.callNotes,
									customerName: leadRequestData?.personalDetails.name,
									customerNumber: leadRequestData?.mobileNumber,
									customerProperty: leadRequestData?.personalDetails.installationAddress,
								}) as ILeadAssessmentWithLeadRequest,
						),
					);
				});
				return combineLatest(assessmentWithAdditionalData$);
			}),
		);
	}

	updateScheduledAssessmentTimes(scheduledAssessmentId: string, startTime: Date, endTime: Date) {
		return super.update(scheduledAssessmentId, {
			'assignment.dateTime.start': Timestamp.fromDate(startTime),
			'assignment.dateTime.end': Timestamp.fromDate(endTime),
		});
	}

	updateScheduledAssessmentStatus(assessmentId: string, status: ScheduledAssessmentStatus) {
		return super.update(assessmentId, { status });
	}

	updateScheduledAssessmentNotes(assessmentId: string, notes: string) {
		return super.update(assessmentId, { 'assessmentDetails.internalAssessmentNotes': notes });
	}
}
