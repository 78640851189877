import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { DropdownControlComponent, IDropdownOption } from '@shared/components/forms/dropdown-control/dropdown-control.component';
import { BaseModalComponent } from '@shared/components/modals/base-modal/base-modal.component';

@Component({
	selector: 'app-time-editor',
	standalone: true,
	imports: [CommonModule, BaseModalComponent, DropdownControlComponent, FormsModule, ReactiveFormsModule],
	templateUrl: './time-editor.component.html',
	styleUrl: './time-editor.component.scss',
	host: {
		class: 'h-100',
	},
})
export class TimeEditorComponent implements OnInit {
	@Input() formGroup!: FormGroup;
	@Input() selectedDate!: NgbDate;
	@Output() confirmAction = new EventEmitter();

	minTime: Date;
	maxTime: Date;
	timeOptions: Array<IDropdownOption> = [];

	private activeModal = inject(NgbActiveModal);

	ngOnInit(): void {
		this.minTime = this.getDefaultMinTime();
		this.maxTime = this.getDefaultMaxTime();
		this.generateTimeOptions();
	}

	private generateTimeOptions(): void {
		const options: Array<IDropdownOption> = [];
		const interval = 15 * 60 * 1000;

		for (let currentTime = this.minTime.getTime(); currentTime <= this.maxTime.getTime(); currentTime += interval) {
			const currentDate = new Date(currentTime);
			options.push({
				id: this.formatTime(currentDate),
				value: currentDate,
				label: this.formatTime(currentDate),
			});
		}

		this.timeOptions = options;
	}

	private formatTime(date: Date): string {
		const hours = date.getHours().toString().padStart(2, '0');
		const minutes = date.getMinutes().toString().padStart(2, '0');
		return `${hours}:${minutes}`;
	}

	private getDefaultMinTime(): Date {
		const min = new Date(this.selectedDate.year, this.selectedDate.month - 1, this.selectedDate.day);
		min.setHours(0, 0, 0, 0);
		return min;
	}

	private getDefaultMaxTime(): Date {
		const max = new Date(this.selectedDate.year, this.selectedDate.month - 1, this.selectedDate.day);
		max.setHours(24, 0, 0, 0);
		return max;
	}

	saveAction(): void {
		if (this.invalidTime) {
			return;
		}
		this.confirmAction.emit();
		this.activeModal.close();
	}

	get invalidTime(): boolean {
		return (
			this.formGroup.controls.selectedStartTime.value >= this.formGroup.controls.selectedEndTime.value ||
			this.formGroup.controls.selectedEndTime.value <= this.formGroup.controls.selectedStartTime.value
		);
	}
}
