import { inject, Injectable } from '@angular/core';
import { ToastService } from '@shared/services/toast/toast.service';
import { ToastTypes } from '@shared/models/toast';
import {
	OrganisationSelectedService
} from '@organisations/services/organisation-selected/organisation-selected.service';
import { environment } from '../../../../../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class LeadRequestFormLinkService {
	private organisationSelectedService = inject(OrganisationSelectedService);

	private toastService = inject(ToastService);
	private baseUrl: string;

	constructor() {
		this.organisationSelectedService.selectedOrganisation.subscribe(selectedOrganisation => {
			if (selectedOrganisation) {
				this.baseUrl = `${environment.baseUrl}/${selectedOrganisation.organisation.id}/lead-request`;
			}
		});
	}

	getNewLeadLink(redirectToLeads?: boolean): string {
		const baseUrl = this.baseUrl;
		if (baseUrl && redirectToLeads) {
			return `${baseUrl}/new?redirectToLeads=true`;
		}
		return baseUrl;
	}

	public copyNewLeadLink(): void {
		if (this.baseUrl) {
			navigator.clipboard.writeText(this.baseUrl)
				.then(() => {
					this.toastService.showToast({
						title: 'Success',
						message: 'Lead request form link copied to clipboard!',
						type: ToastTypes.SUCCESS
					});
				})
				.catch(() => {
					this.toastService.showToast({
						title: 'Failed',
						message: 'Failed to copy the Lead request form link to clipboard.',
						type: ToastTypes.ERROR
					});
				});
		} else {
			this.toastService.showToast({
				title: 'Failed',
				message: 'Sorry the link is not available at this time',
				type: ToastTypes.ERROR
			});
		}
	}

	existingAssessment(assessmentId: string): string {
		return `${this.baseUrl}/view/${assessmentId}`;
	}
}
