import { CalendarEvent } from 'angular-calendar';
import { take } from 'rxjs';

import { inject, Injectable } from '@angular/core';
import { ScreenSize } from '@layout/services/layout/layout.service';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { CalendarPopoverComponent } from '@schedule/lib/components/calendar-popover/calendar-popover.component';
import { ScheduledEvent } from '@schedule/models/calendar/calendar.model';
import { ModalService } from '@shared/services/modal/modal.service';

@Injectable({
	providedIn: 'root',
})
export class CalendarPopoverServiceService {
	private modalService = inject(ModalService);

	private popover: NgbPopover | null;

	toggleEventPopover(screenSize: ScreenSize, popover: NgbPopover, event: CalendarEvent<ScheduledEvent>, isOwner: boolean): void {
		if (screenSize === ScreenSize.Mobile) {
			this.showModal(event, isOwner);
		} else {
			this.showPopup(popover, event);
		}
	}

	private showModal(event: CalendarEvent<ScheduledEvent>, isOwner: boolean) {
		const modalRef = this.modalService.open(CalendarPopoverComponent, true, 'md');
		modalRef.componentInstance.calendarEvent = event;
		modalRef.componentInstance.isOwner = isOwner;
		modalRef.componentInstance.closed.pipe(take(1)).subscribe(() => {
			modalRef.close();
		});
	}

	private showPopup(popover: NgbPopover, event: CalendarEvent<ScheduledEvent>) {
		if (!this.popover) {
			this.popover = popover;
			this.popover.open({ event });
			return;
		}

		this.popover.close();
		this.popover = popover;
		this.popover.open({ event });
	}

	public closePopup() {
		if (this.popover) {
			this.popover.close();
			this.popover = null;
		}
	}
}
