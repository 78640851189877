<div class="heading">
	<div class="left">
		<ng-container *ngIf="backPath">
			<button type="button" class="btn btn-outline-primary border-0" [routerLink]="backPath">
				<i class="bi bi-chevron-left"></i>
			</button>
		</ng-container>
		<h4 class="text-start text-primary fw-semibold">{{ title }}</h4>
	</div>
	<ng-container *ngIf="showLeadFormLink">
		<div class="right">
			<ng-container *ngIf="isMobileSize; else desktopSize">
				<button class="btn btn-sm btn-primary" type="button" (click)="copyToClipboard()">
					<i class="bi bi-person-lines-fill"></i>
				</button>
			</ng-container>
			<ng-template #desktopSize>
				<button class="btn btn-sm btn-primary" type="button" appLeadRequestFormLink>
					<i class="bi bi-person-lines-fill"></i>
					My Lead Form
				</button>
			</ng-template>
		</div>
	</ng-container>
</div>
