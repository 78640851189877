import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ScheduledEvent } from '@schedule/models/calendar/calendar.model';

@Component({
	selector: 'app-scheduled-item-notes-edit-modal',
	standalone: true,
	imports: [CommonModule, FormsModule],
	templateUrl: './scheduled-item-notes-edit-modal.component.html',
	styleUrl: './scheduled-item-notes-edit-modal.component.scss',
})
export class ScheduledItemNotesEditModalComponent implements OnInit {
	@Input() scheduledEvent: ScheduledEvent;
	@Output() saveAssessment = new EventEmitter<string>();
	private activeModal = inject(NgbActiveModal);

	contentText: string;

	ngOnInit(): void {
		this.contentText = this.scheduledEvent.scheduledAssessmentNotes!;
	}

	closeModal(): void {
		this.activeModal.close();
	}

	save(): void {
		this.saveAssessment.emit(this.contentText);
		this.closeModal();
	}
}
