import { take } from 'rxjs';

import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { BaseLeadRequestDetail } from '@leads/client-facing/classes/base-lead-request-detail.component';
import { MobileScheduledItemsListModalComponent } from '@leads/client-facing/screens/mobile/mobile-scheduled-items-list-modal/mobile-scheduled-items-list-modal.component';
import { AlertComponent } from '@shared/components/alerts/alert/alert.component';
import { LinkLocationComponent } from '@shared/components/buttons/link-location/link-location.component';
import { TextareaControlComponent } from '@shared/components/forms/textarea-control/textarea-control.component';
import { AccordionItemComponent } from '@shared/components/ui/accordion-item/accordion-item.component';
import { AccordionComponent } from '@shared/components/ui/accordion/accordion.component';
import { NgbDateToDatePipe } from '@shared/pipes/ngb-date-to-date.pipe';

@Component({
	selector: 'app-mobile-lead-request-detail',
	standalone: true,
	imports: [
		CommonModule,
		NgbDateToDatePipe,
		AccordionComponent,
		AccordionItemComponent,
		TextareaControlComponent,
		AccordionComponent,
		AccordionItemComponent,
		AlertComponent,
		LinkLocationComponent,
	],
	templateUrl: './mobile-lead-request-detail.component.html',
	styleUrl: './mobile-lead-request-detail.component.scss',
})
export class MobileLeadRequestDetailComponent extends BaseLeadRequestDetail {
	openScheduleItemsListModel() {
		const modalRef = this.modalService.open(MobileScheduledItemsListModalComponent, true, 'md');
		modalRef.componentInstance.leadRequest = this.leadRequest;
		modalRef.componentInstance.isSavingNotes = this.isSavingNotes;
		modalRef.componentInstance.client = this.client;
		modalRef.componentInstance.scheduledItems = this.scheduledItems;
		modalRef.componentInstance.internalNotesForm = this.internalNotesForm;
		modalRef.componentInstance.readonlyView = this.readonlyView;
		modalRef.componentInstance.newScheduledItemCreated.pipe(take(1)).subscribe(() => {
			this.newScheduledItemCreated.emit();
		});
	}
}
