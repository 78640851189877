import 'zone.js';

import { NgSwitch, NgSwitchCase } from '@angular/common';
import { Component } from '@angular/core';
import { BaseCalendarView } from '@schedule/lib/classes/base-calendar-view';
import { CalendarControlsComponent } from '@schedule/lib/components/calendar-controls/calendar-controls.component';
import { CalendarDayViewComponent } from '@schedule/lib/components/calendar-day-view/calendar-day-view.component';
import { CalendarMonthViewComponent } from '@schedule/lib/components/calendar-month-view/calendar-month-view.component';
import { CalendarWeekViewComponent } from '@schedule/lib/components/calendar-week-view/calendar-week-view.component';
import { LoadingStateComponent } from '@shared/components/loading-state/loading-state.component';

@Component({
	selector: 'app-calendar-employee-view',
	standalone: true,
	imports: [
		LoadingStateComponent,
		CalendarMonthViewComponent,
		CalendarWeekViewComponent,
		CalendarDayViewComponent,
		CalendarControlsComponent,
		NgSwitchCase,
		NgSwitch,
	],
	templateUrl: './calendar-employee-view.component.html',
	styleUrl: './calendar-employee-view.component.scss',
})
export class CalendarEmployeeViewComponent extends BaseCalendarView {}
