import { Timestamp } from '@angular/fire/firestore';
import { FormArray, FormControl } from '@angular/forms';
import { FireStoreDocument } from '@shared/models/firestore';

export interface IOrganisation extends FireStoreDocument {
	companyName: string;
	companyContactNumber: string;
	companyRegistrationNumber: string | null;
	companyContactName: string;
	companySize: number;
	industry: OrganisationIndustry;
	companyContactEmail: string;
	createDate: Timestamp;
	status: OrganisationStatus;
	settings: IOrganisationSettings;
}

export interface IOrganisationSettings {
	businessHours: IBusinessHours;
	serviceTypes: Array<string>;
	leadsForm: {
		customQuestion1?: {
			question: string;
			required: boolean;
			category: OrganisationCustomQuestionCategories;
		};
		customQuestion2?: {
			question: string;
			required: boolean;
			category: OrganisationCustomQuestionCategories;
		};
	};
}

export interface IBusinessHours {
	monday: boolean;
	tuesday: boolean;
	wednesday: boolean;
	thursday: boolean;
	friday: boolean;
	saturday: boolean;
	sunday: boolean;
}

export enum OrganisationCustomQuestionCategories {
	CONTACT_INFORMATION = 'CONTACT_INFORMATION',
	ASSESSMENT_INFORMATION = 'ASSESSMENT_INFORMATION',
}

export enum OrganisationStatus {
	ACTIVE = 'ACTIVE',
	OVERDUE = 'OVERDUE',
	SUSPENDED = 'SUSPENDED',
	TRIAL = 'TRIAL',
}

export enum OrganisationIndustry {
	PLUMBING = 'Plumbing',
	OTHER = 'Other',
}

export interface IOrganisationForm {
	companyName: FormControl<string | null>;
	companyContactNumber: FormControl<string | null>;
	companyRegistrationNumber: FormControl<string | null>;
	companyContactName: FormControl<string | null>;
	companySize: FormControl<number | null>;
	industry: FormControl<OrganisationIndustry | null>;
	companyContactEmail: FormControl<string | null>;
}

export interface IOrganisationBusinessHoursForm {
	monday: FormControl<boolean | null>;
	tuesday: FormControl<boolean | null>;
	wednesday: FormControl<boolean | null>;
	thursday: FormControl<boolean | null>;
	friday: FormControl<boolean | null>;
	saturday: FormControl<boolean | null>;
	sunday: FormControl<boolean | null>;
}

export interface IOrganisationServiceTypesForm {
	serviceTypes: FormArray<FormControl<string | null>>;
}
