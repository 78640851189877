<div class="assessment-content">
	<div class="assessment-overview">
		<div class="schedule-details">
			<h6 class="text-primary header-container">
				<span>Schedule details</span>
				<app-assessment-status-badge [status]="scheduledItem.status"></app-assessment-status-badge>
			</h6>
			<div class="label">
				<div class="value">{{ scheduledItem.selectedStartTime | date: 'EEEE, MMMM d' }}</div>
			</div>

			<div class="label">
				<div class="value">
					{{ scheduledItem.selectedStartTime | date: 'hh:mm a' }} - {{ scheduledItem.selectedEndTime | date: 'hh:mm a' }}
				</div>
			</div>
		</div>
		<div class="assessment-type-details">
			<ng-container *ngIf="scheduledItem.leadRequestType === LeadRequestType.ON_SITE_VISIT">
				<h6 class="text-primary">On site service</h6>
				<div class="label" *ngIf="scheduledItem.assessmentType">
					<div class="name">Purpose of visit</div>
					<div class="value">{{ assessmentType(scheduledItem.assessmentType) }}</div>
				</div>
				<div class="label">
					<div class="name">Service Types</div>
					<div class="value desktop-service-types">{{ scheduledItem.assessmentServiceTypes?.join(', ') }}</div>
				</div>
				<ul class="list-group list-group-flush mobile-service-types">
					<li class="list-group-item" *ngFor="let assessment of scheduledItem.assessmentServiceTypes">{{ assessment }}</li>
				</ul>
			</ng-container>
			<ng-container *ngIf="scheduledItem.leadRequestType === LeadRequestType.CALL">
				<h6 class="text-primary">Call details</h6>
				<div class="label">
					<em class="value">This is just a phone assessment to understand what you need from us and how we can help you </em>
				</div>
			</ng-container>
		</div>
	</div>

	<div class="actions">
		<span *ngIf="assessmentInStatus(ScheduledAssessmentStatus.SCHEDULED)" class="status text-success">
			You have accepted this request
		</span>
		<span *ngIf="assessmentInStatus(ScheduledAssessmentStatus.DECLINED)" class="status text-danger">
			You have declined this request
		</span>
		<span *ngIf="assessmentInStatus(ScheduledAssessmentStatus.CANCELLED)" class="status text-danger">
			Your service specialist has cancelled this request
		</span>
		<div class="btn-container" *ngIf="!assessmentInStatus(ScheduledAssessmentStatus.CANCELLED)">
			<button
				type="button"
				class="btn btn-sm btn-outline-danger btn-width"
				(click)="declineAssessment()"
				[ngClass]="{ disabled: !declineActionEnabled }"
			>
				Decline
			</button>
			<button
				type="button"
				class="btn btn-sm btn-primary btn-width"
				(click)="acceptAssessment()"
				[ngClass]="{ disabled: !acceptActionEnabled }"
			>
				Accept
			</button>
		</div>
	</div>
</div>
