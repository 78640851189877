import { ScheduledAssessmentStatus } from '@leads/shared/models/domain/scheduled-assessment.domain';

export interface ICalendarEventColour {
	primary: CalendarColours;
	secondary: CalendarColours;
	secondaryText?: CalendarColours;
}

export enum CalendarColours {
	BLACK = '#000000',
	WHITE = '#FFFFFF',
	GREY_LIGHT = '#D9D9D9',
	GREY_DARK = '#8C8C8C',
	BLUE_LIGHT = '#E6F4FF',
	RED = '#FF4D4F',
	ORANGE = '#FAAD14',
	TEAL = '#36CFC9',
	YELLOW = '#FFEC3D',
	GREEN_DARK = '#389E0D',

	// Google Colours
	DEFAULT_BLUE = '#039BE5',
	LAVANDER = '#7986CB',
	GRAPE = '#8E24AA',
	TANGERINE = '#E67C73',
	BANANA = '#F6BF26',
	BASIL = '#33B679',
	SAGE = '#0B8043',
	PEACOCK = '#039BE5',
	CYAN = '#4285F4',
	GRAPHITE = '#616161',
	FLAMINGO = '#E37400',
	TOMATO = '#D50000',
}

export class CalendarEventColours {
	static scheduledAssessment(status: ScheduledAssessmentStatus): ICalendarEventColour {
		let colour: ICalendarEventColour;
		switch (status) {
			case ScheduledAssessmentStatus.REQUESTED:
				colour = {
					primary: CalendarColours.GREY_LIGHT,
					secondary: CalendarColours.GREY_LIGHT,
					secondaryText: CalendarColours.BLACK,
				};
				break;
			default:
			case ScheduledAssessmentStatus.SCHEDULED:
				colour = {
					primary: CalendarColours.DEFAULT_BLUE,
					secondary: CalendarColours.DEFAULT_BLUE,
					secondaryText: CalendarColours.WHITE,
				};
				break;
			case ScheduledAssessmentStatus.DECLINED:
			case ScheduledAssessmentStatus.CANCELLED:
				colour = {
					primary: CalendarColours.TOMATO,
					secondary: CalendarColours.TOMATO,
					secondaryText: CalendarColours.WHITE,
				};
				break;
			case ScheduledAssessmentStatus.AWAITING_INVOICE:
				colour = {
					primary: CalendarColours.TANGERINE,
					secondary: CalendarColours.TANGERINE,
					secondaryText: CalendarColours.WHITE,
				};
				break;
			case ScheduledAssessmentStatus.INVOICED:
				colour = {
					primary: CalendarColours.TEAL,
					secondary: CalendarColours.TEAL,
					secondaryText: CalendarColours.BLACK,
				};
				break;
			case ScheduledAssessmentStatus.PARTIALLY_PAID:
				colour = {
					primary: CalendarColours.BANANA,
					secondary: CalendarColours.BANANA,
					secondaryText: CalendarColours.WHITE,
				};
				break;
			case ScheduledAssessmentStatus.COMPLETED:
				colour = {
					primary: CalendarColours.SAGE,
					secondary: CalendarColours.SAGE,
					secondaryText: CalendarColours.WHITE,
				};
				break;
		}

		return colour;
	}
}
