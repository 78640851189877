import { map, take, tap } from 'rxjs';

import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { IAvailableOrganisation } from '@organisations/models/organisation-user';
import { OrganisationSelectedService } from '@organisations/services/organisation-selected/organisation-selected.service';
import { OrganisationUserService } from '@organisations/services/organisation-user/organisation-user.service';

export const selectedOrganisationGuard: CanActivateFn = (route, state) => {
	const organisationUserService = inject(OrganisationUserService);
	const organisationSelectedService = inject(OrganisationSelectedService);

	return organisationUserService.availableOrganisations().pipe(
		take(1),
		tap((availableOrganisations: IAvailableOrganisation[]) => {
			const storedOrganisationId = organisationSelectedService.getSelectedOrganisationId();
			let selectedOrganisation: IAvailableOrganisation | null = null;
			if (availableOrganisations.length === 0) {
				selectedOrganisation = null;
			} else {
				selectedOrganisation = availableOrganisations[0];
			}

			if (storedOrganisationId) {
				const matchedOrganisation = availableOrganisations.find((o) => o.organisation.id === storedOrganisationId);
				if (matchedOrganisation) {
					selectedOrganisation = matchedOrganisation;
				}
			}

			organisationSelectedService.setSelectedOrganisation(selectedOrganisation);
		}),
		map(() => true),
	);
};
