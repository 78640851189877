import { environment } from 'src/environments/environment';

import { CommonModule, Location } from '@angular/common';
import { Component, NgZone, OnInit } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { FirebaseAppCheck } from '@capacitor-firebase/app-check';
import { App, BackButtonListenerEvent, URLOpenListenerEvent } from '@capacitor/app';
import { Toast } from '@capacitor/toast';
import { ToasterComponent } from '@shared/components/alerts/toaster/toaster.component';
import { AnalyticEvent } from '@shared/models/analytics';
import { AnalyticsService } from '@shared/services/analytics/analytics.service';

@Component({
	selector: 'app-root',
	standalone: true,
	imports: [RouterOutlet, CommonModule, ToasterComponent],
	templateUrl: './app.component.html',
	styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
	constructor(
		private router: Router,
		private zone: NgZone,
		private location: Location,
		private analyticsService: AnalyticsService,
	) {}

	private lastBackPressTime = 0;
	private exitDelay = 2000;

	ngOnInit(): void {
		this.initializeDeepLinks();
		this.initializeBackButtonNavigation();
		this.initializeAppCheck();
		this.analyticsService.logEvent(AnalyticEvent.APP_LOAD);
	}

	private initializeBackButtonNavigation() {
		// Android
		App.addListener('backButton', ({ canGoBack }: BackButtonListenerEvent) => {
			const currentTime = new Date().getTime();

			if (!canGoBack) {
				if (currentTime - this.lastBackPressTime < this.exitDelay) {
					App.exitApp();
				} else {
					this.lastBackPressTime = currentTime;
					Toast.show({
						text: 'Press back again to exit',
						duration: 'short',
					});
				}
			} else {
				this.location.back();
			}
		});
	}

	private initializeDeepLinks(): void {
		// Android
		App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
			this.zone.run(() => {
				const domain = 'app.serveox.com';
				const pathArray = event.url.split(domain);
				const appPath = pathArray.pop();
				if (appPath) {
					this.router.navigateByUrl(appPath);
				}
			});
		});
	}

	private async initializeAppCheck() {
		try {
			await FirebaseAppCheck.initialize({
				siteKey: environment.recaptchaPublicKey,
				isTokenAutoRefreshEnabled: true,
				debug: environment.recaptchaDebugEnabled,
			});
		} catch (error) {
			console.error('Failed to initialize App Check:', error);
		}
	}
}
