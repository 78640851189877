import { takeUntil } from 'rxjs';

import { Component, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IOnSiteAssessmentForm } from '@leads/customer-facing/models/domain/lead-request.domain';
import { LeadOnSiteVisitDateTimeType } from '@leads/shared/models/domain/lead-request.domain';
import { DatePickerParent } from '@shared/components/calendar/date-picker';
import { IDisabledDaysOfTheWeek } from '@shared/components/calendar/models';
import { dateToNgbDateStruct } from '@shared/components/calendar/utilities';
import { IDropdownOption } from '@shared/components/forms/dropdown-control/dropdown-control.component';
import { IRadioOption } from '@shared/models/form-controls';
import { IPopover } from '@shared/models/popover';

@Component({
	template: '',
})
export abstract class LeadAssessmentDetails extends DatePickerParent implements OnInit, OnDestroy {
	@Input() form: FormGroup<IOnSiteAssessmentForm>;
	@Input() disabledDaysOfTheWeek: IDisabledDaysOfTheWeek;
	@Input() serviceTypes: Array<IDropdownOption> = [];

	destroyed$ = new EventEmitter<void>();

	LeadOnSiteVisitDateTimeType = LeadOnSiteVisitDateTimeType;
	dateTypeOptions: Array<IRadioOption> = [
		{
			label: 'Request Date',
			value: LeadOnSiteVisitDateTimeType.REQUEST,
			id: 'request',
		},
		{
			label: 'ASAP',
			value: LeadOnSiteVisitDateTimeType.ASAP,
			id: 'asap',
		},
	];

	ngOnInit(): void {
		this.validateForm();
	}

	ngOnDestroy(): void {
		this.destroyed$.emit();
	}

	datePickerDateChanged(date: Date): void {
		this.form.controls.requestedDate.patchValue(dateToNgbDateStruct(date));
	}

	get dateType(): FormControl {
		return this.form.controls.dateType;
	}

	validateForm(): void {
		// ToDo: Just enable/disable form controls
		this.dateType.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((dateType) => {
			if (dateType === LeadOnSiteVisitDateTimeType.REQUEST) {
				this.form.controls.requestedDate?.setValidators([Validators.required]);
				this.form.controls.requestedTimeWindow?.setValidators([Validators.required]);
			}

			if (dateType === LeadOnSiteVisitDateTimeType.ASAP) {
				this.form.controls.requestedDate?.clearValidators();
				this.form.controls.requestedTimeWindow?.clearValidators();
				this.form.controls.requestedDate?.reset();
				this.form.controls.requestedTimeWindow?.reset();
			}

			this.form.controls.requestedDate?.updateValueAndValidity();
			this.form.controls.requestedTimeWindow?.updateValueAndValidity();
		});
	}

	assessmentPopOver: IPopover = {
		triggerText: {
			desktop: "What's this?",
			mobile: "What's this?",
		},
		title: 'Assessment Date & Time',
		description:
			"Select a preferred date and time for your On-Site Service Request, or choose ASAP if it's an emergency and the specialist will be notified immediately.",
		icon: 'bi-info-circle-fill',
	};

	serviceTypePopover: IPopover = {
		triggerText: {
			desktop: "What's this?",
			mobile: "What's this?",
		},
		title: 'Service Type',
		description:
			'Please select your required service from the dropdown to help the specialist prepare for your On-Site Service Request.',
		icon: 'bi-info-circle-fill',
	};
}
