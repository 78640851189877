import { Injectable } from '@angular/core';
import { FirebaseAnalytics } from '@capacitor-firebase/analytics';
import { AnalyticEvent } from '@shared/models/analytics';

@Injectable({
	providedIn: 'root',
})
export class AnalyticsService {
	async logEvent(eventName: AnalyticEvent) {
		await FirebaseAnalytics.logEvent({
			name: eventName,
		});
	}

	async setUserId(userId: string) {
		await FirebaseAnalytics.setUserId({
			userId: userId,
		});
	}
}
