import { Injectable, Type } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
	providedIn: 'root',
})
export class ModalService {
	private modalRef: NgbModalRef | undefined;

	constructor(private modalService: NgbModal) {}

	open(content: Type<unknown>, fullScreen: boolean = false, size: 'sm' | 'md' | 'lg' | 'xl' = 'lg'): NgbModalRef {
		this.modalRef = this.modalService.open(content, {
			ariaLabelledBy: 'modal-title',
			centered: true,
			size: size,
			fullscreen: fullScreen,
			scrollable: true,
		});
		return this.modalRef;
	}

	close(): void {
		if (this.modalRef) {
			this.modalRef.dismiss();
		}
	}
}
