<div class="dashboard-container">
	<div class="heading">
		<app-page-title title="Leads"></app-page-title>
	</div>
	<div class="content">
		<app-loading-state [isLoading]="isLoading" [loadedContent]="loadedContent"></app-loading-state>
		<ng-template #loadedContent>
			<ng-container *ngIf="userHasAccess; else noAccess">
				<div class="dashboard-container">
					<div class="content">
						<ng-container *ngIf="isMobileSize; else desktopSize">
							<app-mobile-lead-request-list
								[leads]="leads"
								[isLoading]="isSearching"
								[searchTerm$]="searchTerm$"
								[leadRequestType$]="leadRequestType$"
								[leadStatus$]="leadStatus$"
								(onClick)="onClick($event)"
							></app-mobile-lead-request-list>
						</ng-container>
						<ng-template #desktopSize>
							<app-desktop-lead-request-list
								[leads]="leads"
								[isLoading]="isSearching"
								[searchTerm$]="searchTerm$"
								[leadRequestType$]="leadRequestType$"
								[leadStatus$]="leadStatus$"
								(onClick)="onClick($event)"
							></app-desktop-lead-request-list>
						</ng-template>
					</div>
				</div>
			</ng-container>

			<ng-template #noAccess>
				<app-unauthorised-banner
					headingText="It looks like you do not have access to this organisation or do not have the required access level."
				></app-unauthorised-banner>
			</ng-template>
		</ng-template>
	</div>
</div>
