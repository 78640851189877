import { Injectable } from '@angular/core';
import { StoragePaths } from '@shared/models/storage';
import { StorageService } from '@shared/services/storage/storage.service';

@Injectable({
	providedIn: 'root',
})
export class LeadStorageService extends StorageService {
	/**
	 * Uploads a customer signature for an assessment.
	 * @param organisationId - The organisation's unique ID.
	 * @param leadRequestId - The lead's unique ID.
	 * @param assessmentId - The assessment's unique ID.
	 * @param customerName - The customer's name.
	 * @param file - The signature file.
	 * @returns A promise that resolves with the download URL of the uploaded file.
	 */
	uploadCustomerAssessmentSignature(
		organisationId: string,
		leadRequestId: string,
		assessmentId: string,
		customerName: string,
		file: File | Blob,
	): Promise<string> {
		const path = StoragePaths.assessmentCustomerSignature(organisationId, leadRequestId, assessmentId);
		return this.uploadFile(path, file, {
			organisationId: organisationId,
			leadRequestId: leadRequestId,
			assessmentId: assessmentId,
			customerName: customerName,
		});
	}

	/**
	 * Fetches a customer signature for an assessment.
	 * @param organisationId - The organisation's unique ID.
	 * @param leadRequestId - The lead's unique ID.
	 * @param assessmentId - The assessment's unique ID.
	 * @returns A promise that resolves with the download URL of the customer signature.
	 */
	async getCustomerAssessmentSignatureUrl(organisationId: string, leadRequestId: string, assessmentId: string): Promise<string | null> {
		const list = await this.listFilesInFolder(
			StoragePaths.assessmentCustomerSignatureFolder(organisationId, leadRequestId, assessmentId),
		);
		if (list.length === 0) {
			return null;
		}
		const path = StoragePaths.assessmentCustomerSignature(organisationId, leadRequestId, assessmentId);
		return this.getFileUrl(path);
	}

	/**
	 * Uploads a file (e.g., quote, invoice) for an assessment.
	 * @param organisationId - The organisation's unique ID.
	 * @param leadId - The lead's unique ID.
	 * @param assessmentId - The assessment's unique ID.
	 * @param file - The file to be uploaded.
	 * @returns A promise that resolves with the download URL of the uploaded file.
	 */
	uploadAssessmentFile(organisationId: string, leadId: string, assessmentId: string, file: File): Promise<string> {
		const fileName = file.name;
		const path = StoragePaths.assessmentFile(organisationId, leadId, assessmentId, fileName);
		return this.uploadFile(path, file);
	}
}
