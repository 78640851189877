<form [formGroup]="organisationForm">
	<app-accordion parentId="mobileCompanyDetails">
		<app-accordion-item itemId="basicCompanyDetails" parentId="mobileCompanyDetails" [isOpen]="true">
			<div header>Basic Company Details</div>

			<app-standard-control
				body
				class="control"
				type="text"
				name="companyName"
				[formGroup]="organisationForm"
				label="Company Name"
				placeholder="Company Name"
			></app-standard-control>

			<app-standard-control
				body
				class="control"
				type="text"
				name="companyRegistrationNumber"
				[formGroup]="organisationForm"
				label="Company Registration Number"
				placeholder="Company Registration Number"
				patternMessage="Company Registration Number must be YYYY/NNNNNN/XX"
			></app-standard-control>

			<app-standard-control
				body
				class="control"
				type="number"
				name="companySize"
				[formGroup]="organisationForm"
				label="Company Size"
				placeholder="Company Size"
			></app-standard-control>
		</app-accordion-item>

		<app-accordion-item itemId="companyContactDetails" parentId="mobileCompanyDetails">
			<div header>Company Contact Details</div>

			<app-standard-control
				body
				class="control"
				type="text"
				name="companyContactName"
				[formGroup]="organisationForm"
				label="Company Contact Name"
				placeholder="Company Contact Name"
			></app-standard-control>

			<app-standard-control
				body
				class="control"
				type="text"
				name="companyContactNumber"
				[formGroup]="organisationForm"
				label="Company Contact Number"
				placeholder="Company Contact Number"
			></app-standard-control>

			<app-standard-control
				body
				class="control"
				type="email"
				name="companyContactEmail"
				[formGroup]="organisationForm"
				label="Company Contact Email"
				placeholder="Company Contact Email"
			></app-standard-control>
		</app-accordion-item>

		<app-accordion-item itemId="industryItem" parentId="mobileCompanyDetails">
			<div header>Industry</div>

			<app-select-control
				body
				class="control"
				[formGroup]="organisationForm"
				name="industry"
				label="Industry"
				[options]="industryOptions"
			></app-select-control>
		</app-accordion-item>
	</app-accordion>
</form>
