<div class="modal-body text-primary">
	<div class="text-primary text-container">
		<h5>Scheduled Assessment Notes</h5>
		<textarea class="form-control" [(ngModel)]="contentText" rows="15"></textarea>
	</div>
	<div class="button-container mt-2">
		<button type="button" class="btn btn-sm btn-primary" (click)="closeModal()"><i class="bi bi-arrow-left"></i> Back</button>
		<button type="button" class="btn btn-sm btn-outline-primary" (click)="save()">Save</button>
	</div>
</div>
