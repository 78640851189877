import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgClass, NgIf } from '@angular/common';
import { LeadRequestType } from '@leads/shared/models/domain/lead-request.domain';
import {
	ScheduledAssessment,
	ScheduledAssessmentStatus
} from '@leads/shared/models/domain/scheduled-assessment.domain';

@Component({
	selector: 'app-scheduled-item-actions',
	standalone: true,
	imports: [
		NgIf,
		NgClass
	],
	templateUrl: './scheduled-item-actions.component.html',
	styleUrl: './scheduled-item-actions.component.scss'
})
export class ScheduledItemActionsComponent {
	@Input({ alias: 'scheduledItem' }) item?: ScheduledAssessment;
	// Optional
	@Input() editScheduledItem? = false;
	@Input() requestType?: LeadRequestType;
	@Input() mobile?: boolean = false;
	@Input() readonlyView? = false;
	@Input() showButtons? = true;

	@Output() removeAssessment = new EventEmitter<ScheduledAssessment | null>();
	@Output() cancelAssessment = new EventEmitter<ScheduledAssessment>();

	get canCancel(): boolean {
		if (!this.item) {
			return false;
		}
		return [
			ScheduledAssessmentStatus.REQUESTED,
			ScheduledAssessmentStatus.SCHEDULED
		].includes(this.item.status);
	}

	get canRemove(): boolean {
		if (this.editScheduledItem || !this.item) {
			return true;
		}
		return [
			ScheduledAssessmentStatus.DECLINED,
			ScheduledAssessmentStatus.CANCELLED,
		].includes(this.item.status);
	}
}
