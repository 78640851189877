import { take, takeUntil } from 'rxjs';

import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppRoutes } from '@app/app.routes';
import { IClientWithProperties } from '@clients/models/firebase/client.model';
import { ClientsService } from '@clients/services/clients/clients.service';
import { LayoutService } from '@layout/services/layout/layout.service';
import { NewScheduledItemActivity } from '@leads/client-facing/classes/base-lead-request-detail.component';
import { RejectAssessmentModalComponent } from '@leads/client-facing/components/reject-assessment-modal/reject-assessment-modal.component';
import { ILeadRequestNotesForm } from '@leads/client-facing/models/domain/lead-request.domain';
import { ISchedulerForm } from '@leads/client-facing/models/domain/scheduled-assessment.domain';
import { DesktopLeadRequestDetailComponent } from '@leads/client-facing/screens/desktop/desktop-lead-request-detail/desktop-lead-request-detail.component';
import { MobileLeadRequestDetailComponent } from '@leads/client-facing/screens/mobile/mobile-lead-request-detail/mobile-lead-request-detail.component';
import { LeadRequestType, LeadStatus } from '@leads/shared/models/domain/lead-request.domain';
import {
	ScheduledAssessment,
	ScheduledAssessmentStatus,
	ScheduledAssessmentType,
} from '@leads/shared/models/domain/scheduled-assessment.domain';
import { ILeadRequest } from '@leads/shared/models/firebase/lead-request.model';
import { LeadAssessmentService } from '@leads/shared/services/lead-assessment/lead-assessment.service';
import { LeadRequestService } from '@leads/shared/services/lead-request/lead-request.service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { LoadingStateComponent } from '@shared/components/loading-state/loading-state.component';
import { ConfirmationModalComponent } from '@shared/components/modals/confirmation-modal/confirmation-modal.component';
import { PageTitleComponent } from '@shared/components/sections/page-title/page-title.component';
import { ModalService } from '@shared/services/modal/modal.service';

@Component({
	selector: 'app-lead-assessment-request',
	standalone: true,
	imports: [PageTitleComponent, DesktopLeadRequestDetailComponent, MobileLeadRequestDetailComponent, CommonModule, LoadingStateComponent],
	templateUrl: './lead-assessment-request.component.html',
	styleUrl: './lead-assessment-request.component.scss',
})
export class LeadAssessmentRequestComponent implements OnInit, OnDestroy {
	destroyed$ = new EventEmitter<void>();
	isLoading = true;
	isSavingNotes = false;
	isUpdatingStatus = false;
	isMobileSize = true;

	leadRequestId: string;

	// Make into a domain modals
	leadRequest: ILeadRequest;
	client: IClientWithProperties;
	scheduledItems: Array<ScheduledAssessment> = [];

	readonlyView = false;
	newScheduledAssessmentActive = false;

	AppRoutes = AppRoutes;
	LeadStatus = LeadStatus;

	private layoutService = inject(LayoutService);
	private modalService = inject(ModalService);
	private leadsRequestService = inject(LeadRequestService);
	private leadAssessmentService = inject(LeadAssessmentService);
	private clientService = inject(ClientsService);
	private route = inject(ActivatedRoute);
	private router = inject(Router);

	// Leave here
	internalNotesForm: FormGroup<ILeadRequestNotesForm>;
	schedulerForm: FormGroup<ISchedulerForm>;

	ngOnInit(): void {
		this.layoutService.isMobileSize$.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
			this.isMobileSize = value;
		});

		this.internalNotesForm = new FormGroup<ILeadRequestNotesForm>({
			internalNotes: new FormControl<string | null>(null),
		});

		this.schedulerForm = new FormGroup<ISchedulerForm>({
			requestType: new FormControl<LeadRequestType | null>(LeadRequestType.ON_SITE_VISIT, [Validators.required]),
			employeeId: new FormControl<string | null>(null, [Validators.required]),
			selectedDate: new FormControl<NgbDateStruct | null>(null, [Validators.required]),
			selectedStartTime: new FormControl<Date | null>(null, [Validators.required]),
			selectedEndTime: new FormControl<Date | null>(null, [Validators.required]),
			assessments: new FormControl<Array<string> | null>([], [Validators.required]),
			assessmentType: new FormControl<ScheduledAssessmentType | null>(null, [Validators.required]),
			callOutFeeRands: new FormControl<number | null>(null, [Validators.required, Validators.min(0), Validators.max(10_000)]),
			includeCallOutFeeComms: new FormControl<boolean | null>(false, [Validators.required]),
			assessmentNotes: new FormControl<string | null>(null),
			whatsappNotes: new FormControl<string | null>(null),
		});

		this.populateLeadRequest();
	}

	ngOnDestroy(): void {
		this.destroyed$.emit();
	}

	populateLeadRequest(): void {
		this.leadRequestId = this.route.snapshot.params.leadRequestId;
		this.leadsRequestService
			.getLeadRequest(this.leadRequestId)
			.pipe(takeUntil(this.destroyed$))
			.subscribe((leadrequest: ILeadRequest | undefined) => {
				if (leadrequest === undefined) {
					this.router.navigate([AppRoutes.CLIENT_LEADS_DASHBOARD]);
				}
				this.leadRequest = leadrequest as ILeadRequest;

				this.handleReadonlyView();

				const clientId = this.leadRequest.client.path.split('/')[1];
				this.populateClient(clientId);
				this.populateScheduledItems();
				if (this.leadRequest.internalNotes) {
					this.internalNotesForm.controls.internalNotes.patchValue(this.leadRequest.internalNotes);
				}
			});
	}

	private handleReadonlyView() {
		this.readonlyView = this.leadRequest.status === LeadStatus.REJECTED;
		if (this.readonlyView) {
			this.schedulerForm.disable();
			this.internalNotesForm.disable();
		} else {
			this.schedulerForm.enable();
			this.internalNotesForm.enable();
		}
	}

	newScheduledItemActivityChanged($event: NewScheduledItemActivity) {
		this.newScheduledAssessmentActive = $event.open;
	}

	newScheduledItemCreated(): void {
		this.leadsRequestService.updateStatus(this.leadRequestId, LeadStatus.ACTIVE);
	}

	private populateScheduledItems(): void {
		this.leadAssessmentService
			.getLeadAssessmentsByLeadRequest(this.leadRequestId)
			.pipe(takeUntil(this.destroyed$))
			.subscribe((scheduledItems) => {
				this.scheduledItems = scheduledItems;
			});
	}

	private populateClient(clientId: string): void {
		this.clientService
			.getClientWithProperties(clientId)
			.pipe(takeUntil(this.destroyed$))
			.subscribe((client) => {
				if (client) {
					this.client = client;
				}
				this.isLoading = false;
			});
	}

	get rejectAssessmentEnabled(): boolean {
		const everyItemCancelled = this.scheduledItems.every((item) => item.status === ScheduledAssessmentStatus.CANCELLED);
		return everyItemCancelled && !this.newScheduledAssessmentActive;
	}

	get allAssessmentsCompleted(): boolean {
		return (
			this.scheduledItems.length > 0 &&
			this.scheduledItems.every((item) =>
				[ScheduledAssessmentStatus.COMPLETED, ScheduledAssessmentStatus.CANCELLED, ScheduledAssessmentStatus.DECLINED].includes(
					item.status,
				),
			)
		);
	}

	rejectAssessment(): void {
		const modalRef = this.modalService.open(RejectAssessmentModalComponent, false, 'md');
		modalRef.componentInstance.client = this.client;
		modalRef.componentInstance.leadRequest = this.leadRequest;
		modalRef.componentInstance.rejectAssessment.pipe(take(1)).subscribe(() => {
			this.leadsRequestService.updateStatus(this.leadRequestId, LeadStatus.REJECTED);
		});
	}

	recoverAssessment(): void {
		const modalRef = this.modalService.open(ConfirmationModalComponent, false, 'md');
		modalRef.componentInstance.title = 'Are you sure you want to recover this assessment request?';
		modalRef.componentInstance.contentText = 'The request will be marked as new.';
		modalRef.componentInstance.confirmAction.pipe(take(1)).subscribe(() => {
			this.leadsRequestService.updateStatus(this.leadRequestId, LeadStatus.NEW);
		});
	}

	completeAssessment(): void {
		const modalRef = this.modalService.open(ConfirmationModalComponent, false, 'md');
		modalRef.componentInstance.title = 'Are you sure you want to complete this assessment request?';
		modalRef.componentInstance.contentText = 'The request will be marked as Completed.';
		modalRef.componentInstance.confirmAction.pipe(take(1)).subscribe(() => {
			this.leadsRequestService.updateStatus(this.leadRequestId, LeadStatus.COMPLETED);
		});
	}

	saveNotes(): void {
		if (this.internalNotesForm.invalid) {
			return;
		}
		this.isSavingNotes = true;

		this.leadsRequestService
			.update(this.leadRequestId, {
				internalNotes: this.internalNotesForm.controls.internalNotes.value,
			})
			.finally(() => {
				this.isSavingNotes = false;
				this.internalNotesForm.markAsPristine();
			});
	}
}
