import { CalendarCommonModule, CalendarDateFormatter, CalendarView } from 'angular-calendar';
import { takeUntil } from 'rxjs';

import { NgIf, TitleCasePipe } from '@angular/common';
import { Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { LayoutService } from '@layout/services/layout/layout.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { ISearchableEmployee } from '@schedule/lib/models/employee';
import {
	calendarViewTitle,
	calendarViewTitleMobile,
	CustomDateFormatterService,
} from '@schedule/lib/services/calendar-date-formatter/custom-date-formatter.service';
import { CalendarSettings, CalendarSettingsService } from '@schedule/services/calendar/calendar-settings/calendar-settings.service';

@Component({
	selector: 'app-calendar-controls',
	standalone: true,
	imports: [CalendarCommonModule, TitleCasePipe, NgSelectModule, NgIf],
	// @Carl Duplicate here??? see: CalendarWeekHeadersComponent
	providers: [
		{
			provide: CalendarDateFormatter,
			useClass: CustomDateFormatterService,
		},
	],
	templateUrl: './calendar-controls.component.html',
	styleUrl: './calendar-controls.component.scss',
})
export class CalendarControlsComponent implements OnInit, OnDestroy {
	destroyed$ = new EventEmitter<void>();
	isMobileSize = true;
	protected calendarSettings: CalendarSettings;

	@Input() viewDate: Date = new Date();
	@Output() viewDateChange = new EventEmitter<Date>();

	@Input() calendarView: CalendarView = CalendarView.Week;
	@Output() calendarViewChange = new EventEmitter<CalendarView>();

	@Input() employees: Array<ISearchableEmployee> = [];
	@Output() selectedEmployee = new EventEmitter<ISearchableEmployee>();

	@Input() daysInWeek: number = 7;
	@Input() showEmployeeFilter: boolean = true;

	headerCalendarDateMethod = calendarViewTitle(this.calendarView);

	form: FormGroup<{ employeeId: FormControl<string | null> }>;

	protected readonly CalendarView = CalendarView;

	private calendarSettingsService = inject(CalendarSettingsService);
	private layoutService = inject(LayoutService);

	ngOnInit(): void {
		this.calendarSettings = this.calendarSettingsService.getDefaultSettings();

		this.form = new FormGroup({
			employeeId: new FormControl<string | null>(null),
		});

		this.layoutService.isMobileSize$.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
			this.isMobileSize = value;
			this.headerCalendarDateMethod = this.isMobileSize
				? calendarViewTitleMobile(this.calendarView)
				: calendarViewTitle(this.calendarView);
		});
	}

	ngOnDestroy(): void {
		this.destroyed$.emit();
	}
}
