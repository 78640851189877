<div class="accordion-item">
	<span class="accordion-header d-flex flex-row">
		<button
			#accordionButton
			class="accordion-button"
			[ngStyle]="{ 'line-height.px': lineHeight }"
			[class.collapsed]="!isOpen"
			[class.disable-expand]="disableExpand"
			type="button"
			[attr.data-bs-toggle]="disableExpand ? null : 'collapse'"
			[attr.data-bs-target]="disableExpand ? null : '#' + itemId"
			[attr.aria-expanded]="isOpen"
			[attr.aria-controls]="itemId"
			(click)="toggleAccordion()"
		>
			<ng-content select="[header]"></ng-content>
		</button>
		<span
			class="accordion-actions"
			[ngStyle]="{ 'padding-top.px': buttonPaddingY, 'padding-bottom.px': buttonPaddingY }"
			[class.collapsed]="!isOpen"
		>
			<ng-content select="[actions]"></ng-content>
		</span>
	</span>

	<div
		[id]="itemId"
		class="accordion-collapse collapse"
		[class.show]="isOpen"
		[attr.data-bs-parent]="'#' + parentId"
		(shown.bs.collapse)="onShow()"
		(hidden.bs.collapse)="onClose()"
	>
		<div class="accordion-body">
			<ng-content select="[body]" class="body"></ng-content>
		</div>
	</div>
</div>
