import { combineLatest, map, Observable, of, switchMap } from 'rxjs';

import { inject, Injectable } from '@angular/core';
import { collectionData, doc, docData, DocumentReference, getDocs, query, QuerySnapshot, Timestamp, where } from '@angular/fire/firestore';
import { AuthService } from '@auth/services/auth.service';
import { ORGANISATION_COST_PER_USER_PER_MONTH } from '@organisations/classes/consts';
import {
	IAvailableOrganisation,
	IOrganisationBill,
	IOrganisationUser,
	IOrganisationUserWithUserName,
	OrganisationMemberStatus,
	OrganisationUserAccessLevel,
} from '@organisations/models/organisation-user';
import { IOrganisation } from '@organisations/models/organisations';
import { FireStoreCollection } from '@shared/models/firestore';
import { EmailService } from '@shared/services/email/email.service';
import { FirestoreService } from '@shared/services/firestore/firestore.service';
import { IUser } from '@user/models/user';

@Injectable({
	providedIn: 'root',
})
export class OrganisationUserService extends FirestoreService<IOrganisationUser> {
	private authService = inject(AuthService);
	private emailService = inject(EmailService);

	constructor() {
		super(FireStoreCollection.ORGANISATION_USER);
	}

	organisationsByCurrentUser(): Observable<Array<IOrganisationUser>> {
		return this.authService.currentUser$.pipe(
			switchMap((user) => {
				const userRef = doc(this.firestore, `${FireStoreCollection.USERS}/${user?.uid}`);
				const q = query(this.collectionRef, where('user', '==', userRef), where('status', '==', OrganisationMemberStatus.ACTIVE));
				return collectionData(q, { idField: 'id' });
			}),
		);
	}

	availableOrganisations(): Observable<Array<IAvailableOrganisation>> {
		return this.organisationsByCurrentUser().pipe(
			switchMap((organisationUsers: Array<IOrganisationUser>) => {
				if (organisationUsers.length === 0) {
					return of([]);
				}
				const organisationUserWithOrgData$ = organisationUsers.map((organisationUser) => {
					const organisationDocRef = organisationUser.organisation as DocumentReference<IOrganisation, IOrganisation>;
					const organisationData$ = docData(organisationDocRef);
					return organisationData$.pipe(
						map((organisationData) => ({
							...organisationUser,
							companyName: organisationData?.companyName,
						})),
					);
				});
				return combineLatest(organisationUserWithOrgData$);
			}),
		);
	}

	visibleUsersByOrganisation(orgId: string): Observable<Array<IOrganisationUser>> {
		const organisationRef = doc(this.firestore, `${FireStoreCollection.ORGANISATIONS}/${orgId}`);
		const q = query(
			this.collectionRef,
			where('organisation', '==', organisationRef),
			where('status', 'in', [
				OrganisationMemberStatus.ACTIVE,
				OrganisationMemberStatus.INVITATION_PENDING,
				OrganisationMemberStatus.DECLINED,
			]),
		);
		return collectionData(q, { idField: 'id' });
	}

	activeUsersByOrganisationRef(organisationRef: DocumentReference): Observable<Array<IOrganisationUserWithUserName>> {
		const q = query(
			this.collectionRef,
			where('organisation', '==', organisationRef),
			where('status', '==', OrganisationMemberStatus.ACTIVE),
		);
		return collectionData(q, { idField: 'id' }).pipe(
			switchMap((organisationUsers: Array<IOrganisationUser>) => {
				if (organisationUsers.length === 0) {
					return of([]);
				}
				const organisationUserWithUserData$ = organisationUsers.map((organisationUser) => {
					const organisationUserRef = organisationUser.user as DocumentReference<IUser, IUser>;
					const userData$ = docData(organisationUserRef, { idField: 'id' });
					return userData$.pipe(
						map((userData) => ({
							...organisationUser,
							userDisplayName: userData?.displayName,
							userId: userData?.id,
						})),
					);
				});
				return combineLatest(organisationUserWithUserData$);
			}),
		);
	}

	async inviteUserToOrganisation(
		email: string,
		accessLevel: OrganisationUserAccessLevel,
		organisationId: string,
		organisationName: string,
	): Promise<DocumentReference<IOrganisationUser> | null> {
		email = email.toLocaleLowerCase();
		try {
			const orgUserExists = await this.organisationUserExistsForEmail(email, organisationId);
			if (!orgUserExists) {
				const result = await this.create({
					email: email,
					accessLevel: accessLevel,
					createdDate: Timestamp.now(),
					updatedDate: Timestamp.now(),
					joinDate: null,
					removeDate: null,
					organisation: doc(this.firestore, `${FireStoreCollection.ORGANISATIONS}/${organisationId}`),
					status: OrganisationMemberStatus.INVITATION_PENDING,
				});

				if (result) {
					const emailResult = await this.emailService.inviteUserToOrganisation(email, organisationName);
				}
				return result;
			}
			return null;
		} catch (error: any) {
			return null;
		}
	}

	async removeUserFromOrganisation(organisationUserId: string) {
		try {
			const result = await super.update(organisationUserId, {
				status: OrganisationMemberStatus.REMOVED,
				updatedDate: Timestamp.now(),
				removeDate: Timestamp.now(),
			});
			return result;
		} catch (error: any) {
			return null;
		}
	}

	async organisationUserExistsForEmail(email: string, organisationId: string): Promise<boolean> {
		const organisationRef = doc(this.firestore, `${FireStoreCollection.ORGANISATIONS}/${organisationId}`);
		const q = query(
			this.collectionRef,
			where('organisation', '==', organisationRef),
			where('email', '==', email),
			where('status', 'in', [OrganisationMemberStatus.ACTIVE, OrganisationMemberStatus.INVITATION_PENDING]),
		);
		const snapshot = await getDocs(q);

		return !snapshot.empty;
	}

	invitationsByCurrentUser(): Observable<Array<IOrganisationUser>> {
		const user = this.authService.currentUser;
		const q = query(
			this.collectionRef,
			where('email', '==', user?.email),
			where('status', '==', OrganisationMemberStatus.INVITATION_PENDING),
		);
		return collectionData(q, { idField: 'id' });
	}

	organisationInvitations(): Observable<Array<IAvailableOrganisation>> {
		return this.invitationsByCurrentUser().pipe(
			switchMap((organisationUsers: Array<IOrganisationUser>) => {
				if (organisationUsers.length === 0) {
					return of([]);
				}
				const organisationUserWithOrgData$ = organisationUsers.map((organisationUser) => {
					const organisationDocRef = organisationUser.organisation as DocumentReference<IOrganisation, IOrganisation>;
					const organisationData$ = docData(organisationDocRef);
					return organisationData$.pipe(
						map((organisationData) => ({
							...organisationUser,
							companyName: organisationData?.companyName,
						})),
					);
				});
				return combineLatest(organisationUserWithOrgData$);
			}),
		);
	}

	acceptInvitation(organisationUser: IAvailableOrganisation) {
		const user = this.authService.currentUser;
		if (organisationUser.id) {
			return this.update(organisationUser.id, {
				status: OrganisationMemberStatus.ACTIVE,
				updatedDate: Timestamp.now(),
				joinDate: Timestamp.now(),
				user: doc(this.firestore, `${FireStoreCollection.USERS}/${user?.uid}`),
			});
		}
		return null;
	}

	rejectInvitation(organisationUser: IAvailableOrganisation) {
		if (organisationUser.id) {
			return this.update(organisationUser.id, {
				status: OrganisationMemberStatus.DECLINED,
				updatedDate: Timestamp.now(),
			});
		}
		return null;
	}

	async resendOrganisationInvitation(
		organisationUserId: string,
		email: string,
		organisationName: string,
	): Promise<DocumentReference | null> {
		try {
			const result = await this.update(organisationUserId, {
				updatedDate: Timestamp.now(),
				status: OrganisationMemberStatus.INVITATION_PENDING,
			});
			const emailResult = await this.emailService.inviteUserToOrganisation(email, organisationName);
			return emailResult;
		} catch (error: any) {
			return null;
		}
	}

	async ownersForOrganisation(organisationId: string): Promise<QuerySnapshot<IOrganisationUser>> {
		const organisationRef = doc(this.firestore, `${FireStoreCollection.ORGANISATIONS}/${organisationId}`);
		const q = query(
			this.collectionRef,
			where('organisation', '==', organisationRef),
			where('accessLevel', '==', OrganisationUserAccessLevel.OWNER),
			where('status', '==', OrganisationMemberStatus.ACTIVE),
		);
		const result = await getDocs(q);
		return result;
	}

	async updateUserAccessLevel(organisationId: string, organisationUserId: string, accessLevel: OrganisationUserAccessLevel) {
		try {
			const owners = await this.ownersForOrganisation(organisationId);

			if (owners.size <= 1 && accessLevel !== OrganisationUserAccessLevel.OWNER) {
				return false;
			}

			await this.update(organisationUserId, {
				accessLevel: accessLevel,
				updatedDate: Timestamp.now(),
			});

			return true;
		} catch (error: any) {
			return false;
		}
	}

	billableUsersByOrganisation(organisationRef: DocumentReference, month: number, year: number): Observable<IOrganisationBill> {
		const startOfMonth = new Date(year, month - 1, 1);
		const endOfMonth = new Date(year, month, 0);

		const activeUsersQuery = query(
			this.collectionRef,
			where('organisation', '==', organisationRef),
			where('status', 'in', ['active', 'currently logged in']),
			where('joinDate', '<=', Timestamp.fromDate(endOfMonth)),
		);

		const removedUsersQuery = query(
			this.collectionRef,
			where('organisation', '==', organisationRef),
			where('status', '==', 'removed'),
			where('joinDate', '<=', Timestamp.fromDate(endOfMonth)),
			where('removeDate', '>=', Timestamp.fromDate(startOfMonth)),
			where('removeDate', '<=', Timestamp.fromDate(endOfMonth)),
		);

		const activeUsers$ = collectionData(activeUsersQuery, { idField: 'id' });
		const removedUsers$ = collectionData(removedUsersQuery, { idField: 'id' });

		return combineLatest([activeUsers$, removedUsers$]).pipe(
			map(([activeUsers, removedUsers]) => {
				const users = [...activeUsers, ...removedUsers];

				const invoices = users.map((user) => {
					const joinDate = user.joinDate ? user.joinDate.toDate() : startOfMonth;
					const effectiveJoinDate = joinDate < startOfMonth ? startOfMonth : joinDate;

					const removeDate = user.status === 'removed' && user.removeDate ? user.removeDate.toDate() : endOfMonth;
					const effectiveRemoveDate = removeDate > endOfMonth ? endOfMonth : removeDate;

					const daysActive = Math.max(
						(effectiveRemoveDate.getTime() - effectiveJoinDate.getTime()) / (1000 * 60 * 60 * 24) + 1,
						0,
					);
					const daysInMonth = endOfMonth.getDate();
					const billingAmount = (daysActive / daysInMonth) * ORGANISATION_COST_PER_USER_PER_MONTH;

					return {
						email: user.email,
						billingAmount: billingAmount,
						joinDate: user.joinDate?.toDate(),
						removeDate: user.removeDate?.toDate(),
						daysActive: daysActive,
						status: user.status,
					};
				});

				const totalAmount = invoices.reduce((sum, invoice) => sum + invoice.billingAmount, 0);

				return { invoices, totalAmount };
			}),
		);
	}
}
