import { CalendarEvent, CalendarView } from 'angular-calendar';
import { Subject } from 'rxjs';

import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { LeadRequestType } from '@leads/shared/models/domain/lead-request.domain';
import { CalendarEvents } from '@schedule/services/calendar/calendar.service';

@Component({
	template: '',
})
export abstract class BaseCalendarView {
	@Input() isLoading: boolean;
	@Input() view: CalendarView;
	@Output() viewChanged = new EventEmitter<CalendarView>();

	@Input() viewDate: Date = new Date();
	@Output() viewDateChange = new EventEmitter<Date>();

	@Input() events: CalendarEvents = [];
	@Input() refresh = new Subject<void>();

	@Output() dayClicked = new EventEmitter<{ date: Date; events: CalendarEvent[] }>();
	@Output() eventClicked = new EventEmitter<CalendarEvent>();

	daysInWeek = 7;

	@ViewChild('modalContent', { static: true }) modalContent: TemplateRef<unknown>;
	activeDayIsOpen: boolean = true;

	protected readonly CalendarView = CalendarView;
	protected readonly LeadRequestType = LeadRequestType;
}
