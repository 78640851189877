<div class="popover-container">
	<div class="popover-head">
		<div class="event-title">{{ calendarEvent.title }}</div>
		<button class="btn-close" aria-label="Close" (click)="closed.emit()"></button>
	</div>
	<div class="popover-body2">
		<div class="event-detail-col" *ngIf="showProperty('customer_name')">
			<div class="detail-value">
				<span class="event-type badge bg-primary">{{ eventTypeLabel }}</span>
			</div>
		</div>
		<div class="event-detail-col" *ngIf="showProperty('date_and_time')">
			<div class="detail-label">Date & Time</div>
			<div class="event-detail-row">
				<div class="detail-value">{{ dateTimeRange }}</div>
			</div>
		</div>
		<div class="event-detail-col" *ngIf="showProperty('customer_name')">
			<div class="detail-label">Customer</div>
			<div class="detail-value">{{ scheduledEvent.customerName | nullDefault: 'N/A' }}</div>
		</div>
		<div class="event-detail-col" *ngIf="showProperty('customer_property_address')">
			<div class="detail-label">Property Address</div>
			<div class="detail-value">
				<app-link-location [property]="scheduledEvent.customerProperty" [maxCharacters]="50"></app-link-location>
			</div>
		</div>
		<div class="event-detail-col" *ngIf="showProperty('customer_number')">
			<div class="detail-label">Contact Number</div>
			<div class="detail-value">{{ scheduledEvent.customerNumber | nullDefault: 'N/A' }}</div>
		</div>
		<div class="event-detail-col" *ngIf="showProperty('employee_name')">
			<div class="detail-label">Person in charge</div>
			<div class="detail-value">{{ scheduledEvent.employeeName | nullDefault: 'N/A' }}</div>
		</div>
		<div class="event-detail-col" *ngIf="showProperty('job_type')">
			<div class="detail-label">Job Type</div>
			<div class="detail-value">
				{{ (scheduledEvent.serviceTypes.length ? scheduledEvent.serviceTypes.join(', ') : []) | nullDefault: 'N/A' }}
			</div>
		</div>
		<div class="event-detail-col" *ngIf="showProperty('status')">
			<div class="detail-label">{{ jobStatusLabel }}</div>
			<div class="detail-value job-status-dropdown">
				<ng-container *ngIf="isOwner; else employee">
					<app-assessment-status-dropdown
						[(status)]="scheduledAssessmentStatus"
						(statusChange)="statusChange($event)"
						[statusOptions]="scheduledAssessmentStatusOptions"
					></app-assessment-status-dropdown>
				</ng-container>
				<ng-template #employee>
					{{ ScheduledAssessmentStatusLabels[scheduledAssessmentStatus] }}
				</ng-template>
			</div>
		</div>
		<div class="event-detail-col" *ngIf="showProperty('description')">
			<div class="detail-label">Description</div>
			<div class="detail-value">{{ scheduledEvent.description | nullDefault: 'N/A' }}</div>
		</div>
	</div>
	<div class="popover-actions">
		<ng-container *ngFor="let action of calendarEvent.actions">
			<button class="action-button btn btn-sm btn-primary" (click)="onActionClick(action, $event)">
				<i class="action-icon bi {{ action.cssClass }}"></i>
				{{ action.label }}
			</button>
		</ng-container>
	</div>
</div>
