import {
	CalendarCommonModule,
	CalendarDateFormatter,
	CalendarMonthModule,
	CalendarWeekModule,
	ɵCalendarWeekViewHeaderComponent as CalendarWeekViewHeaderComponent,
} from 'angular-calendar';
import { CalendarEvent, WeekDay } from 'calendar-utils';
import { takeUntil } from 'rxjs';

import { NgClass, NgForOf, NgTemplateOutlet } from '@angular/common';
import { Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output, TemplateRef } from '@angular/core';
import { LayoutService } from '@layout/services/layout/layout.service';
import {
	CustomDateFormatterService,
	weekViewColumnHeader,
	weekViewColumnHeaderMobile,
} from '@schedule/lib/services/calendar-date-formatter/custom-date-formatter.service';

@Component({
	selector: 'app-calendar-week-headers',
	standalone: true,
	imports: [CalendarMonthModule, CalendarWeekModule, NgClass, CalendarCommonModule, NgTemplateOutlet, NgForOf],
	providers: [
		{
			provide: CalendarDateFormatter,
			useClass: CustomDateFormatterService,
		},
	],
	templateUrl: './calendar-week-headers.component.html',
	styleUrl: './calendar-week-headers.component.scss',
})
export class CalendarWeekHeadersComponent extends CalendarWeekViewHeaderComponent implements OnInit, OnDestroy {
	destroyed$ = new EventEmitter<void>();
	isMobileSize = true;
	private layoutService = inject(LayoutService);

	headerCalendarDateMethod = weekViewColumnHeader;

	@Input() override days: WeekDay[];

	@Input() override locale: string;

	// Don't allow the custom template
	override customTemplate: TemplateRef<any>;

	@Input() override trackByWeekDayHeaderDate: (index: number, day: WeekDay) => string;

	@Output() override dayHeaderClicked = new EventEmitter<{
		day: WeekDay;
		sourceEvent: MouseEvent;
	}>();

	@Output() override eventDropped = new EventEmitter<{
		event: CalendarEvent;
		newStart: Date;
	}>();

	@Output() override dragEnter = new EventEmitter<{ date: Date }>();

	ngOnInit(): void {
		this.layoutService.isMobileSize$.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
			this.isMobileSize = value;
			this.headerCalendarDateMethod = this.isMobileSize ? weekViewColumnHeaderMobile : weekViewColumnHeader;
		});
	}

	ngOnDestroy(): void {
		this.destroyed$.emit();
	}
}
