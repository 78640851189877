import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { BaseLeadRequestDetail } from '@leads/client-facing/classes/base-lead-request-detail.component';
import { ScheduledItemActionsComponent } from '@leads/client-facing/components/scheduled-item-actions/scheduled-item-actions.component';
import { ScheduledItemTitleComponent } from '@leads/client-facing/components/scheduled-item-title/scheduled-item-title.component';
import { DesktopScheduleItemCreateComponent } from '@leads/client-facing/screens/desktop/desktop-scheduled-item-create/desktop-schedule-item-create.component';
import { DesktopScheduledItemDetailComponent } from '@leads/client-facing/screens/desktop/desktop-scheduled-item-detail/desktop-scheduled-item-detail.component';
import { ScheduledAssessment } from '@leads/shared/models/domain/scheduled-assessment.domain';
import { AlertComponent } from '@shared/components/alerts/alert/alert.component';
import { LinkLocationComponent } from '@shared/components/buttons/link-location/link-location.component';
import { TextareaControlComponent } from '@shared/components/forms/textarea-control/textarea-control.component';
import { AccordionItemComponent } from '@shared/components/ui/accordion-item/accordion-item.component';
import { AccordionComponent } from '@shared/components/ui/accordion/accordion.component';
import { NgbDateToDatePipe } from '@shared/pipes/ngb-date-to-date.pipe';

@Component({
	selector: 'app-desktop-lead-request-detail',
	standalone: true,
	imports: [
		FormsModule,
		ReactiveFormsModule,
		DesktopScheduleItemCreateComponent,
		DesktopScheduledItemDetailComponent,
		CommonModule,
		RouterLink,
		NgbDateToDatePipe,
		TextareaControlComponent,
		AccordionComponent,
		AccordionItemComponent,
		AlertComponent,
		ScheduledItemActionsComponent,
		ScheduledItemTitleComponent,
		LinkLocationComponent,
	],
	templateUrl: './desktop-lead-request-detail.component.html',
	styleUrl: './desktop-lead-request-detail.component.scss',
})
export class DesktopLeadRequestDetailComponent extends BaseLeadRequestDetail {
	accordionState: { [key: number]: boolean } = {};

	isAccordionOpen(index: number): boolean {
		return this.accordionState[index] ?? false;
	}

	toggleAccordion(index: number, isOpen: boolean): void {
		Object.keys(this.accordionState).forEach((key) => (this.accordionState[Number(key)] = false));
		if (isOpen) {
			this.accordionState[index] = true;
		}
	}

	protected override addAssessment(assessment: ScheduledAssessment): void {
		this.clearActiveScheduledItem();
		this.toggleAccordion(0, true);
		this.newScheduledItemCreated.emit();
	}
}
