import {
	CalendarCommonModule,
	CalendarEvent,
	CalendarEventTimesChangedEvent,
	CalendarMonthModule,
	CalendarMonthViewDay,
} from 'angular-calendar';
import { Subject, takeUntil } from 'rxjs';

import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { LayoutService, ScreenSize } from '@layout/services/layout/layout.service';
import { CalendarMonthDayCellComponent } from '@schedule/lib/components/calendar-month-day-cell/calendar-month-day-cell.component';
import { CalendarMonthHeadersComponent } from '@schedule/lib/components/calendar-month-headers/calendar-month-headers.component';
import { CalendarColumnHeaderClickedEvent } from '@schedule/lib/types/calendar.types';

@Component({
	selector: 'app-calendar-month-view',
	standalone: true,
	imports: [CommonModule, CalendarCommonModule, CalendarMonthModule, CalendarMonthDayCellComponent, CalendarMonthHeadersComponent],
	templateUrl: './calendar-month-view.component.html',
	styleUrl: './calendar-month-view.component.scss',
})
export class CalendarMonthViewComponent implements OnInit, OnDestroy {
	private layoutService = inject(LayoutService);
	destroyed$ = new EventEmitter<void>();
	protected screenSize: ScreenSize;

	@Input() isOwner: boolean = false;
	@Input() events: CalendarEvent[];
	@Input() refresh: Subject<void>;
	@Input() viewDate: Date;
	@Input() activeDayIsOpen: boolean;
	@Output() dayClicked = new EventEmitter<CalendarMonthViewDay>();
	@Output() eventClicked = new EventEmitter<CalendarEvent>();
	@Output() eventTimesChanged = new EventEmitter<CalendarEventTimesChangedEvent>();
	@Output() columnHeaderClicked = new EventEmitter<CalendarColumnHeaderClickedEvent>();

	ngOnInit(): void {
		this.layoutService.isMobileSize$.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
			this.screenSize = value ? ScreenSize.Mobile : ScreenSize.Desktop;
		});
	}

	ngOnDestroy(): void {
		this.destroyed$.emit();
	}

	handleDayClicked(day: CalendarMonthViewDay): void {
		this.dayClicked.emit(day);
	}

	handleEventClicked(event: CalendarEvent): void {
		this.eventClicked.emit(event);
	}

	handleEventTimesChanged(event: CalendarEventTimesChangedEvent): void {
		this.eventTimesChanged.emit(event);
	}

	handleColumnHeaderClicked(event: CalendarColumnHeaderClickedEvent): void {
		this.columnHeaderClicked.emit(event);
	}
}
