import { FormControl } from '@angular/forms';
import { LeadRequestType } from '@leads/shared/models/domain/lead-request.domain';
import { ScheduledAssessmentStatus, ScheduledAssessmentType } from '@leads/shared/models/domain/scheduled-assessment.domain';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { IDropdownOption } from '@shared/components/forms/dropdown-control/dropdown-control.component';

export enum AssessmentScheduleSteps {
	ASSIGN_EMPLOYEE = 'assign-employee',
	ASSIGNMENT_DETAILS = 'assignment-details',
	COMMUNICATIONS = 'communications',
}

export enum AssessmentScheduleViewSteps {
	ASSESSMENT_OVERVIEW = 'assessment-overview',
	COMMUNICATIONS = 'communications',
}

export interface ISchedulerForm {
	requestType: FormControl<LeadRequestType | null>;
	employeeId: FormControl<string | null>;
	selectedDate: FormControl<NgbDateStruct | null>;
	selectedStartTime: FormControl<Date | null>;
	selectedEndTime: FormControl<Date | null>;
	assessments?: FormControl<Array<string> | null>;
	assessmentType?: FormControl<ScheduledAssessmentType | null>;
	callOutFeeRands?: FormControl<number | null>;
	includeCallOutFeeComms?: FormControl<boolean | null>;
	assessmentNotes: FormControl<string | null>;
	whatsappNotes: FormControl<string | null>;
}

export const leadAssessmentStatusOptions: Array<IDropdownOption> = [
	{
		id: 'requested',
		icon: 'bi-calendar-check',
		value: ScheduledAssessmentStatus.REQUESTED,
		label: 'Requested',
	},
	{
		id: 'scheduled',
		icon: 'bi-calendar-check',
		value: ScheduledAssessmentStatus.SCHEDULED,
		label: 'Customer Scheduled',
	},
	{
		id: 'declined',
		icon: 'bi-calendar-check',
		value: ScheduledAssessmentStatus.DECLINED,
		label: 'Customer Declined',
	},
	{
		id: 'cancelled',
		icon: 'bi-calendar-check',
		value: ScheduledAssessmentStatus.CANCELLED,
		label: 'Cancelled',
	},
	{
		id: 'awaiting-invoice',
		icon: 'bi-hourglass-split',
		value: ScheduledAssessmentStatus.AWAITING_INVOICE,
		label: 'Awaiting Invoice',
	},
	{
		id: 'invoiced',
		icon: 'bi-receipt',
		value: ScheduledAssessmentStatus.INVOICED,
		label: 'Invoiced',
	},
	{
		id: 'partially-paid',
		icon: 'bi-check',
		value: ScheduledAssessmentStatus.PARTIALLY_PAID,
		label: 'Partially Paid',
	},
	{
		id: 'completed',
		icon: 'bi-check-circle',
		value: ScheduledAssessmentStatus.COMPLETED,
		label: 'Completed',
	},
];
