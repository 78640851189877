import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IClientWithProperties } from '@clients/models/firebase/client.model';
import { ILeadRequest } from '@leads/shared/models/firebase/lead-request.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-reject-assessment-modal',
	standalone: true,
	imports: [CommonModule, FormsModule],
	templateUrl: './reject-assessment-modal.component.html',
	styleUrl: './reject-assessment-modal.component.scss',
})
export class RejectAssessmentModalComponent implements OnInit {
	@Input() client: IClientWithProperties;
	@Input() leadRequest: ILeadRequest;
	@Output() rejectAssessment = new EventEmitter<void>();

	private activeModal = inject(NgbActiveModal);

	contentText: string;

	ngOnInit(): void {
		this.contentText = `Hi ${this.client.clientName},\n
Thank you for reaching out to us regarding your ${this.leadRequest.requestType} request. Unfortunately, we are fully booked at the moment and won't be able to assist with your request.\n
Thank you for considering us, and we hope to assist you in the future!`;
	}

	closeModal(): void {
		this.activeModal.close();
	}

	reject(): void {
		this.rejectAssessment.emit();
		this.closeModal();
	}

	rejectAndShare(): void {
		this.shareToWhatsApp();
		this.reject();
	}

	shareToWhatsApp(): void {
		window.open(
			`https://wa.me/${this.client.clientNumber.countryCode}${this.client.clientNumber.number}?text=${this.contentText.replaceAll('\n', '%0A')}`,
			'_blank',
		);
	}
}
