<div class="modal-header">
	<h5 class="modal-title">Installation Address</h5>
	<button type="button" class="btn-close" (click)="closeModal()" aria-label="Close"></button>
</div>
<div class="modal-body">
	<h5 class="text-primary mb-3">Search for property</h5>

	<app-google-places-autocomplete (placeSelected)="onPlaceSelected($event)"></app-google-places-autocomplete>

	<h5 class="text-primary mt-3">Enter property manually</h5>

	<form [formGroup]="propertyForm" class="body">
		<div class="row-group">
			<app-standard-control
				class="control"
				type="text"
				name="fullAddress"
				[formGroup]="propertyForm"
				label="Full Address"
				placeholder="Full Address"
				autocomplete="address-level1"
			></app-standard-control>

			<app-standard-control
				class="control"
				type="text"
				name="suburb"
				[formGroup]="propertyForm"
				label="Suburb"
				placeholder="Suburb"
			></app-standard-control>
		</div>

		<div class="row-group">
			<app-standard-control
				class="control"
				type="text"
				name="city"
				[formGroup]="propertyForm"
				label="City"
				placeholder="City"
			></app-standard-control>

			<app-standard-control
				class="control"
				type="text"
				name="province"
				[formGroup]="propertyForm"
				label="Province"
				placeholder="Province"
			></app-standard-control>
		</div>

		<div class="row-group">
			<app-standard-control
				class="control"
				type="text"
				name="country"
				[formGroup]="propertyForm"
				label="Country"
				placeholder="Country"
			></app-standard-control>

			<app-standard-control
				class="control"
				type="text"
				name="postalCode"
				[formGroup]="propertyForm"
				label="Postal Code"
				placeholder="Postal Code"
			></app-standard-control>
		</div>
	</form>
</div>
<div class="modal-footer">
	<ng-container *ngIf="showRemove">
		<button type="button" [disabled]="propertyForm.invalid" class="btn btn-sm btn-danger btn-width" (click)="remove()" data-id="submit">
			Remove Property
		</button>
	</ng-container>
	<button type="button" [disabled]="propertyForm.invalid" class="btn btn-sm btn-primary btn-width" (click)="submit()" data-id="submit">
		Save Property
	</button>
</div>
