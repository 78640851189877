import { take, takeUntil } from 'rxjs';

import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, OnDestroy, OnInit } from '@angular/core';
import { DocumentReference } from '@angular/fire/firestore';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppRoutes } from '@app/app.routes';
import { LeadAssessmentScheduledItemComponent } from '@leads/customer-facing/components/lead-assessment-scheduled-item/lead-assessment-scheduled-item.component';
import { LeadAssessmentId } from '@leads/customer-facing/models/domain/lead-assessment.domain';
import { LeadRequestType, LeadStatus } from '@leads/shared/models/domain/lead-request.domain';
import { ScheduledAssessment, ScheduledAssessmentStatus } from '@leads/shared/models/domain/scheduled-assessment.domain';
import { ILeadRequest } from '@leads/shared/models/firebase/lead-request.model';
import { LeadAssessmentService } from '@leads/shared/services/lead-assessment/lead-assessment.service';
import { LeadRequestService } from '@leads/shared/services/lead-request/lead-request.service';
import { IOrganisation } from '@organisations/models/organisations';
import { OrganisationService } from '@organisations/services/organisation/organisation.service';
import { AlertComponent } from '@shared/components/alerts/alert/alert.component';
import { LinkLocationComponent } from '@shared/components/buttons/link-location/link-location.component';
import { LoadingStateComponent } from '@shared/components/loading-state/loading-state.component';
import { ConfirmationModalComponent } from '@shared/components/modals/confirmation-modal/confirmation-modal.component';
import { PageTitleComponent } from '@shared/components/sections/page-title/page-title.component';
import { AlertTypes } from '@shared/models/alert';
import { NgbDateToDatePipe } from '@shared/pipes/ngb-date-to-date.pipe';
import { ModalService } from '@shared/services/modal/modal.service';

@Component({
	selector: 'app-lead-request-view',
	standalone: true,
	imports: [
		CommonModule,
		PageTitleComponent,
		FormsModule,
		ReactiveFormsModule,
		AlertComponent,
		LoadingStateComponent,
		LeadAssessmentScheduledItemComponent,
		LinkLocationComponent,
	],
	templateUrl: './lead-request-view.component.html',
	styleUrl: './lead-request-view.component.scss',
	providers: [NgbDateToDatePipe],
})
export class LeadRequestViewComponent implements OnInit, OnDestroy {
	private route = inject(ActivatedRoute);
	private router = inject(Router);
	private modalService = inject(ModalService);

	private organisationService = inject(OrganisationService);
	private leadRequestService = inject(LeadRequestService);
	private leadAssessmentService = inject(LeadAssessmentService);

	destroyed$ = new EventEmitter<void>();
	isLoading = true;
	isSaving = false;

	leadRequestId: string;
	leadRequest: ILeadRequest;
	leadAssessments: Array<ScheduledAssessment> = [];

	organisation: IOrganisation;

	protected readonly LeadStatus = LeadStatus;
	protected readonly LeadRequestType = LeadRequestType;
	protected readonly ScheduledAssessmentStatus = ScheduledAssessmentStatus;
	protected readonly AlertTypes = AlertTypes;

	notesForm: FormGroup = new FormGroup({
		callNotes: new FormControl<string | null>(null),
		onsiteNotes: new FormControl<string | null>(null),
	});

	ngOnInit(): void {
		this.populateLeadRequest();
	}

	private populateLeadRequest() {
		this.leadRequestId = this.route.snapshot.params.leadRequestId;

		this.leadRequestService
			.getLeadRequest(this.leadRequestId)
			.pipe(takeUntil(this.destroyed$))
			.subscribe((leadRequest: ILeadRequest | undefined) => {
				if (leadRequest === undefined) {
					this.router.navigate([AppRoutes.NOT_FOUND]);
				}
				this.leadRequest = leadRequest as ILeadRequest;
				if (this.leadRequest.callAssessment?.callNotes) {
					this.notesForm.controls.callNotes.setValue(this.leadRequest.callAssessment.callNotes);
				}
				if (this.leadRequest.onSiteVisitAssessment?.notes) {
					this.notesForm.controls.onsiteNotes.setValue(this.leadRequest.onSiteVisitAssessment.notes);
				}

				this.populateLeadAssessments();
				this.populateOrganisation();
			});
	}

	private populateLeadAssessments() {
		this.leadAssessmentService
			.getLeadAssessmentsByLeadRequest(this.leadRequestId)
			.pipe(takeUntil(this.destroyed$))
			.subscribe((leadAssessments) => {
				this.leadAssessments = leadAssessments;
			});
	}

	private populateOrganisation() {
		return this.organisationService
			.getOrganisation(this.leadRequest.organisation as DocumentReference<IOrganisation>)
			.pipe(takeUntil(this.destroyed$))
			.subscribe((organisation) => {
				this.organisation = organisation!;
				this.isLoading = false;
			});
	}

	ngOnDestroy(): void {
		this.destroyed$.emit();
	}

	updateCallNotes(): void {
		if (this.notesForm.controls.callNotes.invalid) {
			return;
		}
		this.isSaving = true;
		this.leadRequestService
			.update(this.leadRequestId, {
				'callAssessment.callNotes': this.notesForm.controls.callNotes.value as string,
			})
			.then(() => {
				this.isSaving = false;
				this.notesForm.controls.callNotes.markAsPristine();
			});
	}

	updateOnSiteNotes(): void {
		if (this.notesForm.controls.onsiteNotes.invalid) {
			return;
		}
		this.isSaving = true;
		this.leadRequestService
			.update(this.leadRequestId, {
				'onSiteVisitAssessment.notes': this.notesForm.controls.onsiteNotes.value as string,
			})
			.then(() => {
				this.isSaving = false;
				this.notesForm.controls.onsiteNotes.markAsPristine();
			});
	}

	acceptAppointment(leadAssessmentId: LeadAssessmentId) {
		this.leadAssessmentService.acceptAppointment(leadAssessmentId.id);
	}

	declineAppointment(leadAssessmentId: LeadAssessmentId): void {
		const modalRef = this.modalService.open(ConfirmationModalComponent, false, 'md');
		modalRef.componentInstance.title = 'Are you sure you want to decline this appointment request?';
		modalRef.componentInstance.contentText = 'You will not be able to recover this appointment request.';
		modalRef.componentInstance.confirmAction.pipe(take(1)).subscribe(() => {
			this.leadAssessmentService.declineAppointment(leadAssessmentId.id);
		});
	}
}
