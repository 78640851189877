import { filter, takeUntil } from 'rxjs';

import { CommonModule } from '@angular/common';
import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	inject,
	Input,
	OnDestroy,
	OnInit,
	Output,
	QueryList,
	ViewChildren,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterLink, RouterLinkActive } from '@angular/router';
import { LayoutService } from '@layout/services/layout/layout.service';
import { IStepNavigation, IStepNavigatorConfig, NavButtonStyle, StepModes, StepUI } from '@shared/models/step-navigator';

@Component({
	selector: 'app-step-navigator',
	standalone: true,
	imports: [CommonModule, RouterLink, RouterLinkActive],
	templateUrl: './step-navigator.component.html',
	styleUrl: './step-navigator.component.scss',
})
export class StepNavigatorComponent implements OnInit, AfterViewInit, OnDestroy {
	@Input() stepConfig: IStepNavigatorConfig;
	@Input() selectedStep: IStepNavigation;
	@Output() selectedStepChange: EventEmitter<IStepNavigation> = new EventEmitter<IStepNavigation>();
	@ViewChildren('tabLink') tabLinks!: QueryList<ElementRef>;
	currentTabIndex: number = 0;

	private layoutService = inject(LayoutService);
	private router = inject(Router);
	private route = inject(ActivatedRoute);

	destroyed$ = new EventEmitter<void>();
	isMobileSize = true;

	StepUI = StepUI;
	NavButtonStyle = NavButtonStyle;

	ngOnInit(): void {
		this.stepConfig = this.applyDefaults(this.stepConfig);

		this.layoutService.isMobileSize$.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
			this.isMobileSize = value;
		});
	}

	ngAfterViewInit(): void {
		this.setCurrentTab();
		this.router.events
			.pipe(
				takeUntil(this.destroyed$),
				filter((event) => event instanceof NavigationEnd),
			)
			.subscribe(() => this.setCurrentTab());
	}

	ngOnDestroy(): void {
		this.destroyed$.emit();
	}

	setCurrentTab(): void {
		if (this.stepConfig.mode === StepModes.ROUTER_OUTLET) {
			const currentRoute = this.route.snapshot.children[0].routeConfig?.path;
			if (currentRoute) {
				const index = this.stepConfig.steps.findIndex((step) => step.route[0] === currentRoute);
				if (index !== -1) {
					this.updateCurrentTabIndex(index);
				}
			}
		}
	}

	updateCurrentTabIndex(index: number): void {
		setTimeout(() => this.scrollToTab(index));
	}

	scrollToTab(index: number) {
		if (!this.stepConfig.steps[index].disabled) {
			this.currentTabIndex = index;
			if (this.stepConfig.ui === StepUI.WIZARD) {
				this.tabLinks.toArray()[index].nativeElement.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
			}
			switch (this.stepConfig.mode) {
				case StepModes.ROUTER_OUTLET:
					this.router.navigate(this.stepConfig.steps[index].route, { relativeTo: this.route });
					this.updateStep(index);
					break;
				case StepModes.NG_SWITCH:
					this.updateStep(index);
					break;
			}
		}
	}

	updateStep(index: number): void {
		this.selectedStepChange.emit(this.stepConfig.steps[index]);
		this.selectedStep = this.stepConfig.steps[index];
	}

	scrollToNextTab() {
		if (this.currentTabIndex < this.stepConfig.steps.length - 1) {
			this.scrollToTab(this.currentTabIndex + 1);
		}
	}

	scrollToPreviousTab() {
		if (this.currentTabIndex > 0) {
			this.scrollToTab(this.currentTabIndex - 1);
		}
	}

	get unifiedSteps(): IStepNavigation[] {
		return this.stepConfig.steps || [];
	}

	applyDefaults(config: IStepNavigatorConfig): IStepNavigatorConfig {
		return {
			...config,
			navButtonStyle: config.navButtonStyle || NavButtonStyle.CHEVRON,
		};
	}

	isActive(step: IStepNavigation): boolean {
		if (this.stepConfig.mode !== StepModes.NG_SWITCH) {
			return false;
		}

		return step.switchCase === this.selectedStep.switchCase;
	}
}
