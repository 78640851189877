import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BaseScheduledItemDetail } from '@leads/client-facing/classes/base-scheduled-item-detail.component';
import { AssessmentStatusDropdownComponent } from '@leads/shared/components/assessment-status-dropdown/assessment-status-dropdown.component';
import { DropdownControlComponent } from '@shared/components/forms/dropdown-control/dropdown-control.component';
import { TextareaControlComponent } from '@shared/components/forms/textarea-control/textarea-control.component';
import { LoadingStateComponent } from '@shared/components/loading-state/loading-state.component';
import { StepNavigatorComponent } from '@shared/components/ui/step-navigator/step-navigator.component';

@Component({
	selector: 'app-desktop-scheduled-item-detail',
	standalone: true,
	imports: [
		CommonModule,
		StepNavigatorComponent,
		LoadingStateComponent,
		TextareaControlComponent,
		FormsModule,
		AssessmentStatusDropdownComponent,
		DropdownControlComponent,
		TextareaControlComponent,
	],
	templateUrl: './desktop-scheduled-item-detail.component.html',
	styleUrl: './desktop-scheduled-item-detail.component.scss',
})
export class DesktopScheduledItemDetailComponent extends BaseScheduledItemDetail {}
