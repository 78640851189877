<div class="client-create-container">
	<form [formGroup]="clientForm">
		<app-accordion parentId="mobileClientDetails">
			<app-accordion-item itemId="customerDetails" parentId="mobileClientDetails" [isOpen]="true">
				<div header>Customer Details</div>

				<app-standard-control
					body
					type="text"
					name="clientName"
					[formGroup]="clientForm"
					label="Client Name"
					placeholder="Client Name"
				></app-standard-control>

				<app-standard-control
					body
					type="text"
					name="clientNumber"
					[formGroup]="clientForm"
					label="Client Contact Number"
					placeholder="Client Contact Number"
					patternMessage="Client Contact must be a valid phone number."
				></app-standard-control>

				<app-standard-control
					body
					type="email"
					name="clientEmail"
					[formGroup]="clientForm"
					label="Client Contact Email"
					placeholder="Client Contact Email"
				></app-standard-control>
			</app-accordion-item>

			<app-accordion-item itemId="propertyDetails" parentId="mobileClientDetails">
				<div header>Property Details</div>

				<div body *ngFor="let property of properties; let i = index">
					<div class="address-container" role="button" (click)="openEditProperty(property, i)">
						{{ property.fullAddress }}, {{ property.suburb }}
					</div>
				</div>
				<button body type="button" class="btn btn-sm btn-primary" (click)="openAddProperty()">
					Add property
					<i class="bi bi-plus"></i>
				</button>
			</app-accordion-item>

			<app-accordion-item itemId="notes" parentId="mobileClientDetails">
				<div header>Internal Notes</div>

				<app-textarea-control
					body
					[heightPx]="100"
					name="internalNotes"
					[formGroup]="clientForm"
					label="Internal Notes"
					placeholder="Internal Notes"
				></app-textarea-control>
			</app-accordion-item>
		</app-accordion>
	</form>
</div>
