import { CalendarModule } from 'angular-calendar';
import { take } from 'rxjs';

import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BaseScheduledItemCreate } from '@leads/client-facing/classes/base-scheduled-item-create.component';
import { TimeEditorComponent } from '@leads/client-facing/components/time-editor/time-editor.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CalendarDayViewComponent } from '@schedule/lib/components/calendar-day-view/calendar-day-view.component';
import { DatePickerDropdownComponent } from '@shared/components/calendar/date-picker-dropdown/date-picker-dropdown.component';
import { DropdownControlComponent } from '@shared/components/forms/dropdown-control/dropdown-control.component';
import { StandardGroupControlComponent } from '@shared/components/forms/standard-group-control/standard-group-control.component';
import { SwitchControlComponent } from '@shared/components/forms/switch-control/switch-control.component';
import { TextareaControlComponent } from '@shared/components/forms/textarea-control/textarea-control.component';
import { BaseModalComponent } from '@shared/components/modals/base-modal/base-modal.component';
import { StepNavigatorComponent } from '@shared/components/ui/step-navigator/step-navigator.component';
import { ModalService } from '@shared/services/modal/modal.service';

@Component({
	selector: 'app-mobile-scheduled-item-create-modal',
	standalone: true,
	imports: [
		CommonModule,
		ReactiveFormsModule,
		StepNavigatorComponent,
		DatePickerDropdownComponent,
		DropdownControlComponent,
		CalendarModule,
		StandardGroupControlComponent,
		TextareaControlComponent,
		SwitchControlComponent,
		CalendarDayViewComponent,
		BaseModalComponent,
	],
	templateUrl: './mobile-scheduled-item-create-modal.component.html',
	styleUrl: './mobile-scheduled-item-create-modal.component.scss',
	host: {
		class: 'h-100',
	},
})
export class MobileScheduledItemCreateModalComponent extends BaseScheduledItemCreate {
	protected activeModal = inject(NgbActiveModal);
	private modalService = inject(ModalService);

	openEditTime(): void {
		const modalRef = this.modalService.open(TimeEditorComponent, true);
		modalRef.componentInstance.formGroup = this.schedulerForm;
		modalRef.componentInstance.selectedDate = this.schedulerForm.controls.selectedDate.value;
		modalRef.componentInstance.confirmAction.pipe(take(1)).subscribe(() => {
			this.updateNewAssessmentEvent(this.selectedStartTime.value as Date, this.selectedEndTime.value as Date);
			this.updateTimePickerEvents();
		});
	}

	closeModal() {
		this.activeModal.close();
	}
}
