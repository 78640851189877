/**
 * @since 6.0.0
 */
export var ConsentType;
(function (ConsentType) {
    /**
     * @since 6.0.0
     */
    ConsentType["AdPersonalization"] = "AD_PERSONALIZATION";
    /**
     * @since 6.0.0
     */
    ConsentType["AdStorage"] = "AD_STORAGE";
    /**
     * @since 6.0.0
     */
    ConsentType["AdUserData"] = "AD_USER_DATA";
    /**
     * @since 6.0.0
     */
    ConsentType["AnalyticsStorage"] = "ANALYTICS_STORAGE";
    /**
     * @since 6.0.0
     */
    ConsentType["FunctionalityStorage"] = "FUNCTIONALITY_STORAGE";
    /**
     * @since 6.0.0
     */
    ConsentType["PersonalizationStorage"] = "PERSONALIZATION_STORAGE";
})(ConsentType || (ConsentType = {}));
/**
 * @since 6.0.0
 */
export var ConsentStatus;
(function (ConsentStatus) {
    /**
     * @since 6.0.0
     */
    ConsentStatus["Granted"] = "GRANTED";
    /**
     * @since 6.0.0
     */
    ConsentStatus["Denied"] = "DENIED";
})(ConsentStatus || (ConsentStatus = {}));
