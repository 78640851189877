<ng-template #title>
	<app-scheduled-item-title [index]="index" [scheduledItem]="scheduledItem" [header]="true"></app-scheduled-item-title>
</ng-template>

<app-base-modal [showFooter]="false" [title]="title">
	<app-loading-state content [isLoading]="isLoading" [loadedContent]="loadedContent"></app-loading-state>

	<ng-template #loadedContent>
		<app-step-navigator [(selectedStep)]="selectedStep" [stepConfig]="navigationConfig"></app-step-navigator>

		<div class="assessment-content">
			<ng-container [ngSwitch]="selectedStep.switchCase">
				<ng-container *ngSwitchCase="NavigationStepCases.ASSESSMENT_OVERVIEW">
					<div class="assessment-overview">
						<div class="schedule-details">
							<h6 class="text-primary">Schedule Details</h6>

							<div class="label">
								<div class="name">Employee</div>
								<div class="value dropdown-container">
									<app-dropdown-control
										[formGroup]="assessmentForm"
										name="employeeId"
										placeholder="Select an employee"
										label="Employee"
										[options]="employees"
										[searchable]="false"
									></app-dropdown-control>
								</div>
							</div>

							<div class="label">
								<div class="name">Assessment Date</div>
								<div class="value">{{ scheduledItem.selectedStartTime | date: 'dd/MM/yyyy' }}</div>
							</div>

							<div class="label">
								<div class="name">Start Time</div>
								<div class="value">{{ scheduledItem.selectedStartTime | date: 'hh:mm a' }}</div>
							</div>

							<div class="label">
								<div class="name">End Time</div>
								<div class="value">{{ scheduledItem.selectedEndTime | date: 'hh:mm a' }}</div>
							</div>

							<div class="label">
								<div class="name">Status</div>
								<div class="value dropdown-container">
									<app-assessment-status-dropdown
										(statusChange)="statusChange($event)"
										[(status)]="leadAssessmentStatus"
										[readonlyView]="readonlyView"
										[statusOptions]="leadAssessmentStatusOptions"
									></app-assessment-status-dropdown>
								</div>
							</div>
						</div>
						<div class="assessment-type-details">
							<h6 class="text-primary">
								<ng-container *ngIf="scheduledItem.leadRequestType === LeadRequestType.ON_SITE_VISIT">
									On Site Details
								</ng-container>
								<ng-container *ngIf="scheduledItem.leadRequestType === LeadRequestType.CALL"> Call Details </ng-container>
							</h6>

							<ng-container *ngIf="scheduledItem.leadRequestType === LeadRequestType.ON_SITE_VISIT">
								<div class="label">
									<div class="name">Assessment Type</div>
									<div class="value">{{ scheduledItem.assessmentType | titlecase }}</div>
								</div>

								<div class="label">
									<div class="name">Assessments</div>
									<div class="value">{{ scheduledItem.assessmentServiceTypes?.join(', ') }}</div>
								</div>

								<div class="label">
									<div class="name">Call Out Fee</div>
									<div class="value">{{ scheduledItem.callOutFeeRands | currency: 'R' }}</div>
								</div>
							</ng-container>

							<div class="assessment-notes">
								<h6 class="text-primary">Assessment Notes</h6>

								<app-textarea-control
									[resizable]="true"
									[height100]="false"
									[heightPx]="150"
									name="assessmentNotes"
									[formGroup]="assessmentForm"
									label="Assessment Notes"
									placeholder="Assessment Notes"
								></app-textarea-control>

								<div class="button-group">
									<button type="button" class="btn btn-sm btn-outline-primary w-100" (click)="openJobCard()">
										<i class="bi bi-wrench"></i>
										Job Card
									</button>

									<button
										type="button"
										class="btn btn-sm btn-primary w-100"
										(click)="onAssessmentNotesChange()"
										[disabled]="assessmentForm.invalid || assessmentForm.pristine"
									>
										<ng-container *ngIf="isSavingNotes; else notLoading">
											<div class="spinner-border spinner-border-sm" role="status">
												<span class="visually-hidden">Loading...</span>
											</div>
										</ng-container>
										<ng-template #notLoading> Save </ng-template>
									</button>
								</div>
							</div>
						</div>
					</div>
				</ng-container>

				<ng-container *ngSwitchCase="NavigationStepCases.COMMUNICATIONS">
					<div class="whatsapp-notes">
						<div class="text-primary">WhatsApp Notes</div>
						<app-textarea-control
							[formGroup]="assessmentForm"
							[height100]="true"
							[resizable]="false"
							class="h-100"
							label="WhatsApp Notes"
							name="whatsappNotes"
							placeholder="WhatsApp Notes"
						></app-textarea-control>
					</div>

					<div class="button-group">
						<button (click)="shareToWhatsApp()" [disabled]="readonlyView" class="btn btn-success btn-whatsapp">
							<i class="bi bi-whatsapp"></i>
							Share to WhatsApp
						</button>
					</div>
				</ng-container>
			</ng-container>
		</div>
	</ng-template>
</app-base-modal>
