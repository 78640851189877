import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LeadAssessmentDetails } from '@leads/customer-facing/components/classes/lead-assessment-details';
import { LeadTimeWindow } from '@leads/shared/models/domain/lead-request.domain';
import { AlertComponent } from '@shared/components/alerts/alert/alert.component';
import { DatePickerFullscreenComponent } from '@shared/components/calendar/date-picker-fullscreen/date-picker-fullscreen.component';
import { dateToNgbDateStruct, formatDate } from '@shared/components/calendar/utilities';
import { DropdownControlComponent } from '@shared/components/forms/dropdown-control/dropdown-control.component';
import { RadioGroupControlComponent } from '@shared/components/forms/radio-group-control/radio-group-control.component';
import { HeaderPopoverComponent } from '@shared/components/sections/header-popover/header-popover.component';
import { IRadioOption, radioOptionsFromEnum } from '@shared/models/form-controls';

@Component({
	selector: 'app-desktop-lead-assessment-details',
	standalone: true,
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		HeaderPopoverComponent,
		AlertComponent,
		RadioGroupControlComponent,
		DatePickerFullscreenComponent,
		DropdownControlComponent,
	],
	templateUrl: './desktop-lead-assessment-details.component.html',
	styleUrl: './desktop-lead-assessment-details.component.scss',
})
export class DesktopLeadAssessmentDetailsComponent extends LeadAssessmentDetails {
	preferredTimeOptions: Array<IRadioOption> = radioOptionsFromEnum(LeadTimeWindow);
	formattedDate: string;

	override datePickerDateChanged(date: Date): void {
		this.form.controls.requestedDate.patchValue(dateToNgbDateStruct(date));
		this.formattedDate = formatDate(date);
	}
}
