import { Directive, HostListener, inject, Input } from '@angular/core';
import { LeadRequestFormLinkService } from '@leads/shared/services/lead-request-form-link/lead-request-form-link.service';

export enum LinkTarget {
	NEW_WINDOW = '_blank',
	SAME_WINDOW = '_top',
}

@Directive({
	selector: '[appLeadRequestFormLink]',
	standalone: true,
})
export class LeadRequestFormLinkDirective {
	@Input() redirectToLeads?: boolean;
	@Input() target?: LinkTarget;

	private service = inject(LeadRequestFormLinkService);

	@HostListener('click', ['$event'])
	onClick(event: Event): void {
		event.preventDefault();
		const url = this.service.getNewLeadLink(this.redirectToLeads);
		if (url) {
			window.open(url, this.target ?? LinkTarget.NEW_WINDOW);
		}
	}
}
