<div class="d-flex flex-row w-100">
	<ng-container *ngIf="item">
		<div class="d-flex flex-wrap" [ngClass]="{ 'flex-column': mobile, 'flex-row': !mobile }">
			<div class="d-flex flex-row flex-wrap gap">
				<div class="align-self-start">
					<app-assessment-status-badge [status]="item.status"></app-assessment-status-badge>
				</div>
				<ng-container *ngIf="!header">
					<span class="title time">
						{{ scheduledTime }}
					</span>
				</ng-container>
				<span class="title"> [{{ item.leadRequestType }} #{{ index + 1 }}] </span>
				<span *ngIf="!mobile && !header" class="title">
					{{ item.assignedEmployee.name }}
				</span>
			</div>
			<div class="d-flex flex-row" *ngIf="mobile && !header">
				<span class="sub-title">{{ item.assignedEmployee.name }}</span>
			</div>
		</div>
	</ng-container>
</div>
