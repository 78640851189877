import { Injectable } from '@angular/core';

export interface CalendarSettings {
	weekStartsOn: number;
	dayStartHour: number;
	dayEndHour: number;
	dayEndMinute: number;
	eventSnapSize: number;
	minimumEventHeight: number;
}

@Injectable({
	providedIn: 'root',
})
export class CalendarSettingsService {
	getDefaultSettings(): CalendarSettings {
		return {
			weekStartsOn: 0,
			dayStartHour: 0,
			dayEndHour: 23,
			dayEndMinute: 59,
			eventSnapSize: 15,
			minimumEventHeight: 15,
		};
	}
}
