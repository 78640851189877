<app-base-modal [showFooter]="false" title="New Scheduled Event">
	<div content>
		<div class="assessment-content">
			<app-step-navigator #stepNav [(selectedStep)]="selectedStep" [stepConfig]="navigationConfig"></app-step-navigator>
			<ng-container [ngSwitch]="selectedStep.switchCase">
				<ng-container *ngSwitchCase="NavigationStepCases.ASSIGN_EMPLOYEE">
					<div class="form-row">
						<div class="form-column">
							<div class="text-primary">Employee Name</div>
							<app-dropdown-control
								[formGroup]="schedulerForm"
								[options]="employees"
								[searchable]="false"
								label="Employee"
								name="employeeId"
								placeholder="Select an employee"
							></app-dropdown-control>
						</div>
					</div>
					<div class="form-row">
						<div class="form-column">
							<div class="text-primary">Request Date</div>
							<app-date-picker-dropdown
								(selectedDateChanged)="datePickerDateChanged($event)"
								[disabledDaysOfTheWeek]="disabledDaysOfTheWeek"
								[form]="schedulerForm"
								[readonly]="true"
								name="selectedDate"
							></app-date-picker-dropdown>
						</div>
					</div>
					<div class="form-row">
						<div class="form-column">
							<div class="form-row justify-content-between">
								<div class="text-primary">Requested Time</div>
								<button class="btn btn-sm btn-primary" role="button" (click)="openEditTime()">Edit Time</button>
							</div>
							<div class="date-column">
								<app-calendar-day-view
									(eventTimesChanged)="timePickerEventTimesChanged($event)"
									(hourSegmentClicked)="timePickerHourClicked($event)"
									[events]="timePickerEvents"
									[hidePopup]="true"
									[refresh]="refreshTimePicker"
									[viewDate]="timePickerSelectedDate"
								></app-calendar-day-view>
							</div>
						</div>
					</div>
					<div class="pagination-controls justify-content-end">
						<button
							(click)="nextTab()"
							[disabled]="employeeId.invalid || selectedDate.invalid || selectedStartTime.invalid || selectedEndTime.invalid"
							class="btn btn-primary"
						>
							Next
							<i class="bi bi-arrow-right"></i>
						</button>
					</div>
				</ng-container>
				<ng-container *ngSwitchCase="NavigationStepCases.ASSIGNMENT_DETAILS">
					<ng-container *ngIf="requestType.value === LeadRequestType.ON_SITE_VISIT">
						<div class="form-column">
							<div class="text-primary">Assessment Type</div>
							<div class="form-column assessment-details">
								<div class="form-row">
									<app-dropdown-control
										[clearable]="true"
										[formGroup]="schedulerForm"
										[multiple]="true"
										[options]="serviceTypes"
										[searchable]="false"
										class="w-100"
										label="Service Type"
										name="assessments"
										placeholder="Select a service"
									></app-dropdown-control>
								</div>
								<div class="form-row">
									<app-dropdown-control
										[formGroup]="schedulerForm"
										[options]="assessmentTypes"
										class="w-100"
										label="Assessment Type"
										name="assessmentType"
										placeholder="Select an assessment type"
									></app-dropdown-control>
								</div>
								<ng-container *ngIf="assessmentType?.value === ScheduledAssessmentType.ASSESSMENT">
									<div class="form-row">
										<app-standard-group-control
											[formGroup]="schedulerForm"
											[showLabel]="false"
											class="w-100"
											label="Call-Out Fee"
											leftText="R"
											name="callOutFeeRands"
											placeholder="Call-Out Fee"
											type="number"
										></app-standard-group-control>
									</div>
									<div class="form-row">
										<app-switch-control
											[formGroup]="schedulerForm"
											label="Include call-out fee in communication to client"
											name="includeCallOutFeeComms"
										></app-switch-control>
									</div>
								</ng-container>
							</div>
						</div>
					</ng-container>

					<div class="form-column">
						<div class="text-primary">Internal Assessment Notes</div>
						<app-textarea-control
							[formGroup]="schedulerForm"
							[height100]="true"
							[resizable]="false"
							class="h-100"
							label="Assessment Notes"
							name="assessmentNotes"
							placeholder="Assessment Notes"
						></app-textarea-control>
					</div>

					<div class="pagination-controls">
						<button (click)="previousTab()" class="btn btn-outline-primary">
							<i class="bi bi-arrow-left btn-sm"></i>
							Back
						</button>
						<button
							(click)="submitAssessment()"
							[disabled]="schedulerForm.invalid || this.assessmentSubmitted || submittingAssessment"
							class="btn btn-primary"
						>
							<ng-container *ngIf="submittingAssessment; else notSubmitting">
								<div class="spinner-border spinner-border-sm" role="status">
									<span class="visually-hidden">Loading...</span>
								</div>
							</ng-container>
							<ng-template #notSubmitting> Create <i class="bi bi-arrow-right"></i></ng-template>
						</button>
					</div>
				</ng-container>
				<ng-container *ngSwitchCase="NavigationStepCases.COMMUNICATIONS">
					<div class="form-column">
						<div class="internal-header text-primary">WhatsApp Notes</div>
						<app-textarea-control
							[formGroup]="schedulerForm"
							[height100]="true"
							[resizable]="false"
							class="h-100"
							label="WhatsApp Notes"
							name="whatsappNotes"
							placeholder="WhatsApp Notes"
						></app-textarea-control>
					</div>
					<div class="pagination-controls">
						<button (click)="previousTab()" class="btn btn-outline-primary">
							<i class="bi bi-arrow-left btn-sm"></i>
							Back
						</button>
						<div>
							<button (click)="shareToWhatsApp()" [disabled]="whatsappNotes.invalid" class="btn btn-success btn-whatsapp">
								<i class="bi bi-whatsapp"></i>
								Share
							</button>
							<button (click)="closeModal()" class="btn btn-primary">
								<i class="bi bi-arrow-right"></i>
								Close
							</button>
						</div>
					</div>
				</ng-container>
			</ng-container>
		</div>
	</div>
</app-base-modal>
