import { NgClass, TitleCasePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { LeadStatus } from '@leads/shared/models/domain/lead-request.domain';

@Component({
	selector: 'app-lead-status-badge',
	standalone: true,
	imports: [TitleCasePipe, NgClass],
	templateUrl: './lead-status-badge.component.html',
	styleUrl: './lead-status-badge.component.scss',
})
export class LeadStatusBadgeComponent {
	@Input() status: LeadStatus;

	get leadStatusClass(): string {
		switch (this.status) {
			case LeadStatus.NEW:
				return 'text-bg-warning';
			case LeadStatus.ACTIVE:
				return 'text-bg-success';
			case LeadStatus.CANCELLED:
			case LeadStatus.REJECTED:
				return 'text-bg-danger';
			case LeadStatus.COMPLETED:
				return 'text-bg-primary';
			default:
				return 'text-bg-primary';
		}
	}
}
