<div class="modal-body text-primary">
	<div class="text-primary text-container">
		<h5>Are you sure you want to reject this assessment request?</h5>
		<label>Share to WhatsApp</label>
		<textarea class="form-control" [(ngModel)]="contentText"></textarea>
	</div>
	<div class="button-container mt-2">
		<button type="button" class="btn btn-sm btn-primary" (click)="closeModal()"><i class="bi bi-arrow-left"></i> Back</button>
		<div class="button-right">
			<button type="button" class="btn btn-sm btn-outline-danger" (click)="reject()">Reject</button>
			<button type="button" class="btn btn-sm btn-success btn-whatsapp" (click)="rejectAndShare()">
				<i class="bi bi-whatsapp"></i>
				Reject and Share
			</button>
		</div>
	</div>
</div>
