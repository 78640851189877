import { CalendarEvent } from 'angular-calendar';
import { map, Observable } from 'rxjs';

import { inject, Injectable } from '@angular/core';
import { DocumentReference } from '@angular/fire/firestore';
import { LeadRequestType } from '@leads/shared/models/domain/lead-request.domain';
import { ScheduledAssessmentStatus } from '@leads/shared/models/domain/scheduled-assessment.domain';
import { ILeadAssessmentWithLeadRequest } from '@leads/shared/models/firebase/scheduled-assessment.model';
import { IOrganisation } from '@organisations/models/organisations';
import { CalendarEventColours } from '@schedule/lib/classes/event-colours';
import { ScheduleAction, ScheduledEvent } from '@schedule/models/calendar/calendar.model';
import { ScheduleService } from '@schedule/services/schedule/schedule.service';

export type CalendarEvents = Array<CalendarEvent<ScheduledEvent>>;

@Injectable({
	providedIn: 'root',
})
export class CalendarService {
	private scheduleService = inject(ScheduleService);

	/**
	 * Transforms the scheduled assessments into calendar events
	 */
	getCalendarEvents(
		organisationRef: DocumentReference<IOrganisation>,
		startDate: Date,
		endDate: Date,
		employeeId?: string | null,
	): Observable<CalendarEvents> {
		const scheduledAssessments = this.scheduleService.getScheduledAssessments(organisationRef, startDate, endDate, employeeId);
		return this.transformScheduledAssessmentsToEvents(scheduledAssessments);
	}

	getCalendarEventsByEmployeeId(employeeId: string, startDate: Date, endDate: Date): Observable<CalendarEvents> {
		const scheduledAssessments = this.scheduleService.getScheduledAssessmentsByEmployeeId(employeeId, startDate, endDate);
		return this.transformScheduledAssessmentsToEvents(scheduledAssessments, false);
	}

	private transformScheduledAssessmentsToEvents(
		scheduledAssessments: Observable<ILeadAssessmentWithLeadRequest[]>,
		includeMeta: boolean = true,
	): Observable<CalendarEvents> {
		return scheduledAssessments.pipe(
			map((assessments) => {
				return assessments.map((assessment: ILeadAssessmentWithLeadRequest) => {
					const event: CalendarEvent<ScheduledEvent> = {
						title: this.decideTitle(assessment),
						start: assessment.assignment.dateTime.start.toDate(),
						end: assessment.assignment.dateTime.end.toDate(),
						meta: includeMeta ? this.createScheduledEvent(assessment) : undefined,
						color: CalendarEventColours.scheduledAssessment(assessment.status),
					};
					return event;
				});
			}),
			map((events) => {
				// Sort events by start time
				return events.sort((a, b) => a.start.getTime() - b.start.getTime());
			}),
		);
	}

	private createScheduledEvent(assessment: ILeadAssessmentWithLeadRequest): ScheduledEvent {
		return {
			scheduledAssessmentId: assessment.id!,
			serviceTypes: assessment.assessmentDetails.serviceTypes ?? [],
			scheduledAssessmentStatus: assessment.status,
			scheduledAssessmentNotes: assessment.assessmentDetails.internalAssessmentNotes ?? '',
			leadRequestId: assessment.leadRequestId,
			leadRequestType: assessment.requestType,
			customerName: assessment.customerName,
			customerNumber: `(${assessment.customerNumber?.countryCode}) ${assessment.customerNumber?.number}`,
			customerProperty: assessment.customerProperty,
			employeeUserId: assessment.employeeId,
			employeeName: assessment.employeeDisplayName,
			description: assessment.leadRequestNotes,
			actions: this.decideActions(assessment),
		};
	}

	private decideTitle(assessment: ILeadAssessmentWithLeadRequest) {
		return assessment.requestType === LeadRequestType.CALL ? `[Call] ${assessment.customerName}` : `[Site] ${assessment.customerName}`;
	}

	/**
	 * Applies business rules to decide which actions are available for the scheduled event.
	 * Rules
	 * - Always include Edit
	 * - Always include Cancel {Job, Call, Visit}
	 */
	private decideActions({ requestType }: ILeadAssessmentWithLeadRequest): Array<ScheduleAction> {
		const actions = [ScheduleAction.VIEW, ScheduleAction.NOTES, ScheduleAction.JOB_CARD];

		if (requestType === LeadRequestType.ON_SITE_VISIT) {
			actions.push(ScheduleAction.CANCEL_JOB);
		} else if (requestType === LeadRequestType.CALL) {
			actions.push(ScheduleAction.CANCEL_CALL);
		} else if (requestType === null) {
			actions.push(ScheduleAction.CANCEL_VISIT);
		}
		return actions;
	}

	updateScheduledAssessmentTimes(event: ScheduledEvent, startTime: Date, endTime: Date) {
		return this.scheduleService.updateScheduledAssessmentTimes(event.scheduledAssessmentId, startTime, endTime);
	}

	updateScheduledAssessmentStatus(event: ScheduledEvent, status: ScheduledAssessmentStatus) {
		return this.scheduleService.updateScheduledAssessmentStatus(event.scheduledAssessmentId, status);
	}

	updateScheduledAssessmentNotes(event: ScheduledEvent, notes: string) {
		return this.scheduleService.updateScheduledAssessmentNotes(event.scheduledAssessmentId, notes);
	}
}
