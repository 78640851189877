<div *ngIf="dismissible"
	 class="alert alert-dismissible fade show"
	 [ngClass]="'alert-' + alertType"
	 [ngStyle]="{'margin-bottom.px': marginBottom}"
	 role="alert"
>
	<div class="alert-container">
		<i class="bi" [ngClass]="alertIcon"></i>
		<ng-container *ngIf="isTemplate(content); else stringContent">
			<ng-container *ngTemplateOutlet="content"></ng-container>
		</ng-container>
		<ng-template #stringContent>
			<div>{{ content }}</div>
		</ng-template>
	</div>
	<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
</div>

<div *ngIf="!dismissible"
	 class="alert"
	 [ngClass]="'alert-' + alertType"
	 [ngStyle]="{'margin-bottom.px': marginBottom}"
	 role="alert"
>
	<div class="alert-container">
		<i class="bi" [ngClass]="alertIcon"></i>
		<ng-container *ngIf="isTemplate(content); else stringContent">
			<ng-container *ngTemplateOutlet="content"></ng-container>
		</ng-container>
		<ng-template #stringContent>
			<div>{{ content }}</div>
		</ng-template>
	</div>
</div>
