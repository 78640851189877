<mwl-calendar-month-view
	[events]="events"
	[refresh]="refresh"
	[viewDate]="viewDate"
	[cellTemplate]="monthCellTemplate"
	[headerTemplate]="headerTemplate"
	(dayClicked)="handleDayClicked($event.day)"
	(eventClicked)="handleEventClicked($event.event)"
	(eventTimesChanged)="handleEventTimesChanged($event)"
></mwl-calendar-month-view>

<ng-template #monthCellTemplate let-day="day" let-locale="locale">
	<app-calendar-month-day-cell [screenSize]="screenSize" [isOwner]="isOwner" [day]="day" [locale]="locale"></app-calendar-month-day-cell>
</ng-template>

<ng-template #headerTemplate let-days="days" let-locale="locale" let-trackByWeekDayHeaderDate="trackByWeekDayHeaderDate">
	<app-calendar-month-headers
		[days]="days"
		[locale]="locale"
		[trackByWeekDayHeaderDate]="trackByWeekDayHeaderDate"
		(columnHeaderClicked)="handleColumnHeaderClicked($event)"
	>
	</app-calendar-month-headers>
</ng-template>
