<div class="subpage-container">
	<div class="heading">
		<app-calendar-controls
			[calendarView]="view"
			(calendarViewChange)="viewChanged.emit($event)"
			[(viewDate)]="viewDate"
			(viewDateChange)="viewDateChange.emit($event)"
			[daysInWeek]="daysInWeek"
			[showEmployeeFilter]="false"
		></app-calendar-controls>
	</div>
	<div class="content">
		<app-loading-state [isLoading]="isLoading" [loadedContent]="loadedContent"></app-loading-state>
		<ng-template #loadedContent>
			<ng-container [ngSwitch]="view">
				<app-calendar-month-view
					*ngSwitchCase="CalendarView.Month"
					[isOwner]="false"
					[events]="events"
					[refresh]="refresh"
					[viewDate]="viewDate"
					[activeDayIsOpen]="activeDayIsOpen"
					(dayClicked)="dayClicked.emit($event)"
					(eventClicked)="eventClicked.emit($event)"
				></app-calendar-month-view>
				<app-calendar-week-view
					[isOwner]="false"
					*ngSwitchCase="CalendarView.Week"
					[events]="events"
					[refresh]="refresh"
					[viewDate]="viewDate"
					(eventClicked)="eventClicked.emit($event)"
				></app-calendar-week-view>
				<app-calendar-day-view
					*ngSwitchCase="CalendarView.Day"
					[isOwner]="false"
					[events]="events"
					[refresh]="refresh"
					[viewDate]="viewDate"
					(eventClicked)="eventClicked.emit($event)"
				></app-calendar-day-view>
			</ng-container>
		</ng-template>
	</div>
</div>
