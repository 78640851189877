import { NgIf, NgStyle, NgTemplateOutlet } from '@angular/common';
import { Component, inject, Input, TemplateRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-base-modal',
	standalone: true,
	imports: [NgStyle, NgIf, NgTemplateOutlet],
	templateUrl: './base-modal.component.html',
	styleUrl: './base-modal.component.scss',
	host: {
		style: 'height: calc(100% - 57px);',
	},
})
export class BaseModalComponent {
	@Input() title: string | TemplateRef<any>;
	@Input() padding?: number;
	@Input() showFooter?: boolean;

	protected activeModal = inject(NgbActiveModal);

	protected closeModal(): void {
		this.activeModal.close();
	}

	isTemplate(value: any): value is TemplateRef<any> {
		return value instanceof TemplateRef;
	}
}
