import { BehaviorSubject, Observable } from 'rxjs';
import { AppRoutes } from 'src/app/app.routes';

import { inject, Injectable } from '@angular/core';
import {
	Auth,
	authState,
	createUserWithEmailAndPassword,
	GoogleAuthProvider,
	idToken,
	sendEmailVerification,
	sendPasswordResetEmail,
	signInWithCredential,
	signInWithEmailAndPassword,
	signOut,
	updateProfile,
	User,
	UserCredential,
} from '@angular/fire/auth';
import { Router } from '@angular/router';
import { FirebaseAuthErrorMap } from '@auth/classes/firebase-errors';
import { IUserLogin } from '@auth/models/login';
import { IPasswordReset } from '@auth/models/password-reset';
import { IUserSignUp } from '@auth/models/signup';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { OrganisationSelectedService } from '@organisations/services/organisation-selected/organisation-selected.service';
import { AnalyticEvent } from '@shared/models/analytics';
import { ToastTypes } from '@shared/models/toast';
import { AnalyticsService } from '@shared/services/analytics/analytics.service';
import { ToastService } from '@shared/services/toast/toast.service';
import { UsersService } from '@user/services/users/users.service';

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	private analyticsService = inject(AnalyticsService);
	private auth = inject(Auth);
	private router = inject(Router);
	private usersService = inject(UsersService);
	private toastService = inject(ToastService);
	private organisationSelectdService = inject(OrganisationSelectedService);

	private currentUserSubject = new BehaviorSubject<User | null>(null);
	currentUser$: Observable<User | null> = authState(this.auth);

	constructor() {
		idToken(this.auth).subscribe((token) => localStorage.setItem('token', token ?? ''));
		this.currentUser$.subscribe((user) => {
			this.analyticsService.setUserId(user?.uid!);
			this.currentUserSubject.next(user);
		});
	}

	getCurrentUser(): Observable<User | null> {
		return this.currentUserSubject.asObservable();
	}

	get currentUser(): User | null {
		return this.auth.currentUser;
	}

	async signOut(): Promise<void> {
		this.organisationSelectdService.clearSelectedOrganisation();
		await FirebaseAuthentication.signOut();
		this.analyticsService.logEvent(AnalyticEvent.SIGN_OUT);
		return await signOut(this.auth);
	}

	async signUpWithEmailAndPassword(userSignup: IUserSignUp): Promise<UserCredential | void> {
		try {
			const result = await createUserWithEmailAndPassword(this.auth, userSignup.email, userSignup.password);
			await sendEmailVerification(result.user);
			await updateProfile(result.user, { displayName: userSignup.fullName });
			await this.usersService.set(result.user.uid, {
				uid: result.user.uid,
				email: result.user.email,
				displayName: userSignup.fullName,
				photoUrl: result.user.photoURL,
			});

			this.handleSuccess();
			this.analyticsService.logEvent(AnalyticEvent.SIGN_UP);
			return result;
		} catch (error: any) {
			this.handleError('Sign up Error', error.code);
		}
	}

	async signInWithGoogle(): Promise<UserCredential | null> {
		try {
			const result = await FirebaseAuthentication.signInWithGoogle({
				customParameters: [
					{
						key: 'prompt',
						value: 'select_account',
					},
				],
			});
			const credential = GoogleAuthProvider.credential(result.credential?.idToken);

			if (result.user) {
				this.usersService.set(result.user.uid, {
					uid: result.user.uid,
					email: result.user.email,
					displayName: result.user.displayName,
					photoUrl: result.user.photoUrl,
				});

				this.analyticsService.logEvent(AnalyticEvent.GOOGLE_SIGN_IN);
				this.handleSuccess();
			}
			return await signInWithCredential(this.auth, credential);
		} catch (error: any) {
			this.handleError('Google Sign In Error', error.code, error.message);
			return null;
		}
	}

	async signInWithEmailAndPassword(user: IUserLogin): Promise<void> {
		try {
			const result = await signInWithEmailAndPassword(this.auth, user.email, user.password);
			this.analyticsService.logEvent(AnalyticEvent.SIGN_IN);
			this.handleSuccess();
		} catch (error: any) {
			this.handleError('Login Error', error.code);
		}
	}

	async sendPasswordResetEmail(passwordReset: IPasswordReset): Promise<void> {
		try {
			const result = await sendPasswordResetEmail(this.auth, passwordReset.email);
			this.toastService.showToast({
				title: 'Password Reset',
				message: 'An email has been sent, please click on the link in the email to reset your password.',
				type: ToastTypes.SUCCESS,
			});
			this.analyticsService.logEvent(AnalyticEvent.PASSWORD_RESET);
		} catch (error: any) {
			this.handleError('Password Reset Error', error.code);
		}
	}

	handleSuccess(): void {
		this.router.navigate([AppRoutes.DASHBOARD]);
	}

	handleError(title: string, errorCode: string, messageOverride?: string): void {
		const message = FirebaseAuthErrorMap[errorCode] || 'An error occurred. Please try again later.';
		this.toastService.showToast({
			title: title,
			message: messageOverride ?? message,
			type: ToastTypes.ERROR,
		});
	}
}
