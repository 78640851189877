<mwl-calendar-day-view
	[dayStartHour]="calendarSettings.dayStartHour"
	[dayEndHour]="calendarSettings.dayEndHour"
	[dayEndMinute]="calendarSettings.dayEndMinute"
	[eventSnapSize]="calendarSettings.eventSnapSize"
	[minimumEventHeight]="calendarSettings.minimumEventHeight"
	[events]="events"
	[refresh]="refresh"
	[viewDate]="viewDate"
	[eventTemplate]="eventTemplate"
	[currentTimeMarkerTemplate]="currentTimeMarkerTemplate"
	(eventClicked)="handleEventClicked($event.event)"
	(eventTimesChanged)="handleEventTimesChanged($event)"
	(hourSegmentClicked)="handleHourSegmentClicked($event)"
	locale="en-ZA"
>
</mwl-calendar-day-view>

<ng-template
	#eventTemplate
	let-weekEvent="weekEvent"
	let-tooltipPlacement="tooltipPlacement"
	let-eventClicked="eventClicked"
	let-tooltipTemplate="tooltipTemplate"
	let-tooltipAppendToBody="tooltipAppendToBody"
	let-tooltipDisabled="tooltipDisabled"
	let-tooltipDelay="tooltipDelay"
	let-column="column"
>
	<div
		class="calendar-week-cell-event"
		tabindex="0"
		role="application"
		[ngbPopover]="eventPopoverTemplate"
		container="body"
		placement="auto"
		autoClose="outside"
		triggers="manual"
		#p1="ngbPopover"
		[ngStyle]="{
			color: weekEvent.event.color?.secondaryText,
			backgroundColor: weekEvent.event.color?.secondary,
			borderColor: weekEvent.event.color?.primary,
		}"
		(click)="toggleEventPopover(p1, weekEvent.tempEvent || weekEvent.event)"
		(mwlKeydownEnter)="toggleEventPopover(p1, weekEvent.tempEvent || weekEvent.event)"
	>
		<span class="event-time">{{ (weekEvent.tempEvent || weekEvent.event).start | date: 'h:mma' | lowercase }}</span>
		<span class="event-title">
			{{ (weekEvent.tempEvent || weekEvent.event).title }}
			<ng-container *ngIf="(weekEvent.tempEvent || weekEvent.event).meta?.customerPropertyAddress">
				<br />
				{{ (weekEvent.tempEvent || weekEvent.event).meta.customerPropertyAddress }}
			</ng-container>
		</span>
	</div>
</ng-template>

<ng-template
	#currentTimeMarkerTemplate
	let-columnDate="columnDate"
	let-dayStartHour="dayStartHour"
	let-dayStartMinute="dayStartMinute"
	let-dayEndHour="dayEndHour"
	let-dayEndMinute="dayEndMinute"
	let-isVisible="isVisible"
	let-topPx="topPx"
>
	<div *ngIf="isVisible" class="cal-current-time-marker" [style.top.px]="topPx"></div>
</ng-template>

<ng-template #eventPopoverTemplate let-event="event">
	<app-calendar-popover [event]="event" [isOwner]="isOwner" (closed)="onClosePopup()"></app-calendar-popover>
</ng-template>
