import { Routes } from '@angular/router';
import { ScheduleComponent } from '@schedule/pages/schedule/schedule.component';

export enum ScheduleRoutes {
	SCHEDULE = '',
	MONTH = 'month',
	WEEK = 'week',
	DAY = 'day',
}

export const scheduleRoutes: Routes = [
	{
		path: ScheduleRoutes.SCHEDULE,
		redirectTo: ScheduleRoutes.WEEK,
		pathMatch: 'full',
	},
	{
		path: ScheduleRoutes.MONTH,
		component: ScheduleComponent,
		title: 'ServeOx | Schedule Month',
	},
	{
		path: ScheduleRoutes.WEEK,
		component: ScheduleComponent,
		title: 'ServeOx | Schedule Week',
	},
	{
		path: ScheduleRoutes.DAY,
		component: ScheduleComponent,
		title: 'ServeOx | Schedule Day',
	},
	{
		path: '**',
		redirectTo: ScheduleRoutes.WEEK,
	},
];
