<form [formGroup]="formGroup">
	<div class="radio-group" [style.flex-direction]="flexDirection">
		<ng-container *ngFor="let option of options; let i = index">
			<fieldset [disabled]="option.disabled">
				<div #radioItem class="form-check" (click)="scrollToTab(i)">
					<input class="btn-check" type="radio" [formControlName]="name" [value]="option.value" [id]="option.id" />
					<label class="btn btn-outline-primary" [for]="option.id">
						<ng-container *ngIf="option.statusIndicator">
							<span class="indicator {{ option.statusIndicator }}"></span>
						</ng-container>
						<div [class.label-padding]="option.statusIndicator" style="font-size: small">
							{{ option.label }}
						</div>
					</label>
				</div>
			</fieldset>
		</ng-container>
	</div>
</form>
