<div class="page-container">
	<div class="heading">
		<app-page-title title="Schedule"></app-page-title>
	</div>
	<div class="content">
		<app-loading-state [isLoading]="isLoading" [loadedContent]="loadedContent"></app-loading-state>
		<ng-template #loadedContent>
			<ng-container *ngIf="isOwner; else employee">
				<app-calendar-owner-view
					[isLoading]="eventsLoading"
					[view]="view"
					(viewChanged)="viewChanged($event)"
					[(selectedEmployeeId)]="selectedEmployeeId"
					(selectedEmployeeIdChange)="selectedEmployeeChange($event)"
					[viewDate]="viewDate"
					(viewDateChange)="viewDateChange($event)"
					[events]="events"
					[refresh]="refresh"
					[employees]="employees"
					(dayClicked)="dayClicked($event)"
					(eventTimesChanged)="eventTimesChanged($event)"
				></app-calendar-owner-view>
			</ng-container>
			<ng-template #employee>
				<app-calendar-employee-view
					[isLoading]="eventsLoading"
					[view]="view"
					(viewChanged)="viewChanged($event)"
					[viewDate]="viewDate"
					(viewDateChange)="viewDateChange($event)"
					[events]="events"
					[refresh]="refresh"
					(dayClicked)="dayClicked($event)"
				></app-calendar-employee-view>
			</ng-template>
		</ng-template>
	</div>
</div>
