import 'zone.js';

import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView } from 'angular-calendar';
import { filter, Subject, take, takeUntil } from 'rxjs';

import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, OnDestroy, OnInit } from '@angular/core';
import { DocumentReference } from '@angular/fire/firestore';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppRoutes } from '@app/app.routes';
import { AuthService } from '@auth/services/auth.service';
import { ClientLeadsRoutes } from '@leads/client-facing/client-facing.routes';
import { JobCardModalComponent } from '@leads/client-facing/components/job-card-modal/job-card-modal.component';
import { ScheduledItemNotesEditModalComponent } from '@leads/client-facing/components/scheduled-item-notes-edit-modal/scheduled-item-notes-edit-modal.component';
import { ScheduledAssessmentStatus } from '@leads/shared/models/domain/scheduled-assessment.domain';
import { LeadStorageService } from '@leads/shared/services/lead-storage/lead-storage.service';
import { IOrganisationUserWithUserName, OrganisationUserAccessLevel } from '@organisations/models/organisation-user';
import { IOrganisation } from '@organisations/models/organisations';
import { OrganisationSelectedService } from '@organisations/services/organisation-selected/organisation-selected.service';
import { OrganisationUserService } from '@organisations/services/organisation-user/organisation-user.service';
import { CalendarEventColours } from '@schedule/lib/classes/event-colours';
import { CalendarEmployeeViewComponent } from '@schedule/lib/components/calendar-employee-view/calendar-employee-view.component';
import { CalendarOwnerViewComponent } from '@schedule/lib/components/calendar-owner-view/calendar-owner-view.component';
import { ISearchableEmployee } from '@schedule/lib/models/employee';
import { PopoverChange, PopoverChangeEvent, ScheduleAction, ScheduledEvent } from '@schedule/models/calendar/calendar.model';
import { ScheduleRoutes } from '@schedule/schedule.routes';
import { CalendarSettings, CalendarSettingsService } from '@schedule/services/calendar/calendar-settings/calendar-settings.service';
import { CalendarService } from '@schedule/services/calendar/calendar.service';
import { LoadingStateComponent } from '@shared/components/loading-state/loading-state.component';
import { ConfirmationModalComponent } from '@shared/components/modals/confirmation-modal/confirmation-modal.component';
import { PageTitleComponent } from '@shared/components/sections/page-title/page-title.component';
import { ModalService } from '@shared/services/modal/modal.service';

@Component({
	selector: 'app-schedule',
	standalone: true,
	imports: [
		CommonModule,
		FormsModule,
		PageTitleComponent,
		LoadingStateComponent,
		CalendarOwnerViewComponent,
		CalendarEmployeeViewComponent,
	],
	templateUrl: './schedule.component.html',
	styleUrl: './schedule.component.scss',
})
export class ScheduleComponent implements OnInit, OnDestroy {
	destroyed$ = new EventEmitter<void>();

	isOwner = false;
	isLoading = true;
	eventsLoading = true;

	private route = inject(ActivatedRoute);
	private router = inject(Router);
	private modalService = inject(ModalService);
	private authService = inject(AuthService);
	private leadStorage = inject(LeadStorageService);

	// Organisation Data
	private organisation: DocumentReference<IOrganisation>;
	private organisationUserService = inject(OrganisationUserService);
	private organisationSelectedService = inject(OrganisationSelectedService);

	activeOrganisationUsers: Array<IOrganisationUserWithUserName> = [];
	employees: Array<ISearchableEmployee> = [];

	selectedEmployeeId: string | null = null;

	// Calendar Data
	private calendarService = inject(CalendarService);
	private calendarSettingsService = inject(CalendarSettingsService);
	// Calendar Events
	protected refresh = new Subject<void>();

	view: CalendarView = CalendarView.Week;
	calendarSettings: CalendarSettings;

	// View Date and Date Ranges
	viewDate: Date = new Date();
	private eventsStartDate: Date = new Date();
	private eventsEndDate: Date = new Date();

	// Scheduled Events
	events: CalendarEvent<ScheduledEvent>[] = [];
	actions: CalendarEventAction[] = [
		{
			label: ScheduleAction.CREATE,
			a11yLabel: ScheduleAction.CREATE,
			cssClass: 'bi-plus',
			onClick: ({ event }: { event: CalendarEvent }): void => {
				this.handleEvent(ScheduleAction.CREATE, event);
			},
		},
		{
			label: ScheduleAction.VIEW,
			a11yLabel: ScheduleAction.VIEW,
			cssClass: 'bi-eye',
			onClick: ({ event }: { event: CalendarEvent }): void => {
				this.navigateToAssessmentRequest(event.meta);
			},
		},
		{
			label: ScheduleAction.CANCEL_JOB,
			a11yLabel: ScheduleAction.CANCEL_JOB,
			cssClass: 'bi-x-circle',
			onClick: ({ event }: { event: CalendarEvent }): void => {
				this.cancelScheduledEvent(event);
			},
		},
		{
			label: ScheduleAction.CANCEL_CALL,
			a11yLabel: ScheduleAction.CANCEL_CALL,
			cssClass: 'bi-x-circle',
			onClick: ({ event }: { event: CalendarEvent }): void => {
				this.cancelScheduledEvent(event);
			},
		},
		{
			label: ScheduleAction.NOTES,
			a11yLabel: ScheduleAction.NOTES,
			cssClass: 'bi-pencil',
			onClick: ({ event }: { event: CalendarEvent }): void => {
				this.editScheduledEventNotes(event);
			},
		},
		{
			label: ScheduleAction.JOB_CARD,
			a11yLabel: ScheduleAction.JOB_CARD,
			cssClass: 'bi-wrench',
			onClick: ({ event }: { event: CalendarEvent }): void => {
				this.openJobCard(event);
			},
		},
	];

	popoverActions: PopoverChangeEvent[] = [
		{
			action: PopoverChange.SCHEDULED_ASSESSMENT_STATUS,
			onChange: (value: ScheduledAssessmentStatus, event: ScheduledEvent): void => {
				this.scheduledAssessmentStatusChanged(value, event);
			},
		},
	];

	constructor() {
		const activeRoute = this.route.snapshot.url.pop()?.path;
		switch (activeRoute) {
			case ScheduleRoutes.MONTH:
				this.view = CalendarView.Month;
				break;
			default:
			case ScheduleRoutes.WEEK:
				this.view = CalendarView.Week;
				break;
			case ScheduleRoutes.DAY:
				this.view = CalendarView.Day;
				break;
		}
	}

	ngOnInit(): void {
		this.calendarSettings = this.calendarSettingsService.getDefaultSettings();
		this.subscribeToSelectedOrganisation();
	}

	ngOnDestroy(): void {
		this.destroyed$.emit();
	}

	private mapAction(event: CalendarEvent<ScheduledEvent>): CalendarEventAction[] {
		if (this.isOwner) {
			return event
				.meta!.actions.map((action) => this.actions.find((a) => a.label === action))
				.filter((action): action is CalendarEventAction => action !== undefined);
		} else {
			return event
				.meta!.actions.map((action) => this.actions.find((a) => a.label === action))
				.filter((action): action is CalendarEventAction => action !== undefined)
				.filter(
					(action): action is CalendarEventAction =>
						action.label === ScheduleAction.NOTES || action.label === ScheduleAction.JOB_CARD,
				);
		}
	}

	subscribeToSelectedOrganisation(): void {
		this.organisationSelectedService.selectedOrganisation
			.pipe(
				takeUntil(this.destroyed$),
				filter((o) => o !== null),
			)
			.subscribe((selectedOrg) => {
				this.isOwner = selectedOrg?.accessLevel === OrganisationUserAccessLevel.OWNER;
				if (!this.isOwner) {
					this.selectedEmployeeId = this.authService.currentUser?.uid!;
				}
				this.organisation = selectedOrg?.organisation as DocumentReference<IOrganisation>;
				this.fetchOrganisationUsers(this.organisation);
				this.calculateQueryDateRanges();
			});
	}

	private fetchOrganisationUsers(organisationRef: DocumentReference<IOrganisation>): void {
		this.organisationUserService
			.activeUsersByOrganisationRef(organisationRef)
			.pipe(takeUntil(this.destroyed$))
			.subscribe((organisationUsers) => {
				this.activeOrganisationUsers = organisationUsers;
				this.employees = organisationUsers.map((u, index) => ({
					userId: u.userId as string,
					email: u.email as string,
					displayName: u.userDisplayName as string,
				}));
			});
	}

	private getCalendarEvents(organisationRef: DocumentReference<IOrganisation>): void {
		this.eventsLoading = true;
		this.calendarService
			.getCalendarEvents(organisationRef, this.eventsStartDate, this.eventsEndDate, this.selectedEmployeeId)
			.pipe(takeUntil(this.destroyed$))
			.subscribe((events) => {
				this.events = events.map((event) => {
					const meta = event!.meta;
					meta!.popoverChanges = this.popoverActions;
					return {
						...event,
						actions: this.mapAction(event),
						resizable: {
							beforeStart: true,
							afterEnd: true,
						},
						draggable: true,
						meta: meta,
						color: CalendarEventColours.scheduledAssessment(meta!.scheduledAssessmentStatus),
					};
				});
				this.refresh.next();
				this.isLoading = false;
				this.eventsLoading = false;
			});
	}

	/**
	 * Calculates the start and end dates for the current view based on the `viewDate`.
	 * If the calculated start and end dates differ from the existing `eventsStartDate` and `eventsEndDate`,
	 * it updates these dates and fetches the calendar events for the new date range.
	 *
	 * This allows us to use pagination to query events for the current month/week/day
	 */
	private calculateQueryDateRanges(): void {
		let newStartDate: Date;
		let newEndDate: Date;
		switch (this.view) {
			case CalendarView.Day:
				newStartDate = new Date(this.viewDate);
				newEndDate = new Date(this.viewDate);
				newStartDate.setHours(0, 0, 0, 0);
				newEndDate.setHours(23, 59, 59, 999);
				break;
			case CalendarView.Week:
			default:
				const dayOffset = this.viewDate.getDay() + ((7 - this.calendarSettings.weekStartsOn) % 7);
				newStartDate = new Date(this.viewDate);
				newStartDate.setDate(this.viewDate.getDate() - dayOffset);
				newStartDate.setHours(0, 0, 0, 0);

				newEndDate = new Date(this.viewDate);
				newEndDate.setDate(this.viewDate.getDate() + (6 - dayOffset));
				newEndDate.setHours(23, 59, 59, 999);
				break;
			case CalendarView.Month:
				newStartDate = new Date(this.viewDate.getFullYear(), this.viewDate.getMonth(), 1);
				newEndDate = new Date(this.viewDate.getFullYear(), this.viewDate.getMonth() + 1, 0);
				break;
		}

		this.eventsStartDate = newStartDate;
		this.eventsEndDate = newEndDate;
		this.getCalendarEvents(this.organisation);
	}

	viewChanged(view: CalendarView) {
		this.view = view;
		switch (view) {
			case CalendarView.Month:
				this.router.navigate([ScheduleRoutes.MONTH], { relativeTo: this.route });
				break;
			case CalendarView.Week:
				this.router.navigate([ScheduleRoutes.WEEK], { relativeTo: this.route });
				break;
			case CalendarView.Day:
				this.router.navigate([ScheduleRoutes.DAY], { relativeTo: this.route });
				break;
			default:
				this.viewChanged(CalendarView.Week);
				break;
		}
	}

	viewDateChange(viewDate: Date) {
		this.viewDate = viewDate;
		this.calculateQueryDateRanges();
	}

	selectedEmployeeChange(employeeId: string | null): void {
		this.getCalendarEvents(this.organisation);
	}

	handleEvent(action: ScheduleAction, event: CalendarEvent): void {
		console.log('handleEvent', action, event);
	}

	// TODO Use when we implement ScheduleAction.CREATE
	dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {}

	/**
	 * Allow events to be dragged and dropped freely without RULES
	 */
	eventTimesChanged(changedEvent: CalendarEventTimesChangedEvent): void {
		const eventFind = this.events.find((event) => event === changedEvent.event);

		const startTimeChanged = eventFind!.start.getTime() === changedEvent.newStart.getTime();
		const endTimeChanged = eventFind!.end!.getTime() === changedEvent.newEnd!.getTime();

		// Ensure the time has actually changed to avoid misclicks
		if (!eventFind || (startTimeChanged && endTimeChanged)) {
			return;
		}

		const modalRef = this.modalService.open(ConfirmationModalComponent, false, 'md');
		modalRef.componentInstance.title = 'Are you sure you want to change the time of this event?';
		modalRef.componentInstance.contentText = `Proposed time: ${Intl.DateTimeFormat('en-ZA', {
			weekday: 'short',
			month: 'short',
			day: '2-digit',
			hour: '2-digit',
			minute: '2-digit',
		}).formatRange(changedEvent.newStart, changedEvent.newEnd!)}`;
		modalRef.componentInstance.confirmAction.pipe(take(1)).subscribe(() => {
			this.events = this.events.map((iEvent) => {
				if (iEvent === changedEvent.event) {
					return {
						...changedEvent.event,
						start: changedEvent.newStart,
						end: changedEvent.newEnd,
					};
				}
				return iEvent;
			});
			this.calendarService.updateScheduledAssessmentTimes(changedEvent.event.meta!, changedEvent.newStart, changedEvent.newEnd!);
		});
	}

	scheduledAssessmentStatusChanged(status: ScheduledAssessmentStatus, event: ScheduledEvent) {
		this.calendarService.updateScheduledAssessmentStatus(event, status);
	}

	navigateToAssessmentRequest(event: ScheduledEvent): void {
		this.router.navigate([
			`${AppRoutes.CLIENT_LEADS_DASHBOARD}/${ClientLeadsRoutes.ASSESSMENT_REVIEW.replace(':leadRequestId', event.leadRequestId)}`,
		]);
	}

	private cancelScheduledEvent(event: CalendarEvent<ScheduledEvent>) {
		const modalRef = this.modalService.open(ConfirmationModalComponent, false, 'md');
		modalRef.componentInstance.title = 'Are you sure you want to cancel this scheduled event?';
		modalRef.componentInstance.contentText = 'You will have to create a new one if you want to re-schedule it.';
		modalRef.componentInstance.confirmAction.pipe(take(1)).subscribe(() => {
			this.events = this.events.filter((iEvent) => iEvent !== event);
			this.calendarService.updateScheduledAssessmentStatus(event.meta!, ScheduledAssessmentStatus.CANCELLED);
		});
	}

	private editScheduledEventNotes(event: CalendarEvent<ScheduledEvent>) {
		const modalRef = this.modalService.open(ScheduledItemNotesEditModalComponent, false, 'lg');
		modalRef.componentInstance.scheduledEvent = event.meta!;
		modalRef.componentInstance.saveAssessment.pipe(take(1)).subscribe((notes: string) => {
			this.calendarService.updateScheduledAssessmentNotes(event.meta!, notes);
		});
	}

	private async openJobCard(event: CalendarEvent<ScheduledEvent>) {
		const modalRef = this.modalService.open(JobCardModalComponent, false, 'lg');
		modalRef.componentInstance.organisationId = this.organisation.id;
		modalRef.componentInstance.leadRequestId = event.meta?.leadRequestId!;
		modalRef.componentInstance.leadAssessmentId = event.meta?.scheduledAssessmentId!;
		modalRef.componentInstance.customerName = event.meta?.customerName!;
	}
}
